import { Box, breakpoints, TabsContainer } from "@thrivecoin/ui-components";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MainContainer } from "../common/MainContainer";
import RafflePrizes from "../Raffle/Body/RafflePrizes";
import AboutSeason from "../Season/BodySeason/AboutSeason";
import HeroPart from "../Season/Hero";
import { SeasonEditorContext } from "./SeasonEditorContext";

const HEADERS = ["Ways to Contribute", "About Season", "Contributors", "Contributions", "Raffle Prizes"];

const PreviewSeason = () => {
  const aboutRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const { season, raffleEnabled, previewMode, currentStep } = useContext(SeasonEditorContext);
  const [showPreview, setShowPreview] = useState(false);

  const executeScroll = () => aboutRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    if (previewMode && currentStep === 1) {
      executeScroll();
    }
  }, [previewMode, currentStep]);

  const AboutTab = () => {
    return (
      <Wrapper>
        <MainContainer>
          <Container ref={aboutRef}>
            <AboutSeason />
          </Container>
        </MainContainer>
      </Wrapper>
    );
  };

  const RafflePrizesTab = () => {
    return <RafflePrizes seasonPage editor editorRafflePrizes={season.raffle_prizes} />;
  };

  let tabClasses = [null, AboutTab, null, null, RafflePrizesTab];

  return (
    <PreviewWrapper $grow="1">
      <HeroPart season={{ ...season, image_url: season.image }} />
      <WrapperTab noSpace={true} prizesEnabled={raffleEnabled && season.raffle_prizes.length > 0}>
        <TabsContainer
          tabProps={{ showPreview, setShowPreview }}
          tabClasses={tabClasses}
          headers={HEADERS}
          selectedIndex={selectedTab}
          onChangeIndex={setSelectedTab}
          className="tabsContainer"
        />
      </WrapperTab>
    </PreviewWrapper>
  );
};

const PreviewWrapper = styled(Box)`
@media ${breakpoints.mobile} {
  display: none;
`;

const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  padding-top: 50px;
  @media ${breakpoints.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto;
  padding-bottom: 63px;
  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.tablet} {
    padding: 0 32px 63px 32px;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 0 24px;
  }
`;

const WrapperTab = styled(Box)`
  padding-left: 0;
  position: relative;
  background-color: ${({ theme }) => theme.colors["01Primary0"]};

  & > div:first-child {
    & > div:first-child {
      & > div {
        opacity: 0.5;
      }
      & > div:nth-child(2)${({ prizesEnabled }) => prizesEnabled && ", > div:nth-child(5)"} {
        opacity: 1;
      }
      & > div:not(:nth-child(2))${({ prizesEnabled }) => prizesEnabled && ":not(:nth-child(5))"} {
        pointer-events: none;
      }
    }
  }
  & > div > div {
    width: 100%;
    & > div:nth-child(2) {
      border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
      background: ${({ theme }) => theme.colors.background};
      & > div {
        @media (min-width: 1003px) and (max-width: 1456px) {
          max-width: calc(100vw - 64px);
        }
        @media ${breakpoints.tablet} {
          max-width: ${({ noSpace }) => (noSpace ? "unset" : " calc(100vw - 64px)")};
        }
        @media ${breakpoints.mobile} {
          max-width: ${({ noSpace }) => (noSpace ? "unset" : " calc(100vw - 64px)")};
        }
      }
    }

    & > div:first-child {
      margin: 0 auto;
      padding: 27px 0 28px 32px;

      @media ${breakpoints.tablet} {
        padding: 27px 0 28px 32px;
      }
      @media ${breakpoints.mobile} {
        width: unset;
      }
    }
  }
`;

export default PreviewSeason;
