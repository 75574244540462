import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import EmptyContent from "../../EmptyContent";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import PendingContributionList from "../../PendingContributions/PendingContributionList";

const LIMIT = 6;

const PendingContributionsTab = () => {
  const navigate = useNavigate();
  // const contributionElements = contributions.map(ContributionRow);
  const { isMobile } = useStoreState((state) => state.media);
  const { pendingContributions } = useStoreState((state) => state.pendingContributions);

  const { getPendingContributions } = useStoreActions((actions) => actions.pendingContributions);

  const { community_id } = useParams();
  const viewAll = () => navigate(`/communities/${community_id}/contributions/pending`);

  useEffect(() => {
    getPendingContributions({ community_id });
  }, []);

  useVirtualPageView({ page: "/contributions" });

  return (
    <MainContainer>
      <Wrapper>
        {pendingContributions.length === 0 ? (
          <EmptyContent
            title="All clear!"
            text="There are no contributions pending your review."
            imageWidth="326.57px"
            marginTopImage={isMobile ? "76px" : "114px"}
            $spacing={"mt-6"}
          />
        ) : (
          <>
            <HeaderBlock
              count={pendingContributions.length}
              text="Pending contributions"
              $width="400px"
              $spacing="p-5"
              viewAll={viewAll}
            />
            <PendingContributionList contributions={pendingContributions.slice(0, LIMIT)}></PendingContributionList>
          </>
        )}
        {/*{ This will be hidden at this stage}*/}
        {/*<Box>
        <HeaderBlock count={contributions.length} text="Contributions" $spacing="my-6" $width="296px" />
        <ListContainer>{contributionElements}</ListContainer>
      </Box>*/}
      </Wrapper>
    </MainContainer>
  );
};

const HeaderBlock = ({ count, text, $spacing, width, viewAll }) => {
  return (
    <Box $flex $width={width} $justifyContent="space-between" $spacing={$spacing} $alignItems="center">
      <Text type="h4" $color="01Primary700" $fontFamily="Outfit" $size="24px" $bold>
        {text}
      </Text>
      |
      <Text $color="accent400" onClick={viewAll} $extraBold>
        View all ({count})
      </Text>
    </Box>
  );
};

const GrayContainer = styled.div`
  background: ${({ theme }) => theme.colors["01Surface"]};
  padding: 40px 0;
`;

const Wrapper = styled(Box)`
  border-radius: 12px;
  margin: 0 auto;
  box-sizing: border-box;

  @media ${breakpoints.mobile} {
    border-radius: 0;
    padding: 1.5rem 0;
  }
`;

export default PendingContributionsTab;
