import debounce from "@utils/debounce";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SORT_DIRECTIONS = ["asc", "desc"];
const DEBOUNCE_TIME = 1000;

const useSort = (sortMethod, initialSort, idProperty = "id", noQueryParam) => {
  const id = noQueryParam ? noQueryParam : useParams()[idProperty];
  const [sort_type, setSortType] = useState(initialSort);
  const [sort_direction, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);

  const onSort = (e) => {
    const _sortType = e.target.dataset.sorttype || e.target.dataset.name;
    setPage(1);
    if (sort_type === _sortType) {
      const direction = SORT_DIRECTIONS.find((d) => d !== sort_direction);
      setSortDirection(direction);
    } else {
      setSortType(_sortType);
      setSortDirection("desc");
    }
  };

  // Since this needs to await for the request to finish to properly manage its loading state
  // we make the request here
  const onLoadMore = (page) => {
    setPage(page);
    return fetchRecords(page);
  };

  const onFilterApply = (search, sort) => {
    setKeyword(search);
    setSortType(sort.value);
    setSortDirection(sort.direction);
  };

  const onChange = (keyword) => {
    if (!keyword || (keyword && keyword.length >= 3)) {
      setKeyword(keyword);
    }
  };

  const fetchRecords = (_page) => {
    setLoading(true);
    return sortMethod({ [idProperty]: id, sort_type, sort_direction, keyword, page: _page || page }).finally(() =>
      setLoading(false)
    );
  };

  useEffect(() => {
    if (!loading) {
      fetchRecords();
    }
  }, [id, sort_type, sort_direction, keyword, page]);

  return {
    sortMethod,
    onSort,
    loading,
    sortType: sort_type,
    searchValue: keyword,
    sortDirection: sort_direction,
    onFilterApply,
    onLoadMore,
    setLoading,
    onChange: debounce(onChange, DEBOUNCE_TIME),
  };
};

export default useSort;
