import { Modal } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import AcceptStep from "./AcceptStep";
import { PendingContributionProvider } from "./PendingContributionContext";
import RejectStep from "./RejectStep";

const ManagePendingContributionModal = ({
  hideModal,
  isAccepting: _isAccepting,
  id,
  contribution_claim_id,
  message,
  username,
  afterClose,
  verification_code,
  activity_name,
  conditions,
  reviewer_instructions,
  submission_urls,
}) => {
  const [isAccepting, setisAccepting] = useState(_isAccepting);
  const StepKlass = isAccepting ? AcceptStep : RejectStep;
  const goToReject = () => setisAccepting(false);
  const { isMobile } = useStoreState((state) => state.media);
  const footerContent = StepKlass.Footer ? (
    <StepKlass.Footer
      id={id}
      isMobile={isMobile}
      goToReject={goToReject}
      contribution_claim_id={contribution_claim_id}
      hideModal={hideModal}
    />
  ) : null;
  return (
    <PendingContributionProvider>
      <Modal open onClose={hideModal} $maxWidth="684px" footerContent={footerContent}>
        <StepKlass
          id={id}
          message={message}
          username={username}
          contribution_claim_id={contribution_claim_id}
          goToReject={goToReject}
          afterClose={afterClose}
          hideModal={hideModal}
          verification_code={verification_code}
          activity_name={activity_name}
          conditions={conditions}
          reviewer_instructions={reviewer_instructions}
          submission_urls={submission_urls}
          isMobile={isMobile}
        ></StepKlass>
      </Modal>
    </PendingContributionProvider>
  );
};

ManagePendingContributionModal.defaultProps = {
  isAccepting: true,
};

export default ManagePendingContributionModal;
