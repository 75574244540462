import { Box, Button, Collapsible, IconSvg, Text } from "@thrivecoin/ui-components";
import { useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import { REASONS } from "../DoActionsModal/constants";
import { statusInformation } from "./statusInformation";

const findLabelById = (id) => {
  const reason = REASONS.find((reason) => reason.id === id);
  return reason ? reason.label : "";
};

const ImportantNoticeStep = ({ type, user }) => {
  const labelAudit = type === "restricted" ? "Restriction" : "Ban";
  const isBanned = type === "banned";
  const { description, titleCollapse } = statusInformation[type];
  const [active, setActive] = useState(false);
  let updatedDescription = description;
  if (description.includes("{Community}")) {
    const communityName = isBanned
      ? user.banned_user_audit?.community_name
      : user.restricted_user_audit[0]?.community_name;
    updatedDescription = description.replace("{Community}", communityName);
  }
  const reason = isBanned ? user.banned_user_audit?.reason : user.restricted_user_audit[0]?.reason;

  const additional_notes = isBanned
    ? user.banned_user_audit?.additional_notes
    : user.restricted_user_audit[0]?.additional_notes;

  const handleCollapsibleClick = () => {
    setActive(!active);
  };

  return (
    <StyledBox>
      <Bar type={type} />
      <Text $spacing="mt-5" $color="02Primary500">
        {updatedDescription}
      </Text>
      <Box $height="1px" $bgColor="02PrimaryGrey150" $spacing="my-5" />

      <Collapsible
        title={titleCollapse}
        onClick={handleCollapsibleClick}
        isActive={active}
        IconComponent={CustomIcon}
        className="collapsible"
        $bgColor="02PrimaryGrey100"
        $color="02Primary500"
        $titleTypography="body/medium"
        $paddingContainer="16px"
        $paddingInside="0 16px 16px !important"
      >
        Reason for {labelAudit}: {findLabelById(reason)}
        <br />
        {additional_notes && `Additional Notes: ${additional_notes}`}
      </Collapsible>
      <Text $spacing="mt-5" $color="02Primary500">
        {" "}
        If you feel there has been made a mistake, please request a security review by clicking the button below.
      </Text>
    </StyledBox>
  );
};

const Footer = ({ closeModal, goToStep, loading, isReviewRequested }) => {
  const onClick = () => goToStep(STEP_INDEXES.RequestSecurityReviewStep);
  return (
    <Box $flex $gap="16px">
      <Button variant="blue" $borderRadius="50px" $width="100%" onClick={closeModal}>
        I'll See Myself Out
      </Button>
      <StyledButton
        variant="red"
        $borderRadius="50px"
        kind="outline"
        $width="100%"
        onClick={onClick}
        loading={loading}
        disabled={isReviewRequested}
      >
        I Disagree
      </StyledButton>
    </Box>
  );
};

const Bar = ({ type }) => {
  const { label, icon, bgColor, borderColor, color } = statusInformation[type];
  return (
    <BarBox $justifyContent="space-between" $alignItems="center" $bgColor={bgColor} $borderColor={borderColor} $flex>
      <IconSvg icon={icon} $width="18px" $color={color} />
      <Text $typography="body/large" $color={color} $extraBold>
        Your ThriveCoin Account has been {label}.
      </Text>
      <IconSvg icon={icon} $width="18px" $color={color} />
    </BarBox>
  );
};

const CustomIcon = ({ isActive, hideIcon }) => {
  const iconElement = hideIcon ? null : <IconSvg $color="02Primary500" $width="18px" icon="chevron-down" />;
  return <IconWrapper isActive={isActive}>{iconElement}</IconWrapper>;
};

const StyledBox = styled(Box)`
  text-align: left;
`;

const BarBox = styled(Box)`
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors["02Secondary500"]};
  ${({ $borderColor, theme }) => $borderColor && `border-color:${theme.colors[$borderColor]}`};
`;
const StyledButton = styled(Button)`
  text-transform: capitalize;
  :disabled {
    background-color: transparent;
    opacity: 0.25;
    border-color: ${({ theme }) => theme.colors["02Secondary500"]};
  }
`;

const IconWrapper = styled.div`
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors["02Primary500"]};

  ${({ isActive }) =>
    isActive &&
    ` transform: rotate(180deg);
      border: 2px solid ${({ theme }) => theme.colors["02Primary500"]};
    `}
`;

ImportantNoticeStep.Footer = Footer;

export default ImportantNoticeStep;
