import { normalize } from "@utils/socialUtils";
import ConnectionButton from "./ConnectionButton";

const DEFAULT_CONFIG = { displayName: "MISSING CONFIGURATION" };

const DiscourseButtons = ({ discourseConnections, countByApp, $isModal }) => {
  const socials = Object.entries(discourseConnections).map(([key, item]) => {
    const config = normalize(item.provider || key) || DEFAULT_CONFIG;
    const { displayName, appName } = config;
    const count = countByApp[appName];
    const connected = !!item.id;

    return (
      <ConnectionButton
        $isModal={$isModal}
        key={key}
        displayName={displayName}
        count={count}
        connected={connected}
        {...config}
      />
    );
  });

  return <>{socials}</>;
};

DiscourseButtons.defaultProps = {
  canDisconnect: true,
  showCount: true,
  isOpen: false,
};

export default DiscourseButtons;
