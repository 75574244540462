import { Box, ProgressBar, Text } from "@thrivecoin/ui-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PERSONAL_LEGEND_PATH } from "../../Constants";
import { trackEventCategory } from "../../hooks/useTrackEvents";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";

const AddNetworkStep2 = ({ closeModal }) => {
  const navigate = useNavigate();
  const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addPolygonToken, providerName } = useWeb3();
  const onTokenAdded = () => setAdded(true);
  const _onClick = () => {
    setLoading(true);
    trackEventCategory("UO_RF_Recognize_Thrive");
    addPolygonToken()
      .then(onTokenAdded)
      .finally(() => setLoading(false));
  };

  const personalLegendClck = () => {
    trackEventCategory("UO_RF_Personal_Legend_Start");
    closeModal();
    navigate(PERSONAL_LEGEND_PATH);
  };

  const message = added ? "Begin Your Contributions" : "Recognize $THRIVE Token in MetaMask";
  const icon = added ? "arrow-right" : null;
  const onClick = added ? personalLegendClck : _onClick;

  return (
    <Box $sm-spacing="mt-6 px-4">
      <Text $typography="heading/large" $spacing="mb-4" $color="02Primary500">
        See your ThriveCoin rewards in {providerName}
      </Text>

      <Text $typography="body/medium" $spacing="mb-5" $sm-spacing="mx-4+1" $color="02Primary500">
        Click the button below and select “Add Token” when prompted. This will add a configuration that lets{" "}
        {providerName + " "}
        recognize $THRIVE tokens.
      </Text>

      <PaddedButton
        loading={loading}
        $size="lg"
        $height="56px"
        $typography="Body/Medium"
        $semiBold
        $spacing="mb-5"
        onClick={onClick}
        rightIcon={icon}
        modal={true}
      >
        {message}
      </PaddedButton>
    </Box>
  );
};

const Footer = () => (
  <Box $height="98px" $bgColor="02Primary0" $flex $justifyContent="center">
    <Box $flex $justifyContent="center" $alignItems="center" $width="278px" $gap="16px">
      <ProgressBar percent="100" $bgColor="#81C394" />
      <Text $typography="Body/Medium" $semiBold $color="02Primary500">
        2/2
      </Text>
    </Box>
  </Box>
);

AddNetworkStep2.Footer = Footer;
AddNetworkStep2.$maxWidth = "684px";
AddNetworkStep2.spacing = "px-7+2 pt-7+6 pb-6+2";

export default AddNetworkStep2;
