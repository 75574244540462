import moment from "moment";
const date = new Date(moment().utc(true));
let nextMonth;
if (date.getMonth() == 11) {
  nextMonth = new Date(date.getFullYear() + 1, 0, date.getDate());
} else {
  nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

export const StepsHeaders = [
  {
    label: "1. Details",
    num: 0,
  },
  {
    label: "2. About",
    num: 1,
  },
  {
    label: "3. Overview",
    num: 2,
  },
  {
    label: "4. Prizes",
    num: 3,
  },
];

export const PrizeStep = {};

export const DEFAULT_MEDIA = {
  image: null,
  image_type: "",
  featured_image: null,
  featured_image_type: "",
};

export const DEFAULT_RAFFLE = {
  draw_date: date,
  image_url: "",
  status: "pending",
  rules: "",
  external_url: "",
};

export const DEFAULT_SEASON = {
  about: "",
  community_id: null,
  description: "",
  end_date: nextMonth,
  goal: "",
  order_number: 1,
  label: "",
  prizes: [],
  slug: "",
  start_date: date,
  subtitle: "",
  title: "",
  raffle: { ...DEFAULT_RAFFLE },
  raffle_prizes: [],
  rewards_token: "thrive",
  max_reward_amount: "",
  badge_id: null,
};

export const DEFAULT_PRIZE = {
  icon: "info",
  text: "",
};

export const DEFAULT_RAFFLE_PRIZE = {
  name: "",
  description: "",
  image: "",
  image_type: "",
  external_url: "",
};
