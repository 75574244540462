import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import WhatIsThriveTooltip from "../CommunityPage/MyContributions/WhatIsThriveTooltip";

const FirstThrive = ({ amount, text = "You earned" }) => {
  const { isMobile } = useStoreState((state) => state.media);

  return (
    <Wrapper $flex $borderRadius="0 16px" $bgColor="01Primary0" $spacing="px-5 py-4" $alignItems="center">
      <IconSvg $width="40px" icon="logo-gold-circle" $spacing="mr-2" />
      <Box $flex $direction="column" $alignItems="flex-start">
        <Text $color="02Primary500">{text}</Text>
        <Container $alignItems="center" $flex>
          <Text $fontFamily="Outfit" $color="02Primary500" $bold $size="22px" $lineHeight="27.72px" $spacing="mr-1">
            {parseFloat(amount)} $THRIVE
          </Text>
          {!isMobile && <WhatIsThriveTooltip $width="18px" $color="#C67B65" />}
        </Container>
      </Box>
    </Wrapper>
  );
};

const Container = styled(Box)`
  @media ${breakpoints.mobile} {
    ${Text} {
      font-size: 20px;
    }
  }
`;

const Wrapper = styled(Box)`
  max-width: 244px;
  max-height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors["02Primary0"]};
  box-shadow: 0px 25px 15px rgba(196, 196, 196, 0.1);
  border-radius: 0px 16px;

  @media ${breakpoints.mobile} {
    width: auto;
    min-width: auto;
    height: auto;
    margin: 0 50px;
  }
`;

export default FirstThrive;
