import { Box, Button, Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Body from "./Body";
import Header from "./Header";

const INITIAL_STATE = {
  name: "",
  description: "",
  requirements: "",
  logo_url: "",
  community_id: "",
};

const AddBadgeModal = ({ hideModal, community_id, id, callback }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { createBadge, getEditBadge, updateBadge } = useStoreActions((actions) => actions.badges);
  const { editBadge } = useStoreState((state) => state.badges);
  const isPersisted = id && id;
  const [badgeValues, setBadgeValues] = useState(INITIAL_STATE);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const getData = () => {
    return {
      ...badgeValues,
      ...(community_id && { community_id }),
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = getData();
    let action = isPersisted ? updateBadge : createBadge;
    const _community_id = !isPersisted && community_id;
    return action({ data, community_id: _community_id })
      .then(({ message }) => {
        showToast({
          content: message,
          type: "success",
          dismissTime: 5000,
        });
        hideModal();
        callback && callback();
      })
      .catch(onError);
  };

  const onError = ({ response }) => {
    const content = response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  useEffect(() => {
    const emptyFields =
      !badgeValues.name || !badgeValues.logo_url || !badgeValues.description || !badgeValues.requirements;

    setIsSubmitDisabled(emptyFields);
  }, [badgeValues, isPersisted]);

  useEffect(() => {
    if (id) {
      getEditBadge(id);
      setBadgeValues(editBadge);
    }
  }, [id, editBadge.id]);

  const footerContent = (
    <>
      <ButtonsWrapper $direction={isMobile && "column"} $gap="1rem" $flex>
        <Button
          onClick={hideModal}
          variant="white"
          kind="outline"
          $size="md"
          $typography="body/medium"
          $borderRadius="50px"
          $width="100%"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          $borderRadius="50px"
          $width="100%"
          $size="md"
          $typography="body/medium"
        >
          {isPersisted ? "Update Badge" : "Create Badge"}
        </Button>
      </ButtonsWrapper>
    </>
  );

  return (
    <StyledModal open onClose={hideModal} $maxWidth="1302px" $padding="0" footerContent={footerContent}>
      <Header community_id={community_id} isPersisted={isPersisted} />
      <Body
        badgeValues={badgeValues}
        setBadgeValues={setBadgeValues}
        community_id={community_id}
        isPersisted={isPersisted}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & > div > div {
    padding: 0;
    & > button {
      margin-right: 24px !important;
      margin-top: 8px !important;
    }
  }
`;

const ButtonsWrapper = styled(Box)`
  margin: auto;
  width: 555px;
  margin-bottom: 1.5rem;
  padding: 32px 0 24px 0;
`;

export default AddBadgeModal;
