import { Box, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { Line } from "./common";

const Header = ({ community_id, isPersisted }) => {
  const title = isPersisted ? "Update" : community_id ? "Setup" : "Add New";
  return (
    <>
      <Container $flex $justifyContent="center" $alignItems="center">
        <Text $typography="heading/medium" $color="01Primary700">
          {title} Badge
        </Text>
      </Container>
      <Line />
    </>
  );
};

const Container = styled(Box)`
  padding: 0 48px;
`;
export default Header;
