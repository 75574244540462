import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import BottomButtons from "./BottomButtons";
import CardHeader from "./CardHeader";
import { RectangleBox, SectionBox } from "./common";
import ListingsSection from "./ListingsSection";
import SeasonsSection from "./SeasonsSection";

import HERO_ILUSTRATION from "@assets/bg-community-hero.svg";
import { useStoreActions } from "easy-peasy";
import BadgesSection from "./BadgesSection";

const ManageCommunityCard = (props) => {
  const { id, expanded, theme, accent_color, header_background_url, header_background_color, cover_media_url } = props;
  const { showModal } = useStoreActions((actions) => actions.modals);
  const editCommunity = () => showModal({ modalName: "CommunityEditorModal", communityID: id });

  const customTheme = theme === "custom";
  const accentColor = customTheme ? accent_color : "03Primary500";
  const backgroundHero = !customTheme
    ? `url(${HERO_ILUSTRATION})`
    : header_background_url
    ? `url(${header_background_url})`
    : header_background_color;

  return (
    <Wrapper $bgColor="surfaces">
      <CardHeader {...props} />
      {expanded && (
        <Box $spacing="mt-6">
          <Box $flex $gap="24px" $spacing="mb-5">
            <SectionBox title="Reward Sources" $gap="16px" $justifyContent="flex-start">
              <RectangleBox $spacing="mb-4" />
              <RectangleBox $relative $lgSize $flex $alignItems="center" $justifyContent="center" $spacing="px-5 py-7">
                <Text $typography="body/small">This functionality is coming soon!</Text>
              </RectangleBox>
            </SectionBox>
            <BadgesSection communityId={id} />
            <SeasonsSection communityId={id} />
            <ListingsSection communityId={id} />
          </Box>
          <Box $flex $gap="24px">
            <BottomCard $spacing="pt-4+1">
              <Box $flex $alignItems="center" $gap="12px" $spacing="mb-5">
                <IconWrapper>
                  <IconSvg icon="tokens" />
                </IconWrapper>
                <Text $typography="heading/small">Community Vault</Text>
              </Box>
              <Box
                $borderRadius="8px"
                $flex
                $alignItems="center"
                $justifyContent="center"
                $bgColor="01Primary0"
                $height="125px"
              >
                <Text $bold>This functionality is coming soon!</Text>
              </Box>
            </BottomCard>
            <BottomCard>
              <Box $flex $alignItems="center" $gap="12px" $spacing="mb-4">
                <IconWrapper>
                  <IconSvg icon="eye" />
                </IconWrapper>
                <Text $typography="heading/small">Theme</Text>
                <BottomButtons {...props} />
              </Box>
              <Box $flex $gap="4px">
                <Box
                  onClick={editCommunity}
                  $flex
                  $pointer
                  $width="50px"
                  $height="125px"
                  $bgColor={accentColor}
                  $borderRadius="8px 0 0 8px"
                >
                  <Box
                    $bgColor="01Primary0"
                    $width="32px"
                    $height="32px"
                    $borderRadius="50%"
                    $flex
                    $alignItems="center"
                    $justifyContent="center"
                    $spacing="mt-2 mx-a"
                  >
                    <IconSvg $color="01Primary700" icon="pen" $width="17px" />
                  </Box>
                </Box>
                <CommunityHeader $bgColor={backgroundHero}>
                  <CommunityImage src={cover_media_url} />
                </CommunityHeader>
              </Box>
            </BottomCard>
          </Box>
        </Box>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: 24px;
  border-radius: 12px;
`;

const BottomCard = styled(Box)`
  flex: 1;
  padding: 16px 16px 20px;
  border-radius: 12px 12px 0 0;
  background: ${({ theme }) => theme.colors.gradient};
`;

const IconWrapper = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors["03Primary500"]};

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg > path {
    fill: ${({ theme }) => theme.colors["01Primary0"]};
  }
`;

const CommunityHeader = styled(Box)`
  flex: 1;
  border-radius: 0 8px 8px 0;
  background-position: bottom;
  padding-right: 30px;
  display: flex;
`;

const CommunityImage = styled.img`
  height: 125px;
  margin-left: auto;
`;

export default ManageCommunityCard;
