export const dummyListing = {
  name: "Contribute to communities you love!",
  canCompleteMultipleTimes: false,
  type: "twitter_app",
  isAuthenticated: false,
  manuallyVerification: false,
  completed: false,
  reward: 30,
  image_url: "https://thrivecoin-assets.s3.amazonaws.com/images/apps/discord.svg",
  token: "THRIVE",
  custom_url: "https://app.thrivecoin.com",
  selectedList: false,
  combinedCompleted: true,
  communityLink: "https://app.thrivecoin.com",
  communityName: "Thrivecoin",
};
