import HeaderElement from "@common/HeaderElement";

const HeaderRow = ({ onSort, sortType, sortDirection, community_name }) => {
  return (
    <thead>
      <tr>
        <HeaderElement
          width="118px"
          text="Name"
          name="name"
          onSort={onSort}
          sort={sortType === "name" ? sortDirection : null}
        />
        <HeaderElement text="Description" name="description" />
        {community_name && (
          <HeaderElement
            text="Community"
            name="community"
            onSort={onSort}
            sort={sortType === "community" ? sortDirection : null}
          />
        )}
        <HeaderElement
          text="Contributors"
          name="contributors"
          onSort={onSort}
          sort={sortType === "contributors" ? sortDirection : null}
        />
        <th></th>
      </tr>
    </thead>
  );
};

export default HeaderRow;
