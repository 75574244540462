export const statusInformation = {
  banned: {
    label: "Banned",
    description:
      "We are sorry to let you know that your account has been (auto)banned. You can therefore no longer login in any of the Communities and/or access any Community Season pages on the ThriveCoin Network. Your outstanding rewards have been frozen until further notice.",
    titleCollapse: "User Ban Details",
    icon: "cancel",
    $bgColor: "error",
    $borderColor: "error",
    $color: "02Primary0",
  },
  restricted: {
    label: "Restricted",
    description:
      "We are sorry to let you know that your account has been restricted. You can therefore no longer participate in {Community} on the ThriveCoin Network. Your outstanding rewards will still be claimable once verified.",
    titleCollapse: "User Restriction Details",
    icon: "hand2",
    $color: "error",
  },
};
