import AvatarUsername from "@common/UsernameAvatar";
import { Box, Text, TokenReward } from "@thrivecoin/ui-components";
import styled from "styled-components";
import EmptySearch from "../../EmptySearch";

const Contributor = ({
  index,
  username,
  _group,
  rewards_earned,
  onProfileClick,
  image_url,
  user_id,
  rewards_token,
  contributor_rank,
  $bgColor,
  dataColor,
  $borderColor,
  topThree,
  isTenth,
  isCommunity,
  personal,
  contributions_count,
}) => {
  return (
    <>
      <Wrapper
        $flex
        $justifyContent="space-between"
        $spacing="px-4 py-4"
        $bgColor={$bgColor}
        $color={dataColor}
        $borderColor={$borderColor}
        $borderWidth={isTenth && "2px"}
      >
        <UsernameRankWrapper $gap="8px" $alignItems="center" $flex $color={dataColor}>
          {!!_group && (
            <AvatarUsername
              $color={dataColor}
              username={username}
              dataID={_group.id}
              onProfileClick={onProfileClick}
              image_url={image_url}
              id={topThree ? null : contributor_rank}
              index={index}
              user_id={user_id}
              imageWidth={!topThree ? "44px" : "56px"}
              imageHeight={!topThree ? "44px" : "56px"}
              contributor_rank={contributor_rank}
              personal={personal}
              isContributor
              isMobile
            />
          )}
          <Box $relative $flex $alignItems="center" $spacing="ml-a">
            <Text $extraBold $typography="body/medium" $color={dataColor}>
              {contributions_count}
            </Text>
          </Box>
        </UsernameRankWrapper>
        {!isCommunity && (
          <WrapperReward>
            <TokenReward
              $color={dataColor}
              $fontSize="30px"
              amount={+rewards_earned}
              token={rewards_token}
              hideText={true}
            />
          </WrapperReward>
        )}
      </Wrapper>
    </>
  );
};

const MobileContributors = ({
  data,
  _group,
  onProfileClick,
  hideTopEntries,
  onRaffleDetails,
  sortProps,
  onSearch,
  resultsFound,
  rewards_token,
  isCustomToken,
  user,
  group,
  topThreeRanks,
}) => {
  const isCommunity = group === "community";

  const contributors = (
    <Box>
      {data?.map((item) => {
        const contributor_rank = item.contributor_rank;
        const topThree = topThreeRanks.includes(contributor_rank);
        const personal = item.user_id === user.id;
        const isTenth = contributor_rank === 10;
        const $bgColor = topThree ? "01Secondary300" : personal ? "03Primary300" : "surfaces";
        const dataColor = topThree || personal ? "02Primary500" : "01Primary700";
        const $borderColor = topThree ? "01Primary0" : isTenth ? "03Primary500" : "01Primary200";
        return (
          <Contributor
            key={item.id}
            id={contributor_rank}
            $borderColor={$borderColor}
            $bgColor={$bgColor}
            _group={_group}
            index={contributor_rank}
            onProfileClick={onProfileClick}
            hideTopEntries={hideTopEntries}
            onRaffleDetails={onRaffleDetails}
            sortProps={sortProps}
            onSearch={onSearch}
            resultsFound={resultsFound}
            rewards_token={rewards_token}
            isCustomToken={isCustomToken}
            user={user}
            dataColor={dataColor}
            topThree={topThree}
            isTenth={isTenth}
            isCommunity={isCommunity}
            {...item}
          />
        );
      })}
    </Box>
  );

  return (
    <>
      <Box $spacing="pb-3 pt-5" $flex>
        <Text $spacing="pl-4" $bold $typography="body/small" $color="01Primary700" $textAlign="left">
          Status
        </Text>

        <Text $spacing="pl-6" $bold $typography="body/small" $color="01Primary700" $textAlign="left">
          Contributors
        </Text>

        <Text $spacing="pl-4 ml-a" $bold $typography="body/small" $color="01Primary700" $textAlign="right">
          Contributions
        </Text>
      </Box>
      {data.length === 0 ? (
        <WrapperOfEmpty>
          <EmptySearch />
        </WrapperOfEmpty>
      ) : (
        <Box>{contributors}</Box>
      )}
    </>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ borderTop, theme }) => theme.colors[borderTop] || borderTop};
  background-color: ${({ $bgColor, theme }) => $bgColor && theme.colors[$bgColor]};
  ${({ $borderColor, theme }) => $borderColor && `border-color:${theme.colors[$borderColor]}`};
  ${({ $borderWidth }) => $borderWidth && `border-width:${$borderWidth}`};

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const UsernameRankWrapper = styled(Box)`
  width: 100%;
`;

const WrapperReward = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 63px;
  margin-top: 16px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const WrapperOfEmpty = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary700"]};
`;

export default MobileContributors;
