import { Box, DropDown, Input, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const OPTIONS = [
  { value: "etherum", label: "Etherum" },
  { value: "optimism", label: "Optimism" },
  { value: "polygon", label: "Polygon" },
];

const ContractAddress = ({ networkAddress, setNetworkAddress }) => {
  const selectedSocials = networkAddress.map((item) => item.id);
  const availableOptions = OPTIONS.filter(({ value }) => !selectedSocials.includes(value));

  const possibleOptions = (id) => {
    const selected = OPTIONS.find(({ value }) => value == id);
    return selected ? [selected, ...availableOptions] : availableOptions;
  };

  const onSelectChange = (val, index) => {
    const updatedSocials = [...networkAddress];
    const prevSelected = updatedSocials.findIndex((item) => item.id === val.value);
    if (prevSelected > -1) {
      updatedSocials[prevSelected] = { ...updatedSocials[prevSelected], id: "empty" };
    }
    updatedSocials[index] = { ...updatedSocials[index], id: val.value };
    setNetworkAddress(updatedSocials);
  };

  const onInputChange = (e) => {
    const { value, dataset } = e.target;
    const updatedSocials = [...networkAddress];
    updatedSocials[dataset.id].value = value;
    setNetworkAddress(updatedSocials);
  };
  return (
    <Box $flex $direction="column" $width="100%">
      {networkAddress.map((item, index) => (
        <Box key={(item.id || index) + availableOptions.length}>
          <Text $bold $spacing="mb-3">
            Contract Address (Required)
          </Text>
          <StyledWrapper $flex $gap="1rem">
            <DropDown
              value={item?.id}
              valueKey="value"
              autocomplete={false}
              label="label"
              options={possibleOptions(item.id)}
              placeholder="Select Network"
              onChange={(val) => onSelectChange(val, index)}
              $bgColor="02PrimaryGrey50"
              $color="02Primary500"
              optionsBgColor="02PrimaryGrey50"
              hoverColor="02PrimaryGrey50"
              borderColor="02PrimaryGrey300"
              $width="170px"
            />
            <Input value={item.value} placeholder="Address" data-id={index} onChange={onInputChange} modal />
          </StyledWrapper>
        </Box>
      ))}
    </Box>
  );
};

const StyledWrapper = styled(Box)`
  & > div:first-child > div:nth-child(4) {
    border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    border-top: none;
    & > div {
      border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    }
  }
`;
export default ContractAddress;
