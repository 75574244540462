import { Box, breakpoints } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Web3Wrapper from "../../../utils/web3wrapper";
import useAdditionalWalletAPI from "../../hooks/useAdditionalWalletAPI";
import useGetBalance from "../../hooks/useGetBalance";
import { TabWrapper } from "../common";
import AdditionalWallets from "./AdditionalWallets";
import MainCard from "./MainCard";

const WALLETS_LIMIT = 3;

const WalletsTab = () => {
  const web3Ref = useRef(new Web3Wrapper());
  const { showModal, hideModal } = useStoreActions((actions) => actions.modals);
  const { isMobile } = useStoreState((state) => state.media);
  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addWallet, disconnectWallet, walletExists, fetched } = useAdditionalWalletAPI(false);
  const [currentAddress, setCurrentAddress] = useState(web3Ref.current.selectedAddress());
  const { wallets, user } = useStoreState((state) => state.user);
  const { additionalWallets } = user;
  const { getExtraWalletsRequest } = useStoreActions((state) => state.user);
  const mainWallet = wallets[0];
  const lengthAdditionalWallets = additionalWallets.length;
  const limitReached = lengthAdditionalWallets === WALLETS_LIMIT;
  const showDetected = currentAddress && !limitReached;
  const disableConnect =
    limitReached ||
    hasError ||
    wallets.some(({ address }) => address == currentAddress) ||
    additionalWallets.some(({ address }) => address == currentAddress);
  useGetBalance(mainWallet);

  const connectNewWallet = async (web3) => {
    const currentAddress = web3.selectedAddress();
    setLoading(true);
    let promise;
    if (currentAddress) {
      promise = addWallet(currentAddress, web3)
        .then(() => {
          showModal({ modalName: "VerificationSuccessfulModal", address: currentAddress });
        })
        .catch((e) => {
          if (e.response.status == 422) {
            setError(true);
            const onClick = async () => {
              hideModal();
              getExtraProvider();
            };
            showModal({ modalName: "AdditionalWalletErrorModal", address: currentAddress, onClick: onClick });
          } else {
            console.error(e);
          }
        });
    }

    return promise.finally(() => setLoading(false));
  };

  const getExtraProvider = async () => {
    const afterClose = (_, provider) => {
      if (provider) {
        const web3 = new Web3Wrapper();
        web3.setProvider(provider);
        connectNewWallet(web3);
      }
    };

    showModal({
      modalName: "WalletConnectorModal",
      afterClose,
      rememberProvider: false,
      useAsProvier: false,
      isAdditional: true,
    });
  };

  useEffect(() => {
    if (web3Ref.current.provider) {
      setCurrentAddress(web3Ref.current.selectedAddress());
      const onAccountChanged = () => {
        setCurrentAddress(web3Ref.current.selectedAddress());
        setError(false);
      };
      getExtraWalletsRequest();
      return web3Ref.current.subscribeToProviderEvent("accountsChanged", onAccountChanged);
    }
  }, [web3Ref.current.provider]);

  useEffect(() => {
    if (fetched && currentAddress && !limitReached) {
      setLoading(true);
      walletExists(currentAddress)
        .then(({ exists }) => setError(exists))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [currentAddress, fetched]);

  return (
    <WalletBalanceWrapper>
      <MainCard mainWallet={mainWallet} />
      <Box $height="1px" $bgColor="01Primary100" $spacing={isMobile ? "my-5" : "my-6"} $width="100%" />
      <AdditionalWallets
        wallets={additionalWallets}
        connectNewWallet={connectNewWallet}
        disconnectWallet={disconnectWallet}
        hasError={hasError}
        getExtraProvider={getExtraProvider}
        loading={loading}
        disableConnect={disableConnect}
        showDetected={showDetected}
        length={lengthAdditionalWallets}
      />
    </WalletBalanceWrapper>
  );
};

const WalletBalanceWrapper = styled(TabWrapper)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media ${breakpoints.mobile} {
    align-items: flex-start;
  }
`;

export default WalletsTab;
