import { IconSvg, Tooltip } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";

const WhatIsThriveTooltip = ({ width, $color }) => {
  const { showModal, hideModal } = useStoreActions((actions) => actions.modals);

  const openAboutUs = (event) => {
    showModal({ modalName: "AboutUsModal", hideModal });
    event.preventDefault();
    return false;
  };
  return (
    <Tooltip
      $bgColor="01Primary0"
      $direction="top"
      $color="02Primary700"
      $minWidth="290px"
      arrowPosition="90%"
      $transform="translate(-89%)"
      text="Learn More"
      onClick={openAboutUs}
      $textAlign="left"
      message="ThriveCoin ($THRIVE) is a cryptocurrency you’re rewarded with for making contributions to Communities you love. "
    >
      <IconSvg $color={color ? $color : "#26385C"} $width={width} $spacing="mt-2" icon="help-circle" />
    </Tooltip>
  );
};

WhatIsThriveTooltip.defaultProps = {
  width: "14px",
};

export default WhatIsThriveTooltip;
