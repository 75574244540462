import { Box, Button, Checkbox, Text } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import styled from "styled-components";
import StatusBox from "./StatusBox";

export const RowElement = ({
  username,
  identifier,
  image_url,
  id,
  created_at,
  className,
  selected,
  status,
  unAssign,
}) => (
  <tr data-id={id} className={className} data-selected={selected} data-status={status}>
    <td>
      <Checkbox name={`checkbox-${id}`} checked={selected} data-id={id} kind="multi-theme" />
    </td>
    <td>
      <Box $flex $gap="16px">
        <CircleImage src={image_url} />
        <Text $typography="body/medium" $bold>
          {username || identifier}
        </Text>
      </Box>
    </td>
    <td>{formatDate(created_at)}</td>
    <td>
      <StatusBox status={status} />
    </td>
    <td>
      <Box $flex $gap="16px" $justifyContent="end" $alignItems="center">
        <Button
          data-id={id}
          onClick={unAssign}
          kind="outline"
          leftIcon="trash-bold"
          $size="sm"
          $borderRadius="40px"
          $borderColor="02Secondary500"
        >
          Unassign
        </Button>
      </Box>
    </td>
  </tr>
);

const CircleImage = styled.img`
  border-radius: 50%;
  width: 26px;
  height: 26px;
`;

RowElement.defaultProps = {
  description: "Vote on all 4 of the Questbook Domain Allocators polls on Snapshot [Raffle] -- Sep. 6 to Sep. 13",
  image: "https://thrivecoin-assets.s3.amazonaws.com/images/apps/discord.svg",
  reward: "-",
  maxReward: "-",
  selectedIDs: [],
};

export default RowElement;
