import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import useColors from "../../useColors";
import Donations from "./Donations";
import ToggleButton from "./ToggleButton";

import GOLD_HAND from "@assets/gold_hand.svg";

const SeasonPrizes = ({ season, seasonDetails }) => {
  const [expanded, setExpanded] = useState(false);
  const { isMobile } = useStoreState((state) => state.media);
  const raffleSystem = season.reward_system === "raffle";
  const automaticDistribution = season?.reward_distribution_type === "automatic";
  const prizesText = raffleSystem ? "Raffle Prizes to Win" : "Prizes to Win";

  const toggleExpanded = () => setExpanded((prev) => !prev);
  const { theme } = useColors(season);
  const showDonations =
    season.status === "active" && season.with_donations && +seasonDetails?.rewards_earned > 0 && !automaticDistribution;

  return (
    <PrizesBox $bgColor="headerGradient" $color="headerText" $raffleSystem={raffleSystem} onClick={toggleExpanded}>
      <Box>
        <Box $flex $alignItems="center">
          <Text $bold $typography="heading/small" $spacing="ml-5" $sm-spacing="ml-0">
            {prizesText}
          </Text>
          {isMobile && (
            <Box $spacing="ml-a">
              <ToggleButton expanded={expanded} setExpanded={setExpanded} />
            </Box>
          )}
        </Box>
        {(expanded || !isMobile) && (
          <RewardsContainer $raffleSystem={raffleSystem}>
            {season.prizes?.map((item, i) => (
              <Box $flex key={i} $alignItems="center" $gap="15px" $spacing="mt-4 mx-5">
                <Box
                  $flex
                  $alignItems="center"
                  $justifyContent="center"
                  $height="32px"
                  $minWidth="32px"
                  $borderRadius="50%"
                  $bgColor={theme.accentColor}
                >
                  <IconSvg icon={item.icon || "reward-image"} $color="02Primary0" $width="16px" />
                </Box>
                <Text $bold $typography="body/medium">
                  {item.text}
                </Text>
              </Box>
            ))}
          </RewardsContainer>
        )}
      </Box>
      <DonationsWrapper>
        {showDonations ? (
          <Donations {...season} {...seasonDetails} />
        ) : (
          (expanded || !isMobile) && <HandImg src={GOLD_HAND} />
        )}
      </DonationsWrapper>
    </PrizesBox>
  );
};

const PrizesBox = styled(Box)`
  padding: 20px 0 40px 0;
  border-radius: 8px 8px 0px 0px;

  display: flex;
  ${({ $raffleSystem }) => $raffleSystem && "flex-direction: column;"}

  @media ${breakpoints.desktop} {
    flex-grow: 1;
  }

  @media ${breakpoints.miniTablet}, ${breakpoints.mobile} {
    ${({ $raffleSystem }) => !$raffleSystem && "flex-direction: column;"}
  }

  @media ${breakpoints.mobile} {
    padding: 20px 20px 24px;
    ${({ $raffleSystem }) => !$raffleSystem && "padding-bottom: 0;"}
  }
`;

const RewardsContainer = styled(Box)`
  ${({ $raffleSystem }) => $raffleSystem && "width: 100%;"}
  display: grid;
  grid-template-columns: ${({ raffleSystem }) => raffleSystem && "1fr"} 1fr;

  @media ${breakpoints.miniTablet}, ${breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

const DonationsWrapper = styled(Box)`
  padding: 0 20px;
  min-width: 240px;
  max-width: 390px;
  flex-grow: 1;
  position: relative;
  display: flex;
  margin-left: auto;

  @media ${breakpoints.miniTablet} {
    margin-left: unset;
    max-width: unset;
    min-width: unset;
    margin-top: 20px;
    min-height: 153px;
  }

  @media ${breakpoints.mobile} {
    min-width: unset;
    max-width: unset;
    margin-left: unset;
    margin-top: 30px;
    padding: unset;
  }
`;

const HandImg = styled.img`
  width: 200px;
  position: absolute;
  bottom: -40px;

  @media ${breakpoints.miniTablet} {
    left: 50%;
    transform: translateX(-50%);
  }

  @media ${breakpoints.mobile} {
    position: unset;
    margin: 0 auto;
  }
`;

export default SeasonPrizes;
