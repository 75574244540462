import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const FilterTitle = ({ headingText, isMobile, isDashboard }) => {
  return (
    <WrapperText $gap="60px" $direction={isDashboard && !isMobile && "column"} $flex>
      <Box $width="100%" $justifyContent="space-between" $alignItems="center" $flex>
        <Text $typography="heading/small" $color="01Primary700">
          {headingText}
        </Text>
      </Box>
    </WrapperText>
  );
};

const WrapperText = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  z-index: 9;

  @media ${breakpoints.tablet} {
    margin-bottom: 40px;
    max-width: calc(100vw - 64px);
  }

  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
    gap: 8px;
  }
`;

export default FilterTitle;
