import { Box, ContextMenu, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const SeasonListingOptions = ({ id, seasonName, removeFromSeason, updateReward, status }) => {
  return (
    <IconContextMenu $circle={false}>
      <ul>
        {status != "pending" && (
          <li data-id={id} onClick={updateReward}>
            Edit
          </li>
        )}
        <li data-id={id} onClick={removeFromSeason}>
          Remove from {seasonName}
        </li>
      </ul>
    </IconContextMenu>
  );
};

export const CommunityListingOptions = ({ id, editListing }) => {
  return (
    <IconContextMenu $circle={false}>
      <ul>
        <li onClick={editListing} data-id={id}>
          Edit
        </li>
      </ul>
    </IconContextMenu>
  );
};

export const IconContextMenu = ({ children, icon, $circle }) => {
  const ContextButton = $circle ? CircleButton : PlainButton;
  return (
    <ContextMenu icon={icon} ContextButton={ContextButton} rightContainer="1%">
      {children}
    </ContextMenu>
  );
};

const CircleButton = ({ onClick, rightIcon, Container = StyledBox }) => {
  return (
    <Container $spacing="p-2" $flex onClick={onClick}>
      <IconSvg icon={rightIcon} $width="15px" />
    </Container>
  );
};

const PlainButton = (props) => <CircleButton {...props} Container={Box} />;

IconContextMenu.defaultProps = {
  icon: "more-vertical",
  $circle: true,
};

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
`;

export const CircledIcon = styled(IconSvg)`
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
`;

export const CircleImage = styled.img`
  border-radius: 50%;
  ${({ $size = "26px" }) => `width: ${$size}; height: ${$size}`}
`;

export const TabWrapper = styled(Box)`
  padding: 32px;
  max-width: 1320px;
  margin: 0 auto;
`;
