import { Box, Button } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";

const CustomButton = (props) => <Button $borderRadius="47px" {...props} />;

const BottomButtons = ({ slug, id, status }) => {
  const { filteredSeasons } = useStoreState((state) => state.season);
  const season = filteredSeasons.length > 0 && filteredSeasons[0];
  const communityPath = `/communities/${slug || id}`;
  const seasonPath = !!season && `/seasons/${season?.slug || season?.id}`;

  return (
    <Box $spacing="ml-a" $flex $alignItems="center" $gap="16px">
      {status === "active" && (
        <>
          <Link to={communityPath}>
            <CustomButton iconWidth="12px" rightIcon="arrow-up-right">
              View Community
            </CustomButton>
          </Link>
          {!!season && (
            <Link to={seasonPath}>
              <CustomButton iconWidth="12px" rightIcon="arrow-up-right">
                View Season
              </CustomButton>
            </Link>
          )}
        </>
      )}
    </Box>
  );
};

export default BottomButtons;
