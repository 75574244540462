import Separator from "@common/Separator";
import { BadgeCard, Box, Button, Modal, TagsInput, Text } from "@thrivecoin/ui-components";
import { numberWithCommas } from "@utils/formatting";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";

const { BadgeUserImages } = BadgeCard;

const AssignBadgeHolderModal = ({ hideModal, id, image_urls, callback }) => {
  const [identifiers, setIdentifiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invalidAddresses, setInvalidAddresses] = useState([]);
  const { getBadgdeRequest } = useStoreActions((actions) => actions.badges);
  const { dangerToast, showToast } = useStoreActions((actions) => actions.toasts);
  const { byID: badgeByID } = useStoreState((state) => state.badges);
  const { pagination } = useStoreState((state) => state.userBadge);
  const { bulkAssignRequest, bulkAssignCSVRequest } = useStoreActions((actions) => actions.userBadge);
  const badge = badgeByID(id);
  const disabled = identifiers.length == 0;

  const onChange = (addresses) => {
    const updatedInvalidAddresses = invalidAddresses.filter((item) => addresses.includes(item));
    setInvalidAddresses(updatedInvalidAddresses);
    setIdentifiers(addresses);
  };
  const triggerUpload = () => document.getElementById("bulk-csv-upload").click();

  const handleRequest = (promise) => {
    setLoading(true);
    promise
      .then((data) => {
        showToast({
          content: data.message,
          type: "success",
          dismissTime: 5000,
        });
      })
      .then(callback)
      .then(hideModal)
      .catch(({ response }) => {
        const content = response.data.data?.errors?.identifier?.[0] || response.data.message;
        if (content.includes("Invalid addresses")) {
          const addresses = content.split(": ")[1].split(", ");
          setInvalidAddresses(addresses);
        } else {
          dangerToast({ content });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const csvAssign = (event) => {
    const csv = event.currentTarget.files[0];
    if (csv) {
      handleRequest(bulkAssignCSVRequest({ id, csv }));
    }
  };

  const submit = () => handleRequest(bulkAssignRequest({ id, identifiers }));

  const validationFN = (tag) => !invalidAddresses.includes(tag);
  const validationError = invalidAddresses.length
    ? `${invalidAddresses.length} out of ${identifiers.length} addresses could not be added to ${badge?.name}`
    : "";

  useEffect(() => {
    if (!badge) {
      getBadgdeRequest(id);
    }
  }, [id]);

  return (
    <Modal open onClose={hideModal} $maxWidth="620px" $paddingBody="16px 64px 0">
      <Box $flex $gap="24px" $direction="column">
        <Text $typography="Heading/Medium" $center>
          Assign Badge Contributors
        </Text>
        <Separator />
        <Box $flex $alignItems="center" $gap="16px">
          <Image src={badge.logo_url} />
          <Text $typography="Heading/Small">{badge.name}</Text>
          <Box $spacing="ml-a" $flex $alignItems="center">
            <Text $typography="body/large" $bold>
              {numberWithCommas(pagination.total)}
            </Text>
            <BadgeUserImages image_urls={image_urls} count={pagination.total} />
          </Box>
        </Box>
        <Separator />
        <Box $flex $gap="16px" $direction="column">
          <Text $typography="Heading/Small" $bold>
            Assign users a Badge
          </Text>
          <Text>
            You can assign badges to users in bulk by pasting their wallet addresses in the field below, separated with
            numbered points (simply press enter after each entry). Additionally, you can copy from your Google Sheet or
            upload a CSV file.
          </Text>
          <Button
            onClick={triggerUpload}
            kind="outline"
            rightIcon="export-cloud"
            variant="01Primary300"
            $size="sm"
            $width="153px"
            $borderRadius="40px"
            $spacing="px-0"
          >
            Upload CSV File
          </Button>
          <input id="bulk-csv-upload" type="file" accept=".csv" onChange={csvAssign} hidden />
        </Box>
        <TagsInput
          onChange={onChange}
          placeholder="0xe5f6dbc39334f3e79c149efb8c8c9c8dec474af1"
          $ordered
          $list
          $error={validationError}
          validationFN={validationFN}
        />
        <Separator />
        <Box $flex $gap="12px">
          <Button loading={loading} kind="outline" $borderRadius="40px" $grow="2" onClick={hideModal}>
            Cancel
          </Button>
          <Button loading={loading} $borderRadius="40px" $grow="0.5" disabled={disabled} onClick={submit}>
            Submit Wallet Addresses
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const Image = styled.img`
  width: 68px;
  height: 68px;
`;

AssignBadgeHolderModal.defaultProps = {
  image_urls: [],
  count: 0,
};

export default AssignBadgeHolderModal;
