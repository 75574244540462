import styled from "styled-components";
import TableDataElement from "../../../common/TableDataElement";
import UsernameAvatar from "../../../common/UsernameAvatar";

const TableRow = ({
  username,
  image_url,
  onProfileClick,
  index,
  id,
  user_id,
  contributions_count,
  raffle_entries_count,
}) => {
  return (
    <>
      <tr>
        <UserNameTd>
          <UsernameAvatar
            username={username}
            dataID={id}
            onProfileClick={onProfileClick}
            image_url={image_url}
            index={index}
            user_id={user_id}
          />
        </UserNameTd>

        <TableDataElement text={contributions_count} $bold />

        <TableDataElement text={raffle_entries_count} $bold />
      </tr>
    </>
  );
};

const UserNameTd = styled.td`
  padding: 26px 16px;
  ${({ $pointer }) => $pointer && `cursor: pointer;`}
`;
export default TableRow;
