import { Box, breakpoints, Button, Countdown, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

import HAND from "@assets/hand_green_background.svg";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const UnclaimedSeasonCard = ({ order_number, reward_claiming_config, slug, id }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const { isMiniTablet, isMobile } = useStoreState((state) => state.media);
  const seasonId = slug || id;

  const collapsable = isMiniTablet || isMobile;
  const titleTypography = collapsable ? "heading/medium" : "heading/large";
  const greenTextTypography = collapsable ? "heading/small" : "body/x-large";
  const showContent = expanded || !collapsable;
  const { deadline } = reward_claiming_config || {};

  const toggle = () => setExpanded((prev) => !prev);
  const goToSeason = () => navigate(`/seasons/${seasonId}`);

  return (
    <Wrapper $relative $bgColor="02Primary500" $spacing="mb-6" $flex>
      <Box $spacing="p-6" $md-spacing="p-5" $sm-spacing="p-4">
        <Title $flex>
          <Text $typography={titleTypography} $spacing="mr-4" $color="02Primary0">
            Season {order_number} is Completed!
          </Text>
          <Text $extraBold $typography={greenTextTypography} $color="03Primary500">
            You can now claim your earnings.
          </Text>
        </Title>
        {showContent && (
          <BottomBox $flex $spacing="mt-5">
            {deadline && (
              <Box>
                <DateWrapper $bgColor="02Primary300" $flex $gap="4px" $color="02Primary0">
                  <Box $flex $alignItems="center" $gap="4px">
                    <IconSvg icon="pacman" $width="16px" />
                    <Text $bold $typography="body/small" $spacing="mr-1">
                      Claim Within
                    </Text>
                  </Box>
                  <Countdown countdown={deadline} $color="02Primary300" $bgColor="01Secondary500" />
                </DateWrapper>
                <Box $flex $alignItems="center" $color="error" $gap="6px" $spacing="mt-4">
                  <IconSvg icon="alert-triangle" $width="14px" />
                  <Text $typography="body/small" $bold>
                    Unclaimed earnings are auto-donated.
                  </Text>
                </Box>
              </Box>
            )}
            <StyledButton
              $bold
              $borderColor="02Primary0"
              $hoverBackground="02Primary0"
              $bgColor="02Primary0"
              $color="02Primary500"
              $borderRadius="40px"
              onClick={goToSeason}
            >
              Go to Season
            </StyledButton>
          </BottomBox>
        )}
      </Box>
      {!collapsable && (
        <Box $grow="1">
          <Image src={HAND} />
        </Box>
      )}
      {collapsable && (
        <CollapseButton $pointer onClick={toggle}>
          <IconSvg $color="02Primary0" icon={expanded ? "chevron-up" : "chevron-down"} $width="22px" />
        </CollapseButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  border-radius: 12px;
  overflow: hidden;
`;

const Title = styled(Box)`
  align-items: flex-end;

  @media ${breakpoints.miniTablet}, ${breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const BottomBox = styled(Box)`
  align-items: center;
  gap: 28px;

  @media ${breakpoints.miniTablet}, ${breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

const DateWrapper = styled(Box)`
  border-radius: 5px;
  height: 34px;
  width: 432px;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    height: unset;
    width: unset;
    padding: 10px 6px;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CollapseButton = styled(Box)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors["03Primary500"]};

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 16px;
  right: 16px;

  @media ${breakpoints.mobile} {
    width: 32px;
    height: 32px;
  }
`;

export default UnclaimedSeasonCard;
