import { HeaderText, IconText, TableHead, TableHeader } from "@common/TableElements";
import { IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ListingsTable = ({ onSort, isMobile, isDesktop, sortIcon, sortType, isCommunity, isTablet, children }) => {
  return (
    <Table>
      <TableHead onClick={onSort} display={isMobile && "none"}>
        <TableHeader $width={isDesktop && "384px"} $textAlign="center">
          <IconText>
            <IconSvg $width="15px" icon="globe-not-filled" />
            {sortType === "network" && <IconSvg data-name="network" $width="7.5px" icon={sortIcon} />}

            <HeaderText data-name="contribution" $typography="body/small" $color="secondary800" $bold>
              Contribution
              {sortType === "contribution" && <IconSvg $width="7.5px" icon={sortIcon} />}
            </HeaderText>
          </IconText>
        </TableHeader>
        <TableHeader>
          {" "}
          <HeaderText data-name="reward" $typography="body/small" $color="secondary800" $bold>
            {isCommunity ? "Reward" : "End of Season Prize"}{" "}
            {sortType === "reward" && <IconSvg $width="7.5px" icon={sortIcon} />}
          </HeaderText>
        </TableHeader>
        <TableHeader $minWidth={"80px"}>
          {" "}
          <HeaderText $typography="body/small" $color="secondary800" $bold>
            Can Be Completed
          </HeaderText>
        </TableHeader>
        <TableHeader display={isTablet && "none"} $minWidth={"197px"}>
          <HeaderText data-name="verification_method" $typography="body/small" $color="secondary800" $bold>
            Completion Check {sortType === "verification_method" && <IconSvg $width="7.5px" icon={sortIcon} />}
          </HeaderText>
        </TableHeader>
        <TableHeader></TableHeader>
      </TableHead>
      <tbody>{children}</tbody>
    </Table>
  );
};

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  tbody:before {
    content: ".";
    display: block;
    line-height: 12.2px;
    text-indent: -99999px;
  }
`;

export default ListingsTable;
