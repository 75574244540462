import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const GrayContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundThrive};
  padding: 0;

  @media ${breakpoints.desktop} {
    padding: ${({ $padding }) => ($padding ? $padding : "32px 0")};
  }
`;

export const Wrapper = styled(Box)`
  position: relative;
  border-radius: ${({ $borderRadius }) => ($borderRadius ? $borderRadius : "12px")};
  margin: 0 auto;
  background: ${({ theme, $bgColor }) => ($bgColor ? theme.colors[$bgColor] : theme.colors["01Surface"])};
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    width: ${({ $isContributors }) => ($isContributors ? "auto" : "100%")};
  }

  @media ${breakpoints.mobile} {
    border-radius: 0;
    width: 100%;
  }
`;

export const TableOfContributions = styled.table`
  width: 100%;
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : "59px")};
  padding: ${({ $padding }) => $padding && $padding};
  position: relative;
  overflow-x: scroll;
  border-collapse: separate;
  border-spacing: 0;
  @media ${breakpoints.belowDesktop} {
     margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : "8px")};
  }
    @media ${breakpoints.mobile} {
     margin-top: 25px;
  }
  tbody {
    width: 100%;
  }
  tbody > tr {
    td:first-child {
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const WrapperContent = styled(Box)`
  @media ${breakpoints.tablet} {
    border-radius: 8px;
    ${({ theme, border }) => border && `border: 1px solid  ${theme.colors["01Primary200"]}`};
  }
`;

export const Title = ({ children }) => (
  <Text $bold $size="20px" $lHeight="24px" $fontFamily="Outfit" $spacing="mx-a" $sm-spacing="mr-6">
    {children}
  </Text>
);

export const LinkThankYou = styled.a`
    $color ${({ theme }) => theme.colors["link"]};
    border 1px solid ${({ theme }) => theme.colors["link"]};
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding: 11px 24px;
    border-radius: 40px;
    float: right;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      $color ${({ theme }) => theme.colors["link"]};
      border-color  ${({ theme }) => theme.colors["01Secondary400"]};
    }
`;
