import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const STATUSES = {
  pending: {
    type: "pending",
    name: "Waiting for Raffle...",
    icon: "timer",
    $color: "02Primary0",
    $bgColor: "01Secondary500",
    $bgColorRow: "01Secondary300",
    textRowColor: "02Primary500",
  },
  selected: {
    type: "selected",
    name: "You are a Winner!",
    icon: "circle-check",
    $color: "02Primary0",
    $bgColor: "03Primary500",
    $bgColorRow: "03Primary300",
    textRowColor: "02Primary500",
  },
  not_selected: {
    type: "not_selected",
    name: "Entry Not selected",
    icon: "circle-minus",
    $color: "01Primary0",
    $bgColor: "01Primary200",
    $bgColorRow: "01Primary100",
    textRowColor: "01Primary700",
  },
};

const Status = ({ status }) => {
  const currentStatus = STATUSES[status];
  return (
    <StatusWrapper $color={currentStatus?.$color} $typography="body/medium" $bgColor={currentStatus?.$bgColor}>
      <Icon icon={currentStatus.icon} $color={currentStatus?.$color} $width="16px" />
      <Text $typography="body/small" $bold>
        {" "}
        {currentStatus?.name}{" "}
      </Text>
    </StatusWrapper>
  );
};

const StatusWrapper = styled(Box)`
  padding: 3.5px 10.5px;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 28px;
  gap: 6px;
  ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor};`}
`;

const Icon = styled(IconSvg)`
  path {
    fill: ${({ theme, $color }) => theme.colors[$color]};
  }
`;
export default Status;
