import { Box, breakpoints, Button } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ButtonList = ({ index, maxStep, submit, cancel, isPersisted, loading, setStep }) => {
  const text = (isPersisted ? "Update" : "Create") + " Community";
  const nextStep = () => setStep((step) => step + 1);
  const prevStep = () => setStep((step) => step - 1);

  return (
    <ButtonsContainer $flex $gap="16px" $width="556px" $spacing="mx-a mb-6">
      {index === 0 && (
        <Button
          kind="outline"
          variant="navyBlue"
          $bold
          onClick={cancel}
        >
          Cancel
        </Button>
      )}
      {index > 0 && (
        <Button
          kind="outline"
          variant="navyBlue"
          $bold
          onClick={prevStep}
        >
          Back
        </Button>
      )}
      {index < maxStep && (
        <Button $bold onClick={nextStep}>
          Next
        </Button>
      )}
      {index == maxStep && (
        <Button onClick={submit} loading={Boolean(loading)} $bold>
          {text}
        </Button>
      )}
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled(Box)`
  @media ${breakpoints.belowDesktop} {
    padding: 0 24px;
  }
  @media ${breakpoints.mobile} {
   gap: 24px;
  }

  ${Button} {
    flex-grow: 1;
    border-radius: 50px;
    font-weight: 600;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column-reverse;
    width: unset !important;
    padding: 0 16px;
    margin-bottom: 50px;
  }
`;

export default ButtonList;
