import { Box, Button, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const HeaderButtons = ({ onClickPreview, onClose, icon, textBtn, hidePreview, saveAsDraft }) => (
  <Wrapper>
    {!hidePreview && (
      <Button
        $size="md"
        kind="outline"
        variant="01Primary700"
        $spacing="p-2"
        $borderRadius="40px"
        rightIcon={icon}
        onClick={onClickPreview}
        leftIcon="eye"
      >
        {textBtn}
      </Button>
    )}

    {saveAsDraft && (
      <Button
        $bold
        $spacing="p-2"
        $borderRadius="40px"
        $height="44px"
        kind="outline"
        $color="01Primary700"
        $borderColor="03Secondary500"
        onClick={saveAsDraft}
      >
        Save as Draft
      </Button>
    )}

    <Button
      $size="md"
      kind="outline"
      $heightSize="44px"
      $minWidth="44px"
      $spacing="px-0"
      $borderColor="01Primary300"
      $color="01Primary700"
      $borderRadius="50%"
      onClick={onClose}
    >
      <XIcon icon="x" $width="12px" $color="01Primary700" />
    </Button>
  </Wrapper>
);

const Wrapper = styled(Box)`
  display: flex;
  gap: 12px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
`;

const XIcon = styled(IconSvg)`
  stroke-width: 3;
`;

HeaderButtons.defaultProps = {
  icon: "chevron-down",
  textBtn: "Preview",
};

export default HeaderButtons;
