import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const AboutSeason = () => {
  const navigate = useNavigate();
  const { season } = useStoreState((state) => state.season);
  const { isDesktop } = useStoreState((state) => state.media);
  const { about } = season;

  const onCommunityClick = () => navigate(`/communities/${season.community_slug}`);

  return (
    <TabWrapper>
      <Box>
        <WhiteBox
          $flex
          $direction={!isDesktop && "column"}
          $gap={isDesktop ? "100px" : "32.58px"}
          $justifyContent="space-between"
          $color="01Primary700"
          $width="100%"
        >
          <Box>
            <Text $typography="heading/small" $spacing="mb-5">
              About {season.title}
            </Text>

            <Box $flex $direction="column" $gap="5px" $spacing="mb-4">
              <Text $typography="body/small" $bold>
                Community
              </Text>
              <Link onClick={onCommunityClick} $typography="body/medium" $color="link" $extraBold>
                {season.community_name}
              </Link>
            </Box>

            <Box $flex $direction="column" $gap="5px" $spacing="mb-4">
              <Text $typography="body/small" $bold>
                Season
              </Text>
              <Text $typography="body/large" $bold>
                {season.order_number}
              </Text>
            </Box>

            <Box $flex $direction="column" $gap="5px" $spacing="mb-4">
              <Text $typography="body/small" $bold>
                Season started
              </Text>
              <Text $typography="body/large" $bold>
                {moment(season.start_date).format("MMMM Do, YYYY")}
              </Text>
            </Box>

            <Box $flex $direction="column" $gap="5px">
              <Text $typography="body/small" $bold>
                Season ends
              </Text>
              <Text $typography="body/large" $bold>
                {moment(season.end_date).format("MMMM Do, YYYY")}
              </Text>
            </Box>
          </Box>
          <AboutText $typography="body/medium" dangerouslySetInnerHTML={{ __html: about }} />
        </WhiteBox>
      </Box>
    </TabWrapper>
  );
};

const AboutText = styled(Text)`
  padding-left: 71.02px;
  border-left: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  max-width: 679px;
  line-height: 28px;
  margin-right: auto;
  @media ${breakpoints.belowDesktop} {
    border: none;
    max-width: unset;
    padding: 0;
  }
`;

const WhiteBox = styled(Box)`
  border-radius: 12px;
  padding: 32.44px 63.85px 163.2px 31.96px;
  background: ${({ theme }) => theme.colors.surfaces};
`;

const Link = styled(Text)`
  cursor: pointer;
`;
export default AboutSeason;
