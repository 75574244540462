import { Box, Button, Checkbox, Text } from "@thrivecoin/ui-components";
import { CircledIcon, CircleImage } from "../common";

export const ListingRow = ({
  name,
  image_url,
  id,
  listing_id,
  addSeason,
  className,
  selected,
  status,
  OptionsComponent,
  previewListing,
  ...props
}) => (
  <tr data-id={id} className={className} data-selected={selected} data-status={status}>
    <td>
      <Checkbox name={`checkbox-${id}`} checked={selected} data-id={id} kind="gray-check" />
    </td>
    <td>
      <Box $flex $gap="12px">
        <CircleImage src={image_url} />
        <Text $typography="body/medium" $bold>
          {name}
        </Text>
      </Box>
    </td>
    <td>
      <Box $flex $gap="16px" $justifyContent="end" $alignItems="center">
        <Button
          data-id={id}
          onClick={addSeason}
          rightIcon="plus"
          $size="sm"
          $borderRadius="40px"
          $typography="Other/Buttons"
          $bgColor="01Secondary500"
        >
          Add to Season
        </Button>
        <CircledIcon icon="eye2" $width="16px" onClick={previewListing} data-id={id} data-listing-id={listing_id} />
        <OptionsComponent id={id} {...props} />
      </Box>
    </td>
  </tr>
);

ListingRow.defaultProps = {
  description: "Vote on all 4 of the Questbook Domain Allocators polls on Snapshot [Raffle] -- Sep. 6 to Sep. 13",
  image: "https://thrivecoin-assets.s3.amazonaws.com/images/apps/discord.svg",
  reward: "-",
  maxReward: "-",
  selectedIDs: [],
};

export default ListingRow;
