import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext } from "react";
import styled from "styled-components";
import { CommunityEditorContext } from "../../CommunityEditorContext";
import Header from "./Header";
import Inputs from "./Inputs";

const Tags = () => {
  const { communityTags, setCommunityTags } = useContext(CommunityEditorContext);
  const { isMobile } = useStoreState((state) => state.media);
  return (
    <StyledBox>
      <Header />
      <Text $typography="body/small" $spacing={isMobile ? "mb-5" : "mb-6"}>
        You can change the default order of the tags by dragging and dropping the items below.
      </Text>
      <Inputs communityTags={communityTags} setCommunityTags={setCommunityTags} isMobile={isMobile}/>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    padding: 0 24px;
  }
  @media ${breakpoints.mobile} {
    padding: 0 16px;
  }
`;
export default Tags;
