import { breakpoints } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

const CircleSeparator = () => {
  const { isMobile } = useStoreState((state) => state.media);
  const lengthNum = isMobile ? 21 : 16;

  return (
    <Container>
      <Triangle />
      <Layer>
        {Array.from({ length: lengthNum }, (_, index) => (
          <InsideLayer key={index} />
        ))}
      </Layer>
      <Triangle $transform="rotate(-0deg)" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: -10px;
`;

const Layer = styled.div`
  height: 89px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 3px 0;
  @media ${breakpoints.mobile} {
    height: 121px;
  }
`;

const InsideLayer = styled.div`
  min-width: 4px;
  min-height: 4px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors["01Primary0"]};
`;

const Triangle = styled.div`
  height: 7.72px;
  width: 20.49px;
  background: ${({ theme }) => theme.colors["01Primary0"]};
  transform: ${({ $transform = "rotate(-180deg)" }) => $transform };
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  margin-top: -0.5px;
`;

export default CircleSeparator;
