export const downloadBlobResponse = (fileName) => (response) => {
  const blobUrl = URL.createObjectURL(new Blob([response.data]));
  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(blobUrl);
};
