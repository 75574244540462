import { Box, breakpoints, Button } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import useWeb3 from "../hooks/useWeb3";
import ThemeSwitcher from "./ThemeSwitcher";

const GroupedButtons = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  gap: 16px;
`;

const AuthButtons = ({ isMobileModalOpen }) => {
  const { loginFlow } = useWeb3();
  const { isMobile, isTablet, isDesktop, isMiniTablet } = useStoreState((state) => state.media);
  const { authLoading } = useStoreState((state) => state.authentication);
  const { communityBrand } = useStoreState((state) => state.community);
  const referral_code = sessionStorage.getItem("referral-code");
  const mobileModalOpen = isMobile && isMobileModalOpen;
  const showForTabletDesktop = (isTablet || isDesktop) && !isMiniTablet;

  const connectWeb3 = () => loginFlow(referral_code);

  return (
    <Box $flex $alignItems="center">
      {showForTabletDesktop && <ThemeSwitcher />}
      <GroupedButtons>
        <ConnectButton
          $typography="body/medium"
          $bold={!isMobile}
          $extraBold={isMobile}
          $color="01Primary0"
          $borderRadius="40px"
          $bgColor={communityBrand?.brand_color || "03Primary500"}
          $heightSize="41px"
          onClick={connectWeb3}
          loading={authLoading}
          $minWidth={isMobile ? "145px" : "143px"}
          $lineHeight={isMobile ? "21px" : "22px"}
          $mobileModalOpen={mobileModalOpen}
        >
          Connect Wallet
        </ConnectButton>
      </GroupedButtons>
    </Box>
  );
};

const ConnectButton = styled(Button)`
  ${({ theme, $mobileModalOpen, $bgColor }) => `
    background-color :${theme.colors[$mobileModalOpen ? "navigation" : "navigationCta"]};
    background-color: ${theme.colors[$bgColor] || $bgColor};
  `}

  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export default AuthButtons;
