import { Text } from "@thrivecoin/ui-components";
import { GoBackHome, SubHeader, Wrapper, WrapperBox } from "./common";

const TermsServices = () => (
  <Wrapper>
    <WrapperBox>
      <GoBackHome />
      <Text $typography="heading/large" $center $spacing="mb-4">ThriveCoin</Text>
      <Text $typography="heading/medium" $center $spacing="mb-6">TERMS OF SERVICE</Text>
      <Text $typography="body/large" $extraBold $spacing="mb-4">Last Updated:  July 4, 2023</Text>

      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        Welcome! These Terms of Service (<b>"Terms”</b>) constitute an agreement by and between you (“<b>User</b>,” "
        <b>you</b>," or "<b>your</b>") and ThriveCoin Inc. (“<b>ThriveCoin</b>,” “<b>we</b>,” “<b>us</b>,” or “
        <b>our</b>”). These Terms, together with any documents they expressly incorporate by reference, govern your
        access to and use of the ThriveCoin web or mobile applications (our
        “<b>Apps</b>”) and any other content, software, tools, features, or functionalities provided on or in connection
        with our services (collectively, our “<b>Services</b>”). By accessing or using our Services, you agree to be
        bound by these Terms. If you do not agree to these Terms, you may not access or use our Services.
        <br />
        <br />
        You understand and agree that any and all use of our Services shall be a part of a beta experience("Beta"). While this
        Beta is temporary, we reserve the right to extend or shorten the length of the Beta term at any time. You may be
        prompted to enter your email address in order to access or use parts of our Services. We may contact you at the
        email address that you provide with questions and/or surveys about your experience. We will use your Feedback
        (as defined below) and usage data to improve our Services.
        <br />
        <br />
        PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE TERMS CONTAIN A MANDATORY
        ARBITRATION AGREEMENT IN SECTION 14.1 AND A CLASS ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 14.2 THAT
        REQUIRE, WITH ONLY SPECIFIED EXCEPTIONS OR UNLESS YOU OPT OUT PURSUANT TO THE PROVIDED INSTRUCTIONS, THE
        EXCLUSIVE USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS ONLY TO RESOLVE DISPUTES, RATHER THAN JURY
        TRIALS OR CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE ACTIONS OR PROCEEDINGS.
        <br />
        <br />
        Please note that if you are an individual and you access or use our Services on behalf of a company or other
        entity, such as your employer (together with its affiliates, an “<b>Organization</b>”), then: (a) these Terms
        are an agreement between (i) us and you and (ii) us and that Organization; (b) you represent and warrant that you have
        the authority to bind that Organization to these Terms (and if you do not have that authority, you may not
        access or use our Services); (c) your acceptance of these Terms will bind such Organization to these Terms; (d)
        your individual right to access and use our Services may be suspended or terminated if you cease to be
        associated with, or cease to use an email address associated with or provisioned by, that Organization; (e) we
        may disclose information regarding you and your use of our Services to such Organization; and (f) the terms
        “you” and “your”, as used in these Terms, refer to both you and such Organization, as the context requires. If you sign up for our
        Services using an email address associated with or provisioned by an Organization, or if an Organization pays
        fees due in connection with your access to or use of our Services (or reimburses you for payment of such fees),
        or otherwise, then, we may deem you to be accessing and using our Services on behalf of that Organization in our
        sole discretion.
      </Text>
    </WrapperBox>
    <WrapperBox>
      <SubHeader>1. USE OF OUR SERVICES</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>1.1 Eligibility.</b> You may use our Services only if you can form a legally binding contract with us (and on
        behalf of your Organization, as applicable), and only in compliance with these Terms and all applicable local,
        state,  provincial, national or federal, and international laws, ordinances, rules and regulations. To use our Services, you must be at least
        eighteen (18) years of age. You represent and warrant that you meet the applicable age requirements and are
        competent to agree to these Terms. Except as expressly allowed by these Terms, you may only use our Services for
        your personal, non-commercial use unless you enter into a separate agreement with us for your commercial use.
      </Text>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>1.2 User Accounts.</b> You may be required to create an account (your “<b>User Account</b>”) to fully use and
        engage with our Services. You may be required to provide personal information including your name, email address
        or phone number in order to create a User Account. Additionally, you may need to connect your crypto wallet
        (your “<b>Crypto Wallet</b>”) through third-party websites and services such as crypto wallets like MetaMask in order to use
        certain parts of our Services. You agree to provide us with truthful, accurate and complete information when
        creating and maintaining your User Account. Your submission of personal information to our Services is governed
        by our{" "}
        <a
          href="https://app.termly.io/document/privacy-policy/a7debbc6-4ea7-44e2-a736-34adeab8e0e4"
          target="_blank"
          rel="noreferrer"
        >
         Privacy Policy
        </a>
        . You acknowledge and agree that you do not own your User Account and that we may terminate your User Account, or
        otherwise suspend or restrict your access to and use of our Services, in our sole discretion and without
        liability, for any reason or no reason.
      </Text>

      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>1.3 Protection of User Accounts.</b> We care about the integrity and security of your personal information.
        However, we cannot guarantee that unauthorized third parties will never be able to defeat our security measures
        or use your personal information for improper purposes. You acknowledge that you provide your personal
        information at your own risk. You are solely responsible for the activity that occurs on your User Account, and
        you must keep your User Account password(s) strong and secure. You should notify us immediately of any breach of
        security or unauthorized use of your User Account. Any individual with access to your User Account can modify
        the User Account settings, access and billing information. We will not be liable for any losses caused by any
        unauthorized use of your User Account or for any changes to your User Account, including your ability to access
        your User Account or User Content (as defined below), made by any individual with access to the User Account.
      </Text>

      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>1.4 Restrictions on Use.</b> You will not, and will not permit anyone else to, directly or indirectly: (i)
        use any robot, spider, scraper, data mining tool, data gathering or extraction tool, or any other automated
        means, to access, collect, copy or record our Services or Services Content; (ii) reverse engineer, disassemble,
        decompile or otherwise attempt to discover the source code or structure, sequence and organization of all or any
        part of our Services (except as permitted by applicable law); (iii) rent, lease, resell, distribute or use our
        Services for timesharing, service bureau, or to aggregate purchases through our Services; (iv) copy, modify,
        alter or create derivative works of any part of our Services or Services Content (unless necessary solely to
        participate in our Services in their intended functionality); (v) remove or alter any proprietary notices or
        labels on or in our Services or Services Content; (vi) identify or refer to us or our Services in a manner that
        could reasonably imply an endorsement, relationship or affiliation with or sponsorship between you (or a third
        party) and us, without our prior express written consent; or (vii) engage in any activity not otherwise
        permitted by this Agreement or our policies.
        <br />
        <br />
        In addition to the restrictions set forth above, you are restricted from using our Services or any Content: (a)
        for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate
        any local, state, provincial, national or federal, or international laws, ordinances, rules or regulations; (d) to infringe
        upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass,
        abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, gender expression, gender identity, sexual
        orientation, religion, ethnicity, race, age, national origin, veteran status or disability; (f) to submit false or misleading
        information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in
        any way that will affect the functionality or operation of our Services or linked third-party websites or
        services; (h) to collect or track the personal information of others; (i) for any obscene or immoral purpose; or
        (j) to interfere with or circumvent the security features of our Services or linked third-party websites or
        services.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>2. ELECTRONIC COMMUNICATIONS</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>2.1 Consent.</b> By inputting or supplying your contact information, including an email address, address,
        mobile telephone number and/or wireline  telephone number, or by otherwise creating a User Account, you electronically
        consent to receive marketing or advertising messages communications including email or mobile push notices from
        ThriveCoin and third parties, such as changes to features of our Services and special offers. If you do not want
        to receive such messages, you may opt out or change your preferences by contacting our Services support team at
        support@thrivecoin.com, or by clicking the unsubscribe link within each marketing or advertising email message.
        Opting out of marketing communications will not prevent you from receiving Services-related notices.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>3. CONTENT</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>3.1 Services Content.</b> You agree that all materials, including information, data, software, text, design
        elements, graphics, images and other content (collectively, "<b>Content</b>"), contained in or delivered via our
        Services or otherwise made available by us in connection with our Services (collectively, the "
        <b>Services Content</b>") is owned by us and our licensors and is protected by copyrights, trademarks, services
        marks, trade secrets or other intellectual property and other proprietary rights and laws. Except as expressly
        authorized by us in writing or solely as necessary for your use of the intended functionality of our Services,
        you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly
        perform, publish, adapt, edit or create derivative works of any Services Content, or post any Services Content
        on any other website or in a networked computer environment for any purpose. Reproducing, copying or
        distributing any Services Content for any other purpose is strictly prohibited without our express prior written
        permission. You will use the Services Content only as permitted by these Terms and any applicable laws and
        regulations (foreign and domestic). Any rights not expressly granted herein are reserved.
      </Text>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>3.2 User Content.</b> Any data, text, graphics, images, illustrations, logos, trademarks, service marks,
        copyrights, audio, videos, music, work of authorship or information, comments, opinions, postings, messages,
        files, e-mail, data, or other materials (in whatever form) that you include in your Submissions (as defined
        below) or otherwise upload or post to our Services is "<b>User Content.</b>" You hereby grant to us a
        non-exclusive, worldwide, perpetual, irrevocable, royalty-free, transferable, sublicensable (through multiple
        tiers) right and license to use, reproduce, adapt, modify, distribute, translate, publish, create derivative
        works based on, perform, display and otherwise exploit your User Content in whole or in part, in any media now
        known or hereafter developed, for any purpose whatsoever, and to allow others to do so, without compensation to
        you or any third party. You represent and warrant that you have all the rights, power, authority and
        authorization necessary to contribute, provide and make your User Content available to us and others and to
        grant the foregoing license, and that your User Content (i) does not infringe, violate, misappropriate or
        otherwise conflict with the rights of any third party, (ii) complies with all applicable laws and regulations
        (foreign and domestic), and (iii) that you have all authorizations, consents, approvals and have made all
        disclosures required to provide your User Content to us and to allow us to use your User Content as provided in
        these Terms. We reserve the right to remove any User Content from our Services at any time and for any reason
        (including upon receipt of claims or allegations from third parties or authorities relating to User Content) or
        no reason. You are solely responsible for your User Content and its accuracy. We do not endorse User Content,
        nor will we take responsibility or assume liability for any User Content.
        <br />
        <br />
        <b>3.3 Feedback.</b> You hereby assign to us all right, title, and interest in and to any feedback, comments or
        suggestions regarding our Services or recommendations for changes to our Services that you provide to us
        (collectively, “<b>Feedback</b>”), and agrees that we are free to use such Feedback, without any attribution or
        compensation to you, for any purpose whatsoever.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>4. REQUESTS AND CONTRIBUTIONS</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>4.1 Posters and Contributors.</b> Users may post requests for other Users to perform specific tasks or
        otherwise provide some form of value toward the end of meeting a specific goal (each User posting a request, a “
        <b>Poster</b>”, and each request, a “<b>Request</b>”). Posters may incentivize Users to complete their Requests
        by offering rewards (“<b>Rewards</b>”) such as ThriveCoin and other cryptocurrencies, tokens, non-fungible
        tokens, or other digital collectibles (collectively, “<b>Digital Collectibles</b>”). Users may provide proof
        that they have completed the Request, as well as any other information as may be requested (a “<b>Submission</b>
        ”), in order to collect the offered Reward (each User providing a Submission, a “<b>Contributor</b>”). Requests
        are exclusively developed and administered by Posters. Unless clearly and explicitly stated by ThriveCoin in
        respect to any discrete Request, ThriveCoin does not participate in developing any aspect of any Request, nor in
        administering such Requests, such as in delivering Rewards to Contributors. You acknowledge and agree that it is
        solely your responsibility to read and understand all of the details provided on a Request page before providing
        a Submission to such Request, and that Rewards are not guaranteed by ThriveCoin, any Poster, or any of their respective affiliates or representatives.

      </Text>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>4.2 Eligibility.</b> Users who meet the requirements and abide by the restrictions provided in Section 1 (Use
        of Our Services) will generally be eligible to become Posters and/or Contributors. However, some Requests may
        have additional eligibility requirements that some Users may not meet. Posters may determine the eligibility
        requirements for their respective Requests in their sole discretion.
      </Text>

      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>4.3 Submissions.</b> Unless otherwise agreed to by Posters and ThriveCoin, Posters, may evaluate and determine the legitimacy and quality of any
        Submission in their sole discretion, and may reject Submissions that they reasonably determine are invalid or
        lacking effort or quality. Submissions may be made publicly available on our Services and third-party websites.
        You acknowledge and agree that any Submissions that you provide must be in accordance with Section 3.2 (User
        Content), and that we will be granted the rights in such Submissions provided therein.
      </Text>

      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b> 4.4 Disclaimer.</b> USERS WILL NOT BE COMPENSATED FOR THEIR SUBMISSIONS UNDER ANY CIRCUMSTANCES. ALTHOUGH
        REWARDS WITH MONETARY VALUE MAY BE PROVIDED, SUCH REWARDS ARE NOT AND WILL NOT BE CONSIDERED COMPENSATION.
        SUBMISSIONS ARE PROVIDED SOLELY AT THE USER’S OPTION AND ARE NOT WORKS FOR HIRE. TO THE FULLEST EXTENT OF THE
        LAW, CONTRIBUTORS ARE NOT AND WILL NOT BE CONSIDERED EMPLOYEES OR INDEPENDENT CONTRACTORS OF EITHER THRIVECOIN
        OR ANY POSTER.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>5. NO ENDORSEMENT</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        Unless we clearly and explicitly state otherwise, we do not expressly endorse any Posters, Contributors or other
        third parties. We have no control over the motivations, finances or business practices of such individuals or
        entities (as applicable) and assume no liability with respect to any transactions between you and any such
        individual and entities. Your dealings with any such individuals and entities, and any other terms, conditions,
        representations or warranties associated with such dealings, do not involve us and are exclusively between you
        and such individuals or entities. You should make whatever investigation or other resources that you deem
        necessary or appropriate before making a Submission to or engaging with such individuals or entities, their
        Requests, or their websites and services.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>6. ERRORS, INACCURACIES AND OMISSIONS</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        Although we attempt to be as accurate as possible, occasionally there may be Content on our Services that
        contains typographical errors, inaccuracies or omissions, including with respect to Requests, Rewards, rules,
        eligibility or availability. We reserve the right, but are not obligated except as required by law, to correct
        any errors, inaccuracies or omissions, and to change or update any Content on our Services or on any related
        website at any time without prior notice. We undertake no obligation to update, amend or clarify any Content on
        our Services or on any related website, except as required by law. Our Services may contain certain historical
        Content. Historical Content, necessarily, is not current and is provided for your reference only. No specified
        update or refresh date applied on our Services or on any related website should be taken to indicate that all
        Content on our Services or on any related website has been modified or updated.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>7. OPTIONAL TOOLS</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        We may provide you with access to third-party tools over which we neither monitor nor have any control nor
        input. You acknowledge and agree that we provide access to such tools “as is” and “as available” without any
        warranties, representations or conditions of any kind and without any endorsement. We will have no liability
        whatsoever arising from or relating to your use of optional third-party tools. Any use by you of optional tools
        offered through our Services is entirely at your own risk and discretion and you should ensure that you are
        familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s). We
        may also, in the future, offer new services and/or features through our Services (including, the release of new
        tools and resources). Such new features and/or services will also be subject to these Terms.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>8. DMCA NOTICE</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        Since we respect artist and content owner rights, it is our policy to respond to alleged infringement notices
        that comply with the Digital Millennium Copyright Act of 1998(<b>“DMCA”</b>).
      </Text>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and
        is accessible via our Services, please notify ThriveCoin’s copyright agent as set forth in the DMCA. For your
        complaint to be valid under the DMCA, you must provide the following information in writing:
        <br />
        <br />
        1. An electronic or physical signature of a person authorized to act on behalf of the copyright owner;
        <br />
        <br />
        2. Identification of the copyrighted work that you claim has been infringed;
        <br />
        <br />
        3. Identification of the material that is claimed to be infringing and where it is located on our Services;
        <br />
        <br />
        4. Information reasonably sufficient to permit ThriveCoin to contact you, such as your address, telephone
        number, and e-mail address;
        <br />
        <br />
        5. A statement that you have a good faith belief that use of the material in the manner complained of is not
        authorized by the copyright owner, its agent, or law; and
        <br />
        <br />
        6. A statement, made under penalty of perjury, that the above information is accurate, and that you are the
        copyright owner or are authorized to act on behalf of the owner.
        <br />
        <br />
        The above information must be submitted to the following email: support@thrivecoin.com
      </Text>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO
        CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS’
        FEES. Please note that this procedure is exclusively for notifying ThriveCoin and its affiliates that your
        copyrighted material has been infringed. The preceding requirements are intended to comply with ThriveCoin’s
        rights and obligations under the DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may
        be advisable to contact an attorney regarding your rights and obligations under the DMCA and other applicable
        laws.
        <br />
        <br />
        In accordance with the DMCA and other applicable law, we have adopted a policy of terminating, in appropriate
        circumstances, Users who are deemed to be repeat infringers. We may also at its sole discretion limit access to
        our Services and/or terminate the User Accounts of any Users who infringe any intellectual property rights of
        others, whether or not there is any repeat infringement.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>9. THIRD-PARTY MATERIALS</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        Our Services may integrate with, or contain, third-party products, services, materials, information, or links
        thereto that are not owned or controlled by us (collectively, “<b>Third-Party Materials</b>”). Third-Party
        Materials on our Services may direct you to third-party websites that are not affiliated with us. We do not
        endorse or assume any responsibility for any such Third-Party Materials or third-party websites. If you access
        or use any Third-Party Materials or third-party websites or share your personal information on or through any
        Third-Party Materials or third-party websites, you acknowledge and agree that you do so at your own risk and
        that these Terms and our Privacy Policy do not apply to your use of, or reliance on, such Third-Party Materials
        or third-party websites. You expressly relieve us from any and all liability arising from your use of, or
        reliance on, any Third Party Materials or third-party websites. Additionally, your dealings with or
        participation in promotions of advertisers found on our Services, including payment and delivery of goods, and
        any other terms (such as warranties) are solely between you and such advertisers. You agree that we shall not be
        responsible for any loss or damage of any sort relating to your dealings with such advertisers.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>10. DISCLAIMER OF WARRANTIES</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        We do not guarantee, represent or warrant that your use of our Services will be uninterrupted, timely, secure or
        error-free. We do not warrant that the results that may be obtained from the use of our Services will be
        accurate or reliable. You agree that we may suspend our Services for indefinite periods of time or cancel our
        Services at any time without notice to you. You expressly agree that your use of, or inability to use, our
        Services is at your sole risk. Our Services are (except as expressly stated by us) provided “as is” and “as
        available” for your use, without any representation, warranties or conditions of any kind, either express or
        implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a
        particular purpose, durability, title, and non-infringement.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>11. LIMITATION OF LIABILITY</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
       TO THE FULLEST EXTENT OF THE LAW, IN NO EVENT WILL THRIVECOIN, OUR DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, INTERNS,
        SUPPLIERS, SERVICE PROVIDERS OR LICENSORS BE LIABLE FOR ANY INJURY, LOSS, CLAIM, OR ANY DIRECT, INDIRECT,
        INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING, WITHOUT LIMITATION LOST PROFITS,
        LOST REVENUE, LOST SAVINGS, LOSS OF DATA, REPLACEMENT COSTS, OR ANY SIMILAR DAMAGES, WHETHER BASED IN CONTRACT,
        TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, ARISING FROM RELATING TO OR IN CONNECTION WITH YOUR USE OF, OR YOUR INABILITY TO USE, OUR
        SERVICES, SERVICES CONTENT, OR ANY THIRD-PARTY WEBSITES, PRODUCTS OR SERVICES, EVEN IF ADVISED OF THEIR
        POSSIBILITY. OUR LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW IN STATES AND JURISDICTIONS
        THAT DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES.
        <br />
        <br />
        IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION ARISING UNDER THESE
        TERMS EXCEED ONE HUNDRED DOLLARS ($100.00).
        <br />
        <br />
        To the maximum extent permitted by law, you permanently and irrevocably waive the right to bring any claim in
        any forum unless you provide the us with written notice of the events or facts giving rise to the claim within
        one (1) year of their occurrence.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>12. INDEMNIFICATION</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        You agree to defend, indemnify and hold harmless ThriveCoin and its subsidiaries, agents, licensors, managers,
        and other affiliated companies, and its and their respective employees, contractors, agents, officers and directors, from and
        against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including
        but not limited to attorney’s fees) arising from: (i) your use of and access to our Services, including any data
        or Content transmitted or received by you; (ii) your violation of any term of these Terms, including without
        limitation your breach of any of the representations and warranties above; (iii) your violation of any
        third-party right, including without limitation any right of privacy or intellectual property rights; (iv) your
        violation of any applicable law, rule or regulation; (v) any Content that is submitted via your User Account
        including without limitation misleading, false, or inaccurate information; (vi) your willful misconduct; or
        (vii) any other party’s access and use of our Services with your unique username, password or other appropriate
        security code.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>13. TERMINATION</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        Any obligations and liabilities which you have incurred prior to the termination date will survive the
        termination of these Terms for any purpose. These Terms are effective unless and until terminated by either you
        or us. You may terminate these Terms at any time by notifying us that you no longer wish to use our Services or
        by ceasing to use our Services. If in our sole judgment you fail, or we suspect that you have failed, to comply
        with any term or provision of these Terms, we also may terminate these Terms at any time without notice.
        <br />
        <br />
        Any section of these Terms that, by its nature, should survive the termination or expiration of these Terms will
        survive, including Sections 3, 5, 9, 11, 12, 13, 14, and 15.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>14. BINDING ARBITRATION</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>14.1 Arbitration Agreement.</b> READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU AND THRIVECOIN TO
        ARBITRATE ANY DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US. This section (the “
        <b>Arbitration Agreement</b>”) applies to and governs any dispute, controversy, or claim between you and
        ThriveCoin that arises out of or relates to, directly or indirectly: (a) these Terms, including the formation,
        existence, breach, termination, enforcement, interpretation, validity, or enforceability thereof; (b) access to
        or use of our Services, including receipt of any advertising or marketing communications; (c) any transactions
        through, by, or using our Services; or (d) any other aspect of your relationship or transactions with
        ThriveCoin, directly or indirectly, as a consumer (“<b>Claim</b>” or collectively, “<b>Claims</b>”). The
        Arbitration Agreement will apply, without limitation, to all Claims that arose or were asserted before or after
        your agreement to these Terms.
        <br />
        <br />
        You can reject and opt-out of this Arbitration Agreement within 30 days of accepting these Terms by emailing us
        at support@thrivecoin.com with your first and last name and stating your intent to opt-out of the Arbitration
        Agreement. Note that opting out of this Arbitration Agreement does not affect any other part of these Terms,
        including the provisions regarding controlling law or in which courts any disputes must be brought.
        <br />
        <br />
        For any Claim, you agree to first contact us at support@thrivecoin.com and attempt to resolve the dispute with
        us informally. In the unlikely event that we have not been able to resolve a Claim after sixty (60) days, we
        each agree to resolve any Claim exclusively through binding arbitration by AAA before a single arbitrator (the “
        <b>Arbitrator</b>”), under the Expedited Procedures then in effect for AAA (the “<b>Rules</b>”), except as
        provided herein. In the event of any conflict between the Rules and this Arbitration Agreement, this Arbitration
        Agreement will control. AAA may be contacted at www.adr.org, where the Rules are also available. The arbitration
        will be conducted in the county of Los Angeles in the state of California, unless you and ThriveCoin agree otherwise. If
        you are using our Services for commercial purposes, each party will be responsible for paying any AAA filing,
        administrative and Arbitrator fees in accordance with AAA rules, and the award rendered by the Arbitrator will
        include costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses. If
        you are an individual using our Services for non-commercial purposes: (i) AAA may require you to pay a fee for
        the initiation of your case, unless you apply for and successfully obtain a fee waiver from AAA; (ii) the award
        rendered by the Arbitrator may include your costs of arbitration, your reasonable attorney’s fees, and your
        reasonable costs for expert and other witnesses; and (iii) you may sue in a small claims court of competent
        jurisdiction without first engaging in arbitration, but this does not absolve you of your commitment to engage
        in the informal dispute resolution process. Any judgment on the award rendered by the Arbitrator may be entered
        in any court of competent jurisdiction. You and ThriveCoin agree that the Arbitrator, and not any local, state, provincial, national or federal, or international court or agency, will have exclusive authority to resolve any disputes relating to the
        interpretation, applicability, enforceability or formation of this Arbitration Agreement, including any claim
        that all or any part of this Arbitration Agreement is void or voidable. The Arbitrator will also be responsible
        for determining all threshold arbitrability issues, including issues relating to whether any provision of these
        Terms is unconscionable or illusory and any defense to arbitration, including waiver, delay, laches,
        unconscionability, or estoppel.
        <br />
        <br />
        Nothing in this Section will be deemed as: preventing ThriveCoin from seeking injunctive or other equitable
        relief from the courts as necessary to prevent the actual or threatened infringement, misappropriation, or
        violation of Services Content, data security, or other proprietary rights; or preventing you from asserting
        Claims in small claims court, if your Claims qualify and so long as the matter remains in such court and
        advances on only an individual (non-class, non-representative) basis.
        <br />
        <br />
        If this Arbitration Agreement is found to be void, unenforceable, or unlawful, in whole or in part, the void,
        unenforceable, or unlawful provision, in whole or in part, will be severed. Severance of the void,
        unenforceable, or unlawful provision, in whole or in part, will have no impact on the remaining provisions of
        the Arbitration Agreement, which will remain in force, or the parties’ ability to compel arbitration of any
        remaining claims on an individual basis pursuant to the Arbitration Agreement. Notwithstanding the foregoing, if
        the Class Action/Jury Trial Waiver is found to be void, unenforceable, or unlawful, in whole or in part, because
        it would prevent you from seeking public injunctive relief, then any dispute regarding the entitlement to such
        relief (and only that relief) must be severed from arbitration and may be litigated in a civil court of
        competent jurisdiction. All other Claims for relief subject to arbitration under this Arbitration Agreement will
        be arbitrated under its terms, and the parties agree that litigation of any Claim regarding the entitlement to
        public injunctive relief will be stayed pending the outcome of any individual Claims in arbitration.
        <br />
        <br />
        <b>14.2 Class Action/Jury Trial Waiver.</b> WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY
        HAVE OBTAINED OR USED OUR SERVICES FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE
        CLAIMANTS’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE
        ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS
        ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS.
        YOU AND THRIVECOIN AGREE THAT THE ARBITRATOR MAY AWARD RELIEF ONLY TO AN INDIVIDUAL CLAIMANT AND ONLY TO THE
        EXTENT NECESSARY TO PROVIDE RELIEF ON THEIR INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED MAY NOT AFFECT OTHER
        THRIVECOIN USERS. YOU AND THRIVECOIN FURTHER AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND THRIVECOIN ARE
        EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO BRING, JOIN, OR PARTICIPATE IN A CLASS ACTION, COLLECTIVE
        ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND AS A PLAINTIFF OR CLASS
        MEMBER.
      </Text>
    </WrapperBox>

    <WrapperBox>
      <SubHeader>15. GENERAL</SubHeader>
      <Text $size="16px" $color="01Primary700" $lineHeight="26px">
        <b>15.1 Severability.</b> In the event that any provision of these Terms is determined to be unlawful, void or
        unenforceable, such provision will nonetheless be enforceable to the fullest extent permitted by applicable law,
        and the unenforceable portion will be deemed to be severed from these Terms, such determination will not affect
        the validity and enforceability of any other remaining provisions.
        <br />
        <br />
        <b>15.2 Governing Law.</b> You agree that: (i) we will be deemed solely based in the State of Colorado; and (ii)
        our Services will be deemed passive and will not give rise to personal jurisdiction over us, either specific or
        general, in jurisdictions other than Colorado. These Terms will be governed by the internal substantive laws of
        Colorado, without respect to its conflict of laws principles and expressly excluding the United Nations
        Convention on Contracts for the International Sale of Goods. The parties acknowledge that these Terms evidence a
        transaction involving interstate commerce. You and we agree that any Claim (as defined below) that is not
        subject to arbitration or cannot be heard in small claims court, will be resolved in the state or federal courts
        of Colorado, which is the proper and exclusive forum for any such proceedings.
        <br />
        <br />
        <b>15.3 Changes To These Terms.</b> Any new features or tools which are added to the current store will also be
        subject to these Terms. You can review the most current version of the Terms at any time on this page. We
        reserve the right, at our sole discretion, to update, change or replace any part of these Terms by posting
        updates and changes to our Services. It is your responsibility to check our Services periodically for changes.
        Your continued use of or access to our Services following the posting of any changes to these Terms constitutes
        acceptance of those changes.
        <br />
        <br />
        <b>15.4 Construction And Interpretation.</b> For purposes of these Terms, (a) the words “include,” “includes,”
        and “including” are deemed to be followed by the words “without limitation”; (b) the word “or” is not exclusive;
        and (c) words denoting the singular have a comparable meaning when used in the plural, and vice-versa. You
        acknowledge and agree that any ambiguities in the interpretation of these Terms will not be construed against
        us.
        <br />
        <br />
        <b>15.5 No Waiver.</b> Our delay or failure to exercise or enforce any right or provision of these Terms will
        not constitute a waiver of such right or provision.
        <br />
        <br />
        <b>15.6 Contact Information.</b> Questions about these Terms should be sent to us at support@thrivecoin.com.
        <br />
        <br />
        <b>15.7 Entire Agreement.</b> These Terms and any policies or operating rules posted by us on our Services, or
        in respect to our Services, constitutes the entire agreement and understanding between you and us and govern
        your use of our Services, superseding any prior or contemporaneous agreements, communications and proposals,
        whether oral or written, between you and us (including any prior versions of these Terms).
      </Text>
    </WrapperBox>
  </Wrapper>
);

export default TermsServices;
