import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const CREATE_TEXT = {
  dontSave: "Discard all changes and leave",
  doSave: "Save as draft and leave",
};

const UPDATE_TEXT = {
  dontSave: "Don’t save changes",
  doSave: "Save Changes",
};

const BeforeYouLeaveModal = ({ hideModal, filledRequired, onSubmit }) => {
  const { id } = useParams();
  const isPersisted = id;
  const text = !isPersisted ? CREATE_TEXT : UPDATE_TEXT;

  const onDiscard = () => {
    hideModal();
    hideModal();
  };

  const doSubmit = (e) => onSubmit(e).then(hideModal);

  return (
    <Modal open onClose={hideModal} leaf={true} $maxWidth="684px">
      <Wrapper>
        <Title
          $bold
          $size="28px"
          $lHeight="36px"
          $color="02Primary500"
          $typography="heading/medium"
          $spacing={!isPersisted ? "mb-4" : "mb-8"}
        >
          Do you want to save your progress before leaving?
        </Title>
        <Text>You must complete all required fields in order to save as draft.</Text>
      </Wrapper>
      <ButtonsWrapper $spacing="mt-6" $flex $direction="column" filledRequired={filledRequired}>
        <StyledButton $grow="1" $color="02Primary700" kind="outline" $borderColor="02PrimaryGrey300" onClick={hideModal} modal={true}>
          Nevermind, I want to stay
        </StyledButton>

        {filledRequired && (
          <StyledButton
            kind="outline"
            $color="02Primary700"
            $borderColor="03Secondary500"
            $bgColor="primary700"
            onClick={doSubmit}
            modal={true}
          >
            {text.doSave}
          </StyledButton>
        )}

        <StyledButton $grow="1" $color="02Primary700" kind="outline" $borderColor="error" onClick={onDiscard} modal={true}>
          {text.dontSave}
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

export const BeforeYouLeaveCommunityModal = BeforeYouLeaveModal;

export const BeforeYouLeaveSeasonModal = BeforeYouLeaveModal;

const Wrapper = styled(Box)`
  text-align: center;
  margin: 0 47px;

  @media ${breakpoints.mobile} {
    margin: 0;
  }
`;

const Title = styled(Text)`
  font-family: Outfit;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  font-weight: 600;
  border-radius: 40px;

  &:hover {
    color: ${({ theme }) => theme.colors["02Primary500"]};
  }
`;

const ButtonsWrapper = styled(Box)`
  gap: 1.5rem;
  @media ${breakpoints.mobile} {
    margin: 0;
  }
`;

export default BeforeYouLeaveModal;
