import { Box, Button, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import { IdentifierSelect, InputGroup } from "../common";
import { ListingEditorContext } from "../ListingEditorContext";

const SHEET_ELEMENT_CLASS = "sheet-form";
const PROCESSOR_TYPES = {
  twitter_app: "twitter",
  discord_app: "discord",
  activity_app: "address",
  bankless_discourse_app: "bankless_discourse",
  // particle_discourse_app: "particle_discourse",
  apecoin_discourse_app: "apecoin_discourse",
  aavegotchi_discourse_app: "aavegotchi_discourse",
  email_app: "email",
};

const SpreadsheetStep = () => {
  const { listing, addImportJob, removeImportJob } = useContext(ListingEditorContext);
  const { import_jobs, contribution_app, id } = listing;
  const isCreation = !id;
  const processor_type = PROCESSOR_TYPES[contribution_app];

  return (
    <div>
      {import_jobs.map((sheet, index) => (
        <SheetPanel
          key={sheet.id || sheet.key}
          index={index}
          removeSheet={!isCreation && removeImportJob}
          {...sheet}
          processor_type={processor_type}
        />
      ))}
      {!isCreation && (
        <Button
          $size="lg"
          $borderRadius="50px"
          $width="100%"
          $spacing="mb-6"
          kind="outline"
          $color="01Primary700"
          variant="03Primary400"
          onClick={addImportJob}
        >
          Add Spreadsheet(s){"  +"}
        </Button>
      )}
      <Box $height="1px" $spacing="my-6" $bgColor="01Primary200" />
      <InputGroup
        defaultValue={contribution_app}
        field="identifier_type"
        label="Identifier Type"
        InputClass={IdentifierSelect}
        disabled
        required
      />
      <CenteredText $spacing="mt-2" $typography="Body/Medium" $color="01Primary700" $justifyItems="center">
        <IconSvg icon="circle-info" $color="03Primary500" $width="16px" $spacing="mr-2" />
        The selected Contribution App determines the “Identifier Type”.
      </CenteredText>
    </div>
  );
};

const SheetPanel = styled(({ className, index, removeSheet, id, processor_type, sheet_name, sheet_id }) => (
  <Box className={className}>
    <form className={SHEET_ELEMENT_CLASS}>
      {removeSheet && (
        <span data-index={index} onClick={removeSheet}>
          X
        </span>
      )}
      {id && <input type="text" name="invalidate" required hidden />}
      <input type="text" name="processor_type" value={processor_type} required hidden />
      <Box $spacing="mb-5">
        <Text $bold $spacing="mb-3">
          Results Sheet Name (Required)
        </Text>
        <Input name="sheet_name" data-importjobindex={index} value={sheet_name} disabled={id} required />
      </Box>
      <Box>
        <Text $bold $spacing="mb-3">
          Results Spreadsheet (Required)
        </Text>
        <Input name="sheet_id" data-importjobindex={index} value={sheet_id} disabled={id} required />
      </Box>
    </form>
  </Box>
))`
  margin-bottom: 24px;
  position: relative;
  color: ${({ theme }) => theme.colors["01Primary700"]};

  span {
    position: absolute;
    right: 8px;
    top: 6px;
    cursor: pointer;
  }
`;

const CenteredText = styled(Text)`
  display: flex;
  align-items: center;
`;

SpreadsheetStep.spreadsheet_class = SHEET_ELEMENT_CLASS;

export default SpreadsheetStep;
