import Divider from "@common/Divider";
import { breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";

const StepNavigationBig = styled(({ className, index, steps, setStep }) => {
  const handleStep = (event) => {
    const step = parseInt(event.target.dataset.step);
    setStep(step);
  };

  return (
    <>
      <div className={className}>
        {steps.map(({ label, num }) => {
          return (
            <label key={label} data-selected={index == num} data-step={num} data-past={index >= num && index != num} onClick={handleStep}>
              {label}
            </label>
          );
        })}
      </div>
      <Divider />
    </>
  );
})`
  display: flex;
  margin: 0 auto;
  max-width: 737px;
  justify-content: center;

  label {
    color: ${({ theme }) => theme.colors["01Primary700"]};
    background-color: ${({ theme }) => theme.colors["01Primary100"]};
    border-radius: 8px;
    padding: 10px 50px;
    position: relative;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: ${({ $width }) => $width};

    &:hover {
      background-color: ${({ theme }) => theme.colors["01Primary100"]};
    }

    @media ${breakpoints.mobile} {
      padding: 10px 30px;
    }
  }


  & label:not(:first-child):before {
    content: "";
    position: absolute;
    height: 0px;
    width: 0px;
    border-radius: 50%;
    top: 3px;
    left: -18px;
    border-width: 18px;
    border-color: transparent transparent ${({ theme }) => theme.colors["01Primary0"]}
      ${({ theme }) => theme.colors["01Primary0"]};
    border-radius: 0 0 8px 8px;
    transform: rotate(225deg);
    border-style: solid;
  }

  & label:not(:last-child):after {
    content: "";
    position: absolute;
    height: 0px;
    width: 0px;
    border-radius: 50%;
    top: 4px;
    right: -11px;
    border-width: 17px;
    border-color: transparent transparent ${({ theme }) => theme.colors["01Primary100"]}
      ${({ theme }) => theme.colors["01Primary100"]};
    border-radius: 4px 0 8px 8px;
    transform: rotate(225deg);
    border-style: solid;
    z-index: 2;
  }

  label:not(:last-child):hover:after {
    border-color: transparent transparent ${({ theme }) => theme.colors["01Primary100"]}
      ${({ theme }) => theme.colors["01Primary100"]};
  }
  label[data-selected="true"] {
    color:${({ theme }) => theme.colors["01Primary0"]};
    background-color: ${({ theme }) => theme.colors["01Secondary500"]};
    font-weight: 600;
    &:after {
      border-color: transparent transparent ${({ theme }) => theme.colors["01Secondary500"]}
        ${({ theme }) => theme.colors["01Secondary500"]};
    }
    &:hover:after {
      border-color: transparent transparent ${({ theme }) => theme.colors["01Secondary500"]}
        ${({ theme }) => theme.colors["01Secondary500"]};
    }
  }
   label[data-past="true"] {
    color:${({ theme }) => theme.colors["01Primary0"]};
    background-color: ${({ theme }) => theme.colors["01Primary55"]};
    font-weight: 600;
    &:after {
      border-color: transparent transparent ${({ theme }) => theme.colors["01Primary55"]}
        ${({ theme }) => theme.colors["01Primary55"]};
    }
    &:hover:after {
      border-color: transparent transparent ${({ theme }) => theme.colors["01Primary55"]}
        ${({ theme }) => theme.colors["01Primary55"]};
    }
  }
`;

export default StepNavigationBig;
