import { Box, breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";

const TabWrapper = (props) => (
  <Box $flex fled-direction="column" $justifyContent="center">
    <TabContent {...props} />
  </Box>
);

const TabContent = styled.div`
  padding: 32px;
  max-width: 1302px;
  width: 100%;

  @media ${breakpoints.tablet} {
    padding: 32px 24px;
  }

  @media ${breakpoints.mobile} {
    padding: 24px 16px;
  }
`;

export default TabWrapper;
