import TabWrapper from "@common/TabWrapper";

import DebouncedSearchInput from "@common/DebouncedSearchInput";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import pluralize from "../../utils/pluralize";
import TableTab from "../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "../hooks/useSort";
import TableBadges from "../ManageCommunities/BadgesTab/TableBadges";

const CommunityBadgesTab = () => {
  const { id } = useParams();
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { getCommunityBadges } = useStoreActions((actions) => actions.badges);
  const { communityBadges } = useStoreState((state) => state.badges);
  const { getActiveCommunities } = useStoreActions((actions) => actions.community);
  const _getCommunityBadges = (params = {}) => getCommunityBadges({ ...params, community_id: id });
  const sortProps = useSort(_getCommunityBadges);
  const title = communityBadges && `There are a total of  ${communityBadges?.length} badges`;
  const dataResults = communityBadges?.length;
  const resultsFound = `There are ${dataResults} ${pluralize("badge", dataResults)} based on your search`;
  const headingText = sortProps.searchValue ? resultsFound : title;

  const createBadge = () => showModal({ modalName: "AddBadgeModal", callback: _getCommunityBadges });
  const rowProps = {
    _getBadges: _getCommunityBadges,
  };
  const onLoadMore = (page) => {
    return Promise.resolve(
      sortProps
        .sortMethod({
          page,
        })
        .finally(() => sortProps.setLoading(false))
    );
  };

  useEffect(() => {
    getCommunityBadges({ community_id: id });

    getActiveCommunities();
  }, []);

  return (
    <TableTab
      TableComponent={TableBadges}
      data={communityBadges}
      sortProps={{ ...sortProps, onLoadMore }}
      _showForDesktop={true}
      WrapperKlass={TabWrapper}
      hideTitleBox={true}
      rowProps={rowProps}
    >
      <Box $flex $justifyContent="space-between" $alignItems="center">
        <Text $typography="heading/small" $color="01Primary700" $bold>
          {headingText}
        </Text>
        <Box $flex $alignItems="center" $gap="16px">
          <Button
            kind="solid"
            variant="green"
            leftIcon="plus"
            $borderRadius="40px"
            $heightSize="44px"
            onClick={createBadge}
          >
            Add New Badge
          </Button>
          <Box $spacing="ml-a">
            <DebouncedSearchInput onChange={sortProps.onChange} />
          </Box>
        </Box>
      </Box>
    </TableTab>
  );
};

export default CommunityBadgesTab;
