import { Button, IconSvg } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components";

const SharePageButton = (props) => {
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { referral_code } = useStoreState((state) => state.user.user);
  const search = referral_code ? `referral_code=${referral_code}` : "";
  const link = `${window.location.href}?${search}`;
  const onClick = () => showModal({ modalName: "ShareSocialMediaModal", link });

  return (
    <Button
      $bgColor="white"
      $borderRadius="50px"
      $color="02Primary700"
      $borderColor="02Primary0"
      $hoverBackground="02PrimaryGrey100"
      $hoverBorderColor="02PrimaryGrey100"
      $hoverColor="02Primary500"
      onClick={onClick}
      {...props}
    >
      <span>Share</span>
      <IconSvg icon="share" $width="13px" />
    </Button>
  );
};

const StyledSharePageButton = styled(SharePageButton)`
  cursor: pointer;
  z-index: 1;
  color: #33343d;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  padding: 0;

  span{
    visibility: hidden;
  }

  svg {
    margin-left: 14px;
  }

  ${Button} {
    width: fit-content;
    height: fit-content;
  }

  &:hover {
    padding: 11px 16px;
    border-radius: 40px;
    width: unset;
    span {
      color: black;
      max-width: 100px;
      margin-right: 8px;
      margin-left: 0;
      visibility: visible;
    }
  }

  span {
    color: white;
    max-width: 0px;
    margin-right: 0px;
    transition: color 0.75s 0.25s, max-width 0.75s, margin-right 0.75s;
    margin-left: -19px;
  }
`;

export default StyledSharePageButton;
