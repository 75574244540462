/* eslint-disable max-len */
import LoadMore from "@common/LoadMore";
import { MainContainer } from "@common/MainContainer";
import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, ListingCompactModular, ListingFilters, Loader } from "@thrivecoin/ui-components";
import pluralize from "@utils/pluralize";
import { removeUndefined } from "@utils/removeUndefined";
import { textLinkify } from "@utils/text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EmptyContent, { SearchEmpty } from "../../../EmptyContent";
import { LISTING_SORT_OPTIONS } from "../../../SortOptions";
import { categories, ICONS, NETWORKICONS, networks } from "./common";
import FilterTitle from "./FilterTitle";
import THeader from "./THeader";
import useFavoriteListings from "./useFavoriteListings";
import useTagFilters from "./useTagFilters.js";
import useWaysToContribute from "./useWaysToContribute";

const getBackLabel = (group) => {
  switch (group) {
    case "season":
      return "Back to Season Page";
    case "dashboard":
      return "Back to Dashboard";
    default:
      return "Back to Community Page";
  }
};

const categoriesIcons = categories(ICONS, "icon");
const networkIcons = networks(NETWORKICONS, "url");

const DASHBOARD_OPTIONS = [
  { options: networkIcons, label: "Network", value: "title", isMultiple: true },
  { options: categoriesIcons, label: "Community", value: "title", isMultiple: true },
];

const SORT_DIRECTIONS = ["asc", "desc"];
const reference = { pagination: {} };
const setPagination = (pagination) => (reference.pagination = pagination);
const getTotal = () => reference.pagination.total;

const {
  ContributionTD,
  RewardTD,
  CommunityTD,
  CompletionsTD,
  CanBeCompletedTD,
  CompletionCheckTD,
  ConnectOrDetailsTD,
} = ListingCompactModular;

const FIELDS_WITH_COMMUNITY = [
  ContributionTD,
  RewardTD,
  CommunityTD,
  CanBeCompletedTD,
  CompletionCheckTD,
  ConnectOrDetailsTD,
];

const DEFAULT_CELLS = [
  ContributionTD,
  RewardTD,
  CompletionsTD,
  CanBeCompletedTD,
  CompletionCheckTD,
  ConnectOrDetailsTD,
];

const CONFIG = {
  season: {
    storeKey: "season",
    dataKey: "seasonListings",
    paginationKey: "seasonListingsPagination",
  },
  dashboard: {
    storeKey: "listings",
    dataKey: "allListings",
    paginationKey: "allListingsPagination",
  },
  community: {
    storeKey: "listings",
    dataKey: "listings",
    paginationKey: "listingsPagination",
  },
};

const filterOut = (KlassToRemove) => (Klass) => Klass !== KlassToRemove;

const WaysToContribute = ({ id, group, getListings }) => {
  const navigate = useNavigate();
  const isCommunity = group === "community";
  const isDashboard = group === "dashboard";
  const isSeason = group === "season";
  const { community } = useStoreState((state) => state.community);
  const { confirmed } = useStoreState((state) => state.user.user);
  const { isMobile, isDesktop, isTablet, isMiniTablet } = useStoreState((state) => state.media);
  const { season } = useStoreState((state) => state.season);
  const { getSnapshotDetails, getActivityDetails } = useStoreActions((actions) => actions.listings);
  const [loading, setLoading] = useState(true);
  const [selectedCommunity, setSelectedCommunity] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const [sortingType, setSortingType] = useState({});

  const { onSignUp, networkConnections, readMore, connectSocialMedias, manuallyComplete, connectMultiNetworks } =
    useWaysToContribute();
  const { storeKey, dataKey, paginationKey } = CONFIG[group];
  const store = useStoreState((state) => state[storeKey]);
  const _listings = store[dataKey];
  const _pagination = store[paginationKey];
  const accentColor =
    (community.theme === "custom" && community.accent_color) ||
    (season.community_theme === "custom" && season.community_accent_color);
  const toggleFavorite = useFavoriteListings();
  const { successToast } = useStoreActions((actions) => actions.toasts);
  const communityID = community.id || season.community_id;

  const filterByTags = (community_tag_ids, keyword, stateOption, sortDirection, sortType, page = 1) => {
    let params = removeUndefined({
      id,
      keyword: keyword,
      sort_type: sortType,
      sort_direction: sortDirection,
      community_tag_ids,
      page,
    });

    if (stateOption) {
      params[stateOption] = true;
    }

    setLoading(true);
    getListings(params).finally(() => setLoading(false));
  };

  const {
    text,
    paginatedSearch,
    sortDirection,
    sortType,
    sortValue,
    setSortValue,
    setSortType,
    setSortDirection,
    setText,
    ...filterProps
  } = useTagFilters(communityID, filterByTags);

  const sortIcon = sortDirection === "asc" ? "up-long" : "down-long";
  const canFilter = _listings.length > 0 || text;

  setPagination(_pagination);
  const headingText = text
    ? `${_pagination.total} ${pluralize("result", _pagination.total)} found for "${text}"`
    : `There are ${_pagination.total} ${pluralize("way", _pagination.total)} to contribute to this ${group}!`;

  const goToDashboard = () => navigate("/");

  const _setSelectedValues = () => {
    let _selectedCommunity = [];
    let _selectedNetwork = [];
    sortValue?.options.forEach((item) => {
      const getCommunity = categories(ICONS, "icon").find((i) => i.value === item.value);
      const getNetworks = networks(ICONS, "icon").find((i) => i.value === item.value);
      if (getCommunity) {
        _selectedCommunity = [..._selectedCommunity, item];
      }
      if (getNetworks) {
        _selectedNetwork = [..._selectedNetwork, item];
      }
    });
    setSelectedCommunity(_selectedCommunity);
    setSelectedNetwork(_selectedNetwork);
  };

  const setSearch = (keyword) => setText(keyword);

  const onSort = (event) => {
    const type = event.target?.dataset.name;
    if (type && !loading) {
      let sort_direction = "desc";
      if (sortType === type) {
        sort_direction = SORT_DIRECTIONS.find((d) => d !== sortDirection);
      } else {
        setSortType(type);
        if (type === "contribution") sort_direction = "asc";
      }
      setLoading(true);
      setSortDirection(sort_direction);
    }
  };

  const onFilterApply = (search, sort) => {
    const { value, direction } = sort;
    setText(search);
    setSortValue(sort);
    setSortType(value);
    setSortDirection(direction);
  };

  const onShare = (listingId) => {
    navigator.clipboard.writeText(`${window.location.origin}/listings/${listingId}`);
    successToast({ content: "Link was copied!", dismissTime: 3000 });
  };

  const fieldClasses2 = useMemo(() => {
    let classes = isDashboard ? FIELDS_WITH_COMMUNITY : DEFAULT_CELLS;
    return classes;
  }, [isDashboard]);

  const onSearch = () => {
    showModal({
      modalName: "FilterModal",
      searchValue: text,
      sortValue,
      onSubmit: onFilterApply,
      sortOptions: !isDashboard ? LISTING_SORT_OPTIONS : DASHBOARD_OPTIONS,
      backLabel: getBackLabel(group),
      filterLabel: "ways to contribute",
      hideSort: isDashboard,
      getTotal,
      setSortingType,
      sortingType,
    });
  };

  useEffect(() => {
    if (isMobile && isDashboard) {
      _setSelectedValues();
    }
  }, [sortValue]);

  let content;
  let loadingContent;
  if (loading) {
    loadingContent = (
      <tr>
        <td colSpan="7">
          <Box $width="100%" $spacing="m-a p-9+6" $flex $alignItems="center" $justifyContent="center">
            <Loader />
          </Box>
        </td>
      </tr>
    );
  }
  if (_listings.length === 0) {
    content = text ? (
      <NoSearchResults />
    ) : (
      !loading && (
        <tr>
          {" "}
          <td colSpan="7">
            <EmptyContent
              title="No active contributions right now..."
              text="Don’t be shy —— start contributing to communities you love!"
              btnText="Find Contribution Opportunities "
              onClick={goToDashboard}
              imageWidth="326.57px"
              marginTopImage={isMobile ? "106px" : "128px"}
              hideBtnIcon
              $btnBgColor={accentColor}
            />
          </td>
        </tr>
      )
    );
  } else {
    content = (
      <>
        {_listings.map((listing) => {
          const _selectedCommunity =
            selectedCommunity.filter((item) => item.label === listing.community_name).length > 0 ? true : undefined;
          const _selectedNetwork =
            selectedNetwork.filter((item) => item.value === listing.contribution_app).length > 0 ? true : undefined;
          const selectedOptions = isDashboard ? _selectedCommunity || _selectedNetwork : undefined;

          return (
            <ListingCompactModular
              $spacing="mb-5"
              key={listing.id}
              {...listing}
              reward={parseFloat(listing.reward_amount)}
              earned={parseFloat(listing.rewards_earned)}
              completedCount={listing.contributions_count || 0}
              canCompleteMultipleTimes={listing.unlimited_contributions_per_user}
              custom_url={listing.custom_url}
              type={listing.contribution_app}
              manuallyVerification={listing.verification_method === "manual"}
              description={textLinkify(listing.description)}
              isAuthenticated={confirmed}
              onSignUp={onSignUp}
              connectSocialMedias={connectSocialMedias}
              getSnapshotDetails={getSnapshotDetails}
              getActivityDetails={getActivityDetails}
              toggleFavorite={toggleFavorite}
              manuallyComplete={manuallyComplete}
              readMore={readMore}
              combinedCompleted={isDashboard}
              selectedList={selectedOptions}
              communityName={listing.community_name}
              communityLink={`/communities/${listing.community_slug}`}
              fieldClasses={[...fieldClasses2]}
              accentColor={accentColor}
              onShare={onShare}
              connectMultiNetworks={connectMultiNetworks}
              {...networkConnections(listing.contribution_app, listing.networks)}
            />
          );
        })}
      </>
    );
  }

  return (
    <TabWrapper>
      <MainContainer>
        <Container>
          {canFilter && (
            <FilterTitle
              id={id}
              headingText={headingText}
              isMobile={isMobile}
              searchValue={text}
              sortValue={sortValue}
              isCommunity={isCommunity}
              isDashboard={isDashboard}
              group={group}
              selectedNetwork={selectedNetwork}
              selectedCommunity={selectedCommunity}
              /////////////
              onFilterApply={onFilterApply}
              setSearch={setSearch}
              getTotal={getTotal}
            />
          )}
          <Box $spacing="mb-4">
            <ListingFilters {...filterProps} onFilter={onSearch} />
          </Box>
          <Table>
            {canFilter && (
              <THeader
                onSort={onSort}
                isMobile={isMobile}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isMiniTablet={isMiniTablet}
                sortType={sortType}
                sortIcon={sortIcon}
                isCommunity={isCommunity}
                confirmed={confirmed}
                isDashboard={isDashboard}
                isSeason={isSeason}
                isCustomToken={season.reward_system === "custom_token"}
              />
            )}
            <tbody>
              {content}
              {loadingContent}
            </tbody>
          </Table>
          {!loading && <LoadMore pagination={_pagination} loadMore={paginatedSearch} extraText="contributions" />}
        </Container>
      </MainContainer>
    </TabWrapper>
  );
};

const NoSearchResults = () => (
  <tr>
    <td colSpan="7">
      <Box $height="1px" $bgColor="01Primary300" />
      <SearchEmpty />
    </td>
  </tr>
);

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  padding-bottom: 170px;
  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.tablet} {
    max-width: calc(100vw - 64px);
    & > div {
      max-width: calc(100vw - 64px);
    }
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 0 24px;
  }
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  z-index: 9;
  tbody:before {
    content: ".";
    display: block;
    line-height: 12.2px;
    text-indent: -99999px;
  }
`;

export default WaysToContribute;
