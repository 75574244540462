import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useNavigate } from "react-router";
import styled from "styled-components";

export const GoBackHome = () => {
  const navigate = useNavigate();
  const onClick = () => navigate(`/communities`);
  return (
    <Box $flex $alignItems="center" $pointer $spacing="mb-4+1 " onClick={onClick}>
      <IconSvg icon="arrow-left" />
      <Text $spacing="ml-3" $bold>
        Back to main page
      </Text>
    </Box>
  );
};

export const Wrapper = styled.div`
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  background: ${({ theme }) => theme.colors["01Primary0"]};
  border-radius: 12px;
  margin: 40px 20px;
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  ${({ $alignSelf }) => $alignSelf && `align-self: ${$alignSelf};`}

  a {
    color: ${({ theme }) => theme.colors["link"]};
  }
`;

export const HeaderText = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 26px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors["01Primary700"]};
  margin-bottom: 40px;

  @media ${breakpoints.tablet} {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const SubHeader = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors["01Primary700"]};
  margin-bottom: 24px;
`;

export const WrapperBox = styled(Box)`
  margin-bottom: 40px;
`;
