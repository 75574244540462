const usePanelOptions = (isPublicProfile, isThriveAdmin, profileData) => {
  const panelOptions = [
    { label: "Edit", action: "edit", condition: !isPublicProfile },
    { label: "Add/Remove Badge", action: "manageBadges", condition: isThriveAdmin },
    { label: "Invite Friends", action: "invite", condition: !isPublicProfile },
    { label: "UnBan", action: "unban", condition: isThriveAdmin && isPublicProfile && profileData.banned },
    { label: "UnFlag", action: "unflag", condition: isThriveAdmin && isPublicProfile && profileData.flagged },
    {
      label: "Unrestrict",
      action: "unrestrict",
      condition: isThriveAdmin && isPublicProfile && profileData.restricted,
    },
    { label: "Ban", action: "ban", condition: isThriveAdmin && isPublicProfile && !profileData.banned },
    { label: "Flag", action: "flag", condition: isThriveAdmin && isPublicProfile && !profileData.flagged },
    {
      label: "Restrict",
      action: "restrict",
      condition: isThriveAdmin && isPublicProfile && !profileData.restricted,
    },
    { label: "Share", action: "share", condition: isPublicProfile },
  ];

  return {
    panelOptions,
  };
};

export default usePanelOptions;
