import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

const STATUS_CONFIG = {
  pending: {
    isDesktop: {
      margin: "46px 0 24px 0",
    },
    isTablet: {
      margin: "32px 0 24px 0",
    },
    isMobile: {
      margin: "24px 0 24px 0",
    },
  },
  live: {
    isDesktop: {
      margin: "78px 0 24px 0",
    },
    isTablet: {
      margin: "44px 0 24px 0",
    },
    isMobile: {
      margin: "24px 0 24px 0",
    },
  },
  completed: {
    isDesktop: {
      margin: "106px 0 24px 0",
    },
    isTablet: {
      margin: "65px 0 24px 0",
    },
    isMobile: {
      margin: "24px 0 16px 0",
    },
  },
};

const Title = ({ raffle }) => {
  const { isMobile, isDesktop, isTablet } = useStoreState((state) => state.media);
  const isLive = raffle.status === "live";
  const isCompleted = raffle.status === "completed";

  const device = isDesktop ? "isDesktop" : isTablet ? "isTablet" : "isMobile";

  const config = raffle.status && STATUS_CONFIG[raffle.status][device];

  return (
    <TitleWrapper {...config} $flex>
      {isLive && <LiveText>Live</LiveText>}
      {isCompleted && !isMobile && <CheckIcon $spacing="mr-2" icon="success-check" />}
      <TextTitle
        $textAlign={isCompleted && isMobile && "center"}
        $typography="heading/x-large"
        $bold
        $color="02Primary500"
      >
        {getTitle(raffle.status, raffle.community_name, raffle.season_number)}
      </TextTitle>
    </TitleWrapper>
  );
};

export const getTitle = (status, community_name, season_number) => {
  return {
    pending: `${community_name} - Season ${season_number} - Raffle`,
    live: `The Raffle for ${community_name} Season ${season_number} has started`,
    completed: `The Raffle for ${community_name} Season ${season_number} has finished!`,
  }[status];
};

const LiveText = styled(Text)`
  background: #5fb974;
  border-radius: 4px;
  font-weight: 800;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors["02Primary0"]};
  width: 49px;
  height: 25px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckIcon = styled(IconSvg)`
  circle {
    fill: #5fb974;
  }
`;

const TitleWrapper = styled(Box)`
  margin: ${({ margin }) => margin && margin};
  flex-direction: row;
  align-items: baseline;
  gap: 24px;

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

const TextTitle = styled(Text)`
  max-width: 965px;
  @media ${breakpoints.tablet} {
    max-width: 760px;
  }
`;

export default Title;
