import { Box, Text } from "@thrivecoin/ui-components";
import { useEffect } from "react";
import styled from "styled-components";

const SelectView = ({ hideOptions, closed, options, sortValue, setSortValue }) => {
  const onItemClick = (event) => {
    const { value } = event.currentTarget.dataset;
    setSortValue(value);
  };

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <Wrapper $flex $direction="column" $justifyContent="flex-end" onClick={hideOptions}>
      <Content onClick={(e) => e.stopPropagation()} className={closed && "closing-content"}>
        <TopPart $relative $color="02Primary700">
          <Box $flex $direction="column" $gap="11px" $justifyContent="center" $alignItems="center">
            <Line onClick={hideOptions} />
            <Text $color="02Primary500" $typography="body/medium">
              Contributors
            </Text>
          </Box>
        </TopPart>

        <ItemsContainer $flex $direction="column" $sm-spacing="mx-4">
          {options.map((item, i) => {
            const label = item.label === "All Contributors" ? "All" : item.label;
            const selectedOpt = item.value === sortValue;

            return (
              <Item
                $pointer
                key={i}
                data-value={item.value}
                onClick={onItemClick}
                selected={selectedOpt}
                $spacing="p-4 mt-4"
                $borderRadius="12px"
                $flex
                $alignItems="center"
                $gap="12px"
              >
                <Text $color="02Primary700" $extraBold={selectedOpt}>
                  {label}
                </Text>
              </Item>
            );
          })}
        </ItemsContainer>
        <Box $height="1px" $bgColor="02PrimaryGrey150" $spacing="my-5" />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  inset: 0;
  background-color: rgba(51, 52, 61, 0.5);
  animation: animateOverlayIn 0.5s forwards;

  @keyframes animateOverlayIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  max-height: 80%;
  padding: 0 0 1.5rem;
  background-color: white;
  border-radius: 24px 24px 0 0;
  animation: animateIn 0.5s forwards;
  overflow-y: auto;

  &.closing-content {
    animation: animateOut 0.5s forwards;
  }

  @keyframes animateIn {
    0% {
      transform: translate(0, 100%);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes animateOut {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 100%);
    }
  }
`;

const TopPart = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 8px 0 23px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey150"]};
`;

const Item = styled(Box)`
  ${({ selected, theme }) =>
    selected
      ? `
  border: 2px solid ${theme.colors["03Primary500"]};
    background-color: ${theme.colors["02PrimaryGrey100"]};
  `
      : `
  border: 1px solid transparent;
  background-color: ${theme.colors.grey100};
  `}
`;

const ItemsContainer = styled(Box)`
  overflow-y: auto;
  flex: 1;
`;

const Line = styled(Box)`
  width: 57px;
  height: 4px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors["02PrimaryGrey150"]};
`;

export default SelectView;
