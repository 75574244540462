import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { normalize } from "@utils/socialUtils";
import styled from "styled-components";
import { ConnectionButtons, DiscourseButtons } from "../../common/NetworkConnections";
import { PaddedButton } from "../common";

export const discourseStats = ({ discourseConnections, countByApp }) => {
  const discourseKeys = Object.keys(discourseConnections);
  const nonConnectedKeys = discourseKeys.filter((key) => !discourseConnections[key]);
  const connectedCount = discourseKeys.length - nonConnectedKeys.length;
  const pendingWaysCount = nonConnectedKeys
    .map((key) => normalize(key).appName)
    .reduce((acumulator, key) => acumulator + (countByApp[key] || 0), 0);

  return { connectedCount, pendingWaysCount, networksCount: discourseKeys.length };
};

const ConnectStep = (props) => {
  return (
    <Box>
      <Text $bold $typography="Heading/Large" $textAlign="center">
        Connect more networks for more rewards
      </Text>
      <Content $color="02Primary500">
        <Text $textAlign="center" $spacing="mb-4 mx-4">
          Connect to verify your identity on other networks you use to contribute. Verified identities are never
          exposed.
        </Text>
        <ButtonsWrapper>
          <ConnectionButtons $isModal {...props} />
          <DiscourseButtons $isModal {...props} />
        </ButtonsWrapper>
      </Content>
    </Box>
  );
};

export const Footer = ({ closeModal, override, text }) => {
  const onClick = override || closeModal;
  return (
    <Box $height="116px" $bgColor="#81C394" $flex $justifyContent="center" $alignItems="center">
      <PaddedButton
        onClick={onClick}
        $typography="body/medium"
        $bgColor="#FFFFFF"
        $color="02Primary700"
        kind="outline"
        $maxWidth="396px"
        $height="56px"
        variant="#5FB974"
        $borderColor="02Primary0"
        $hoverBackground="02PrimaryGrey100"
        $hoverBorderColor="02PrimaryGrey100"
        $hoverColor="02Primary500"
        $sm-spacing="mx-5+1"
      >
        {text}
      </PaddedButton>
    </Box>
  );
};

const ButtonsWrapper = styled(Box)`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media ${breakpoints.miniTablet} {
    padding: 0 32px;
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

Footer.defaultProps = {
  text: "Keep Contributing",
};

const Content = styled(Box)`
  padding: 32px 50px 24px;

  @media ${breakpoints.miniTablet} {
    padding: 32px 0 24px;
  }

  @media ${breakpoints.mobile} {
    padding: 32px 0 24px;

    & > ${Text} {
      text-align: left;
    }
  }
`;

ConnectStep.Footer = Footer;

export default ConnectStep;
