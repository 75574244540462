export const uniqueArray = (arr) => Array.from(new Set(arr));

export const uniqueByID = (array) => {
  const uniqueIds = new Set();
  return array.filter((item) => {
    // Check if the item's ID is not in the set
    if (!uniqueIds.has(item.id)) {
      // Add the item's ID to the set
      uniqueIds.add(item.id);
      return true;
    }
    return false;
  });
};
