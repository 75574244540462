import Divider from "@common/Divider";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import truncateText from "@utils/truncateText";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../../../Constants";
import { AddButton, OutsideBorder, RemoveIcon, StyledTag, WrapperInputs } from "./common";

const EditAdmins = ({ communityValues, removeAdmin }) => {
  const { id } = useParams();
  const { administrators, pendingAdministrators } = useStoreState((state) => state.members);
  const {
    getAdministratorsRequest,
    getPendingAdministratorsRequest,
    reSendInvitationRequest,
    cancelInvitationRequest,
  } = useStoreActions((actions) => actions.members);
  const { find } = useStoreActions((actions) => actions.community);
  const { showToast } = useStoreActions((actions) => actions.toasts);
  const { showModal } = useStoreActions((actions) => actions.modals);

  const onInvite = () => {
    showModal({ modalName: "InviteAdministratorsModal" });
  };

  const onResend = (e) => {
    const id = e.target.dataset.id;
    const adminEmail = e.target.dataset.email;
    reSendInvitationRequest(id).then(() => {
      showToast({
        content: `Administrator Invite re-sent to ${adminEmail}.`,
        type: "success",
        dismissTime: 5000,
      });
      getPendingAdministratorsRequest({ communityId: communityValues.id, keyword: "" });
    });
  };
  const onCancel = (e) => {
    const id = e.target.dataset.id;
    const adminEmail = e.target.dataset.email;
    cancelInvitationRequest(id).then(() => {
      showToast({
        content: `Admin Invite sent to ${adminEmail} has been canceled.`,
        dismissTime: 5000,
      });
      getPendingAdministratorsRequest({ communityId: communityValues.id, keyword: "" });
    });
  };
  const onRemoveAdmin = (e) => {
    const id = e.target.dataset.id;
    const username = e.target.dataset.username;
    const admin = { id, username };
    showModal({
      modalName: "RemoveAdminModal",
      admin,
    });
  };

  useEffect(() => {
    getAdministratorsRequest({ communityId: communityValues.id, keyword: "" });
    getPendingAdministratorsRequest({ communityId: communityValues.id, keyword: "" });
  }, []);

  useEffect(() => {
    id && find(id);
  }, []);
  return (
    <div>
      {administrators.length > 0 && (
        <>
          <Text $typography="body/large" $color="01Primary700" $bold>
            Administrators
          </Text>
          <Divider />
          <Box $gap="32px" $direction="column" $flex>
            <Administrators administrators={administrators} onRemoveAdmin={onRemoveAdmin} />
          </Box>
          <Divider />
        </>
      )}
      {pendingAdministrators.length > 0 && (
        <>
          <Text $typography="body/large" $color="01Primary700" $bold>
            Pending Administrators
          </Text>
          <Divider />
          <Box $gap="32px" $direction="column" $flex>
            <PendingAdministrators
              onResend={onResend}
              onCancel={onCancel}
              pendingAdministrators={pendingAdministrators}
            />
          </Box>
        </>
      )}

      <WrapperInputs hideBorderTop={false}>
        {communityValues.admin_emails.map((admin, index) => {
          return (
            <OutsideBorder key={index}>
              <StyledTag $borderColor="01Primary300" $heightSize="56px" $padding="16px" $borderRadius="12px">
                {admin}
              </StyledTag>
              <RemoveIcon $pointer onClick={() => removeAdmin(admin)} icon="x" $color="01Primary700" $width="16px" />
            </OutsideBorder>
          );
        })}
      </WrapperInputs>

      <AddButton
        rightIcon="plus"
        $borderRadius="40px"
        $heightSize="56px"
        kind="outline"
        variant="hover"
        $color="01Primary700"
        onClick={onInvite}
        $widthFull
      >
        Invite Administrators
      </AddButton>
    </div>
  );
};

const UserBox = ({ username, image, subtext }) => {
  return (
    <>
      <Box $gap="12.3px" $flex>
        <Avatar src={image || DEFAULT_PROFILE_IMAGE} alt="Avatar" />
        <Box>
          <Text $color="01Primary700" $typography="body/medium" $extraBold>
            {username && truncateText(username)}
          </Text>
          <Text $color="01Primary700" $typography="body/small">
            {subtext}
          </Text>
        </Box>
      </Box>
    </>
  );
};

const Administrators = ({ administrators, onRemoveAdmin }) => {
  const { isMobile } = useStoreState((state) => state.media);
  return administrators.map((administrator, index) => {
    return (
      <Box $justifyContent="space-between" $flex key={index}>
        <UserBox subtext={administrator.email} username={administrator.username} image={administrator.image} />
        {administrator.role === "owner" ? (
          <Text $typography="body/medium" $color="01Primary700">
            Owner
          </Text>
        ) : (
          <Button
            $heightSize="44px"
            $bold
            $borderRadius="40px"
            $size={isMobile ? "sm" : "md"}
            leftIcon="trash-filled"
            kind="outline"
            variant="error"
            data-id={administrator.id}
            data-username={administrator.username}
            onClick={onRemoveAdmin}
          >
            Remove as admin
          </Button>
        )}
      </Box>
    );
  });
};

const PendingAdministrators = ({ pendingAdministrators, onResend, onCancel }) => {
  const { isMobile } = useStoreState((state) => state.media);
  return pendingAdministrators.map((administrator, index) => {
    return (
      <>
        <Box $justifyContent="space-between" $direction={isMobile && "column"} $gap={isMobile && "16px"} $flex key={index}>
          <UserBox subtext={administrator.invitation_date} username={administrator.email} image={administrator.image} />
          <Box $gap="8px" $flex>
            <StyledButton
              onClick={onResend}
              data-id={administrator.id}
              data-email={administrator.email}
              $heightSize="44px"
              $borderRadius="40px"
              $size={isMobile ? "sm" : "md"}
              leftIcon="send-filled"
              kind="outline"
              variant="hover"
              $bold
            >
              Resend invite
            </StyledButton>
            <StyledButton
              onClick={onCancel}
              data-id={administrator.id}
              data-email={administrator.email}
              $heightSize="44px"
              $borderRadius="40px"
              $size={isMobile ? "sm" : "md"}
              leftIcon="x"
              kind="outline"
              variant="01Primary700"
              $bold
            >
              Cancel invite
            </StyledButton>
          </Box>
        </Box>
      </>
    );
  });
};

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;
const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export default EditAdmins;
