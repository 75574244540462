import { breakpoints, Button } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const ImageWrapper = styled.div`
  position: absolute;
  top: -37.5px;
  border: 6px solid ${({ theme }) => (theme.themeName === "dark" ? "#2F3E5C" : "#fff")};
  border-radius: 50%;

  img {
    width: 89px;
    height: 89px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const TabWrapper = styled.div`
  padding: 40px 32px;

  @media ${breakpoints.tablet} {
    padding: 32px 24px;
  }

  @media ${breakpoints.mobile} {
    padding: 16px;
  }
`;

export const DisconnectButton = ({ children, ...props }) => (
  <Button
    $bold
    $bgColor="transparent"
    $borderRadius="40px"
    $borderColor="01Primary700"
    $color="01Primary700"
    leftIcon="link-2"
    kind="outline"
    $width="100%"
    $spacing="mt-5"
    {...props}
  >
    {children}
  </Button>
);
