import { Box, ListingCompactModular, Modal, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { _PreviewListing } from "../ListingEditor/PreviewListings";

const { ContributionTD, RewardTD, CompletionsTD, ConnectOrDetailsTD } = ListingCompactModular;

const EmptyTD = () => <td></td>;

const fieldClasses = [ContributionTD, RewardTD, CompletionsTD, ConnectOrDetailsTD, EmptyTD, EmptyTD];

const NOOP = () => {};

const WaysToContributePreviewModal = ({ hideModal, listing }) => {
  return (
    <Modal open onClose={hideModal} maxWidth="1073px" paddingBody="0px !important">
      <Text $typography="Heading/Small" $bold $align="center" $spacing="mb-5">
        Contribution Preview
      </Text>
      <Container $bgColor="02PrimaryGrey150" $spacing="px-6+4 py-8+5" $flex $justifyContent="center">
        <table>
          <tbody>
            <_PreviewListing listing={listing} fieldClasses={fieldClasses} toggleFavorite={NOOP} />
          </tbody>
        </table>
      </Container>
    </Modal>
  );
};

const Container = styled(Box)`
  table {
    border-spacing: 0;
  }
`;

export default WaysToContributePreviewModal;
