import { Box, Checkbox, ContextMenu, IconSvg, Tooltip } from "@thrivecoin/ui-components";
import { useEffect, useState } from "react";

export const HeaderCheckbox = ({ children, selectAll, allSelected }) => {
  const [checked, setState] = useState(allSelected);

  const tooltipMessage = checked ? "Unselect All" : "Select All";

  const onChange = (event) => {
    event.stopPropagation();
    setState(event.target.checked);
  };

  const onClick = (e) => {
    if (e.target.type == "checkbox") {
      selectAll(e.target.checked);
    }
  };

  useEffect(() => {
    setState(allSelected);
  }, [allSelected]);

  return (
    <Box $flex $alignItems="center" $gap="4px" onClick={onClick}>
      <Tooltip
        $direction="top"
        message={tooltipMessage}
        minWidth="78px"
        $bgColor="01Primary700"
        $color="01Primary0"
        $spacing="px-0"
      >
        <Checkbox checked={checked} onChange={onChange} kind="multi-theme" />
      </Tooltip>
      {children && <ContextMenu ContextButton={ContextButton}>{children}</ContextMenu>}
    </Box>
  );
};

const ContextButton = ({ onClick }) => <IconSvg onClick={onClick} icon="sort-down" $width="12px" />;
