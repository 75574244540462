import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, Button, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BottomSection = ({ raffle }) => {
  const navigate = useNavigate();
  const { isMobile } = useStoreState((state) => state.media);
  const { seasonContributorStats } = useStoreState((state) => state.season);
  const { getSeasonContributorStats } = useStoreActions((actions) => actions.season);
  const onKeepContributing = () => {
    navigate(`/seasons/${raffle.season_slug}`);
  };

  useEffect(() => {
    getSeasonContributorStats(raffle.season_slug);
  }, [raffle.season_slug]);

  return (
    <Wrapper $flex $bgColor="01Primary0" $color="01Primary700">
      <MainContainer>
        <Container>
          <Box
            $direction={isMobile && "column"}
            $gap={isMobile && "24px"}
            $alignItems="center"
            $justifyContent="space-between"
            $flex
          >
            <Description $typography="body/large">
              <Text $inline $extraBold>
                It's not too late to contribute! Every contribution increases your chances of winning.{" "}
              </Text>
              {seasonContributorStats.raffle_entries > 0 && (
                <>
                  Your contributions have already earned{" "}
                  <Text $inline $extraBold>
                    {+seasonContributorStats.rewards_earned} $THRIVE and {seasonContributorStats.raffle_entries} raffle
                    entires.{" "}
                  </Text>{" "}
                  (Every 5 $THRIVE earned = 1 raffle entry)
                </>
              )}
            </Description>
            <KeepContributingButton
              onClick={onKeepContributing}
              rightIcon="arrow-up-right"
              iconWidth="10px"
              $borderRadius="50px"
              $color="01Primary0"
              $heightSize="56px"
              $width={isMobile ? "100%" : "228px"}
              $bold
            >
              Keep Contributing
            </KeepContributingButton>
          </Box>
        </Container>
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: 23px 0 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
`;

const Container = styled(Box)`
  margin: 0 auto;
  gap: 25px;
  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.tablet} {
    padding: 0 32px;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const Description = styled(Text)`
  max-width: 969px;
  @media ${breakpoints.tablet} {
    max-width: 695px;
  }
`;
const KeepContributingButton = styled(Button)`
  font-size: 16px;
  line-height: 24px;
`;

export default BottomSection;
