import { Box, breakpoints, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import humanize from "../../utils/humanize";

const iconNames = ["info", "nft", "tokens", "contribution", "ticket", "claim", "donate", "time"];

const filteredIcons = (val) => {
  return iconNames.filter((obj) => obj.includes(val));
};

const Content = ({ selected, setOpen, setSelected }) => {
  const [search, setSearch] = useState("");
  const icons = filteredIcons(search);
  const ref = useRef(null);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const changeIcon = (e) => {
    setSelected(e.currentTarget.dataset.name);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <PopperWrapper ref={ref}>
      <Popper>
        <StyledInput value={search} onChange={onSearch} placeholder="Search icons" />
        <IconsBox>
          {icons.map((item, index) => {
            return (
              <IconWrapper key={index} data-name={item} $pointer selected={selected === item} onClick={changeIcon}>
                <IconSvg icon={item} $width="20px" $height="24px" />
              </IconWrapper>
            );
          })}
        </IconsBox>
        <SelectedBox>
          <IconSvg icon={selected} $width="24px" $height="24px" />
          <Text $bold>{humanize(selected)}</Text>
        </SelectedBox>
      </Popper>
    </PopperWrapper>
  );
};

const IconPicker = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <StyledButton onClick={() => setOpen(!open)}>
        <IconSvg $width="20px" $height="20px" icon={selected} />
        <IconSvg $width="20px" $height="20px" icon="chevron-down" />
      </StyledButton>
      {open && <Content selected={selected} setOpen={setOpen} setSelected={setSelected} />}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: relative;
`;

const StyledButton = styled(Box)`
  cursor: pointer;
  height: 56px;
  width: 90px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0 1rem;
  background: ${({ theme }) => theme.colors["01Primary50"]};
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const PopperWrapper = styled(Box)`
  position: absolute;
  inset: 0px auto auto 0px;
  z-index: 99;
  transform: translateY(66px);
  transition: all 0.2s ease;

  @media ${breakpoints.mobile} {
    inset: 0px auto auto -32px;
  }
`;

const Popper = styled(Box)`
  background: ${({ theme }) => theme.colors["01Primary0"]};
  display: flex;
  flex-direction: column;
  max-height: 374px;
  margin-bottom: 50px;
  width: 452px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.15);

  @media ${breakpoints.mobile} {
    width: 100vw;
  }
`;

const IconWrapper = styled(Box)`
  width: 58px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.colors["01Primary200"]};
  `}
`;

const IconsBox = styled(Box)`
  flex: 1;
  overflow-y: scroll;
  display: flex;
  gap: 12px;
  row-gap: 8px;
  flex-wrap: wrap;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
`;

const SelectedBox = styled(Box)`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledInput = styled(Input)`
  padding: 1rem;

  & > div > input {
    background: ${({ theme }) => theme.colors["01Primary50"]};
    border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};

    &::placeholder {
      font-size: 1rem;
    }
  }
`;

export default IconPicker;
