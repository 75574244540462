import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { EditButton, LoadingSection, SectionBox } from "./common";

const NETWORK_ICONS = {
  twitter: "twitter",
  discord: "discord",
  zoom: "zoom2",
  discourse: "discourse",
  activity: "chain",
  asana: "asana",
  email: "email",
  linkedin: "linkedin2",
  gitcoin: "gitcoin",
  github: "github",
  snapshot: "bolt",
};

const filteredListings = (listings) => {
  const data = [];
  let discourseActive = 0;
  let discourseDraft = 0;
  Object.entries(listings).forEach(([key, value]) => {
    if (value.active > 0 || value.draft > 0) {
      if (key.includes("discourse")) {
        discourseActive += value.active;
        discourseDraft += value.draft;
      } else {
        data.push({ network: key, ...value });
      }
    }
  });
  if (discourseActive > 0 || discourseDraft > 0) {
    data.push({ network: "discourse", draft: discourseDraft, active: discourseActive });
  }
  return data;
};

const ListingsSection = ({ communityId }) => {
  const [loading, setLoading] = useState(false);
  const { listingsNetworkCount } = useStoreState((state) => state.listings);
  const { getListingsNetworkCount } = useStoreActions((actions) => actions.listings);
  const { showModal } = useStoreActions((actions) => actions.modals);

  const managePath = `/manage/communities/${communityId}?tabIndex=1`;
  const networksToShow = 6;
  const networks = filteredListings(listingsNetworkCount || {}).slice(0, networksToShow);
  const showAdd = networksToShow - networks.length > 0;
  const emptyCircles = networksToShow - networks.length - 1;

  const addNewListing = () => showModal({ modalName: "ListingEditorModal", communityID: communityId });

  const _renderNetworkds = networks.map((item) => (
    <ContentBox $bgColor="02Primary500" key={item.network}>
      {NETWORK_ICONS[item.network] && <IconSvg $color="#fff" icon={NETWORK_ICONS[item.network]} />}
      <CountBox $spacing="mx-1" $bgColor={item.draft > 0 && "01Secondary500"}>
        {item.draft > 0 && (
          <Text $color="#fff" $typography="body/large">
            {item.draft}
          </Text>
        )}
      </CountBox>
      <CountBox $bgColor={item.active > 0 && "03Primary500"}>
        {item.active > 0 && (
          <Text $bold $color="#fff" $typography="body/large">
            {item.active}
          </Text>
        )}
      </CountBox>
    </ContentBox>
  ));

  const _renderEmptyCircles =
    showAdd &&
    Array(emptyCircles)
      .fill("")
      .map((_, index) => <ContentBox $bgColor="manageCard2" key={index} />);

  useEffect(() => {
    setLoading(true);
    getListingsNetworkCount(communityId).finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <LoadingSection title="Ways to Contribute" />;
  }

  return (
    <SectionBox title="Ways to Contribute">
      <Box $flex $gap="26px" $direction="column">
        <NetworksWrapper>
          {_renderNetworkds}
          {showAdd && (
            <ContentBox $pointer $bgColor="manageCard1" onClick={addNewListing}>
              <IconSvg icon="plus" $color="#fff" />
            </ContentBox>
          )}
          {_renderEmptyCircles}
        </NetworksWrapper>
        <Link to={managePath}>
          <EditButton>Edit Ways to Contribute</EditButton>
        </Link>
      </Box>
    </SectionBox>
  );
};

const NetworksWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const ContentBox = styled(Box)`
  border-radius: 8px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CountBox = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ListingsSection;
