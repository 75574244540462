import { Box, breakpoints, Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AddNetworkStep from "./AddNetworkStep";
import AddNetworkStep2 from "./AddNetworkStep2";
import ConnectSocialProfilesStep from "./ConnectSocialProfilesStep";
import CreateAccountStep from "./CreateAccountStep";
import RewardsFoundStep from "./RewardsFoundStep";
import RewardsNotFoundStep from "./RewardsNotFoundStep";
import SearchForRewardsStep from "./SearchForRewardsStep";
import SignInStep from "./SignInStep";

export const STEP_INDEXES = {
  CreateAccountStep: 0,
  SearchForRewardStep: 1,
  RewardsFoundStep: 2,
  RewardsNotFoundStep: 3,
  AddNetworkStep: 4,
  AddNetworkStep2: 5,
  SignInStep: 6,
  ConnectSocialProfilesStep: 7,
};

const STEP_KLASSES = [
  CreateAccountStep,
  SearchForRewardsStep,
  RewardsFoundStep,
  RewardsNotFoundStep,
  AddNetworkStep,
  AddNetworkStep2,
  SignInStep,
  ConnectSocialProfilesStep,
];

const GREEN_BACKGROUND_INDEX = [
  STEP_INDEXES.SearchForRewardStep,
  STEP_INDEXES.RewardsFoundStep,
  STEP_INDEXES.RewardsNotFoundStep,
  STEP_INDEXES.ConnectSocialProfilesStep,
];

const makeRegex = (prefix) => new RegExp(prefix + "/[a-zA-Z0-9\\D]+$");
export const resetPasswordRegex = makeRegex("reset_password");
export const createAccountRegex = makeRegex("create");

export const useModalRoutes = () => {
  const path = location.pathname;
  const resetPassword = resetPasswordRegex.test(path);
  const createAccount = createAccountRegex.test(path);
  const { showModal } = useStoreActions((actions) => actions.modals);

  useEffect(() => {
    if (resetPassword) {
      showModal({ modalName: "WalletSigninFlow", initialStep: STEP_INDEXES.ResetPasswordStep });
    }

    if (createAccount) {
      showModal({ modalName: "WalletSigninFlow", initialStep: STEP_INDEXES.CreateAccountStep });
    }
  }, [resetPassword, createAccount]);
};

const WalletSigninFlow = ({ hideModal, initialStep, afterClose, ...props }) => {
  const [step, goToStep] = useState(initialStep);
  const { isMobile } = useStoreState((state) => state.media);
  const StepKlass = STEP_KLASSES[step];
  const $maxWidth = StepKlass?.$maxWidth || "610px";
  const spacing = "px-7+6 pt-7+6 pb-6+2";
  const $fullScreenModal = isMobile && step !== STEP_INDEXES.AddNetworkStep && step !== STEP_INDEXES.AddNetworkStep2;
  const $borderRadius = $fullScreenModal ? "0 !important" : "24px";
  const backText = $fullScreenModal && step !== STEP_INDEXES.SearchForRewardStep && "Back to community";

  const closeModal = () => {
    hideModal();
    afterClose && afterClose();
  };

  const footerContent = StepKlass.Footer ? <StepKlass.Footer goToStep={goToStep} closeModal={closeModal} /> : null;
  const overflow = StepKlass.$overflowY ? StepKlass.$overflowY : "hidden";
  const showBgGreen = GREEN_BACKGROUND_INDEX.includes(step);

  return (
    <StyledModal
      open
      $bgColor={StepKlass.$bgColor}
      onClose={hideModal}
      leaf={!isMobile}
      $spacing={$spacing}
      footerContent={footerContent}
      $overflowY={overflow}
      $maxWidth={$maxWidth}
      $fullScreen={$fullScreenModal}
      hideX={$fullScreenModal}
      $borderRadius={isMobile && $borderRadius}
      $sm-spacing="px-0 pt-4"
      backText={backText}
      $contentHeight={$fullScreenModal && "100%"}
      showBgGreen={showBgGreen}
    >
      <StyledBox $direction="column" $textAlign="center">
        <StepKlass goToStep={goToStep} closeModal={closeModal} {...props} />
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 0;

  ${Modal.Footer} {
    padding: 0;
    background-color: ${({ showBgGreen }) => (showBgGreen ? "#81C394" : undefined)};
    margin: 0;
  }
`;

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 100%;
  }

  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

WalletSigninFlow.defaultProps = {
  initialStep: 0,
};

export default WalletSigninFlow;
