import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import DateTimePicker from "../../common/DateTimePicker";
import ContractAddress from "./ContractAdress";

const AddSmartContractModal = ({ hideModal, id, fetchData }) => {
  const { setSmartContract } = useStoreActions((actions) => actions.season);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);

  const { isMobile } = useStoreState((state) => state.media);
  const [networkAddress, setNetworkAddress] = useState([{ id: "", value: "" }]);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const changeDates = (value) => {
    setSelectedDateTime(value);
  };

  const getData = () => {
    return {
      chain_id: networkAddress[0].id,
      contract_address: networkAddress[0].value,
      deadline: selectedDateTime,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const season = getData();
    const seasonId = id;

    const data = { season };
    return setSmartContract({ data, seasonId })
      .then(({ message }) => {
        showToast({
          content: message,
          type: "success",
          dismissTime: 5000,
        });
        hideModal();
        fetchData();

      })
      .catch(onError);
  };

  const onError = ({ response }) => {
    const content = response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  useEffect(() => {
    setIsSubmitDisabled(!networkAddress[0].id || !networkAddress[0].value);
  }, [networkAddress]);

  const footerContent = (
    <>
      <ButtonsWrapper $direction={isMobile && "column"} $gap="1rem" $flex>
        <Button onClick={hideModal} kind="outline" variant="white" $borderRadius="50px" $width="100%" modal>
          Cancel
        </Button>
        <Button $borderRadius="50px" $width="100%" onClick={handleSubmit} disabled={isSubmitDisabled} modal>
          Set Smart Contract
        </Button>
      </ButtonsWrapper>
    </>
  );

  return (
    <StyledModal open onClose={hideModal} maxWidth={"621px"} footerContent={footerContent} paddingBody="8px 48px 0 48px">
      <Box $flex $direction="column" $alignItems="center" $justifyContent="center">
        <Text $typography="heading/medium" $color="02Primary500" $bold $spacing="mb-4">
          Add Smart Contract
        </Text>
        <StyledText $typography="body/medium" $color="02Primary500">
          To give contributors the option to Claim Rewards after the end of season, please set up a Smart Contract.
        </StyledText>
        <Box $bgColor="02PrimaryGrey150" height="1px" $width="100%" $spacing="my-5" />
        <ContractAddress networkAddress={networkAddress} setNetworkAddress={setNetworkAddress} />
        <Box $bgColor="02PrimaryGrey150" height="1px" $width="100%" $spacing="my-5" />
        <Box $width="100%">
          <Text $typography="body/medium" $color="02Primary500" $spacing="mb-3" $bold>
            Claim Deadline (Required){" "}
          </Text>
          <Text $typography="body/medium" $color="02Primary500" $spacing="mb-5">
            We will always use UTC +0 Timezone as Claim Deadline time
          </Text>

          <PickersBox $flex $gap="12px" $width="100%">
            <DateTimePicker id="deadline" value={new Date()} setValue={changeDates} />
            <DateTimePicker id="deadline" value={new Date()} setValue={changeDates} timeSelector />
          </PickersBox>
        </Box>
        <Box $bgColor="02PrimaryGrey150" height="1px" $width="100%" $spacing="my-5" />
      </Box>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 0;

  ${Modal.Body} {
    padding-bottom: 0;
    overflow: initial;
  }

  ${Modal.Footer} {
    padding-top: 0;

    & > div {
      padding: 0 48px;
    }
  }
`;

const ButtonsWrapper = styled(Box)`
  margin-bottom: 1.5rem;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const PickersBox = styled(Box)`
  .react-datepicker-wrapper {
    svg {
      color: ${({ theme }) => theme.colors["02Primary500"]};
    }
  }

  .react-datepicker__time-list-item,
  .react-datepicker__header,
  .react-datepicker-time__header {
    color: ${({ theme }) => theme.colors["02Primary500"]};
    background: ${({ theme }) => theme.colors["02PrimaryGrey50"]};
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  }

  & > div > div > div > div,
  .react-datepicker,
  .react-datepicker__month-container > div,
  .react-datepicker__day {
    color: ${({ theme }) => theme.colors["02Primary500"]};
    background: ${({ theme }) => theme.colors["02PrimaryGrey50"]};
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;
export default AddSmartContractModal;
