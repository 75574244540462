import { Box, breakpoints, Button, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { isEmail } from "@utils/validations";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import Switcher from "./Switcher";

const EmailNotifications = ({ enabled, toggle }) => {
  const { isDesktop } = useStoreState((state) => state.media);
  const { showToast } = useStoreActions((actions) => actions.toasts);
  const { updateNotificationEmailRequest, verifyNotificationEmailRequest } = useStoreActions((actions) => actions.user);
  const { getProfileRequest } = useStoreActions((actions) => actions.authentication);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { notifications_email, notifications_email_confirmed } = useStoreState((state) => state.user.user);
  const _email = notifications_email_confirmed ? notifications_email : undefined;
  const [email, setEmail] = useState(_email);
  const [labelButton, setLabelButton] = useState("Apply");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [notificationsEmailErrors, setNotificationEmailErrors] = useState([]);

  const showEmailError = () => notificationsEmailErrors.length > 0;

  const onCompleted = (confirmation_code, setvalidationText, hideModal) => {
    return verifyNotificationEmailRequest(confirmation_code)
      .then(() => {
        const email = document.getElementById("email").value;
        setEmail(email);
        hideModal();
        getProfileRequest();
        showToast({
          content: "Your email address has been successfully updated.",
          type: "success",
          dismissTime: 5000,
        });
      })
      .catch(({ response }) => {
        if (response.status == 422) {
          setvalidationText(response.data.message);
        }
      });
  };

  const handleApply = (e) => {
    e.preventDefault();

    const email = document.getElementById("email").value;
    const invalid = !isEmail(email);

    setIsEmailInvalid(invalid);
    if (!invalid) {
      updateNotificationEmailRequest(email)
        .then(() => {
          setNotificationEmailErrors([]);
          showModal({ modalName: "PinCodeVerificationModal", email, onCompleted });
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            setNotificationEmailErrors(response.data.data.errors.notifications_email);
          }
        });
    }
  };

  const editEmail = () => {
    setEmail("");
    setLabelButton("Update email");
  };

  const toggleNotifications = (e) => {
    toggle(e.target.checked);
  };

  return (
    <TopWrapper>
      <StyledBox $flex $alignItems="center" $spacing="mb-6">
        <Text $color="01Primary700" $typography="body/medium" $maxWidth={isDesktop && "406px"}>
          ThriveCoin can email me about key events related to my in-app activity
        </Text>
        <Switcher checked={enabled} onChange={toggleNotifications} />
      </StyledBox>
      {!email ? (
        <>
          <ApplyWrapper onSubmit={handleApply} disabled={!enabled}>
            <Input
              className={isEmailInvalid && "invalidEmail"}
              alwaysShowValidation={showEmailError}
              validationText={notificationsEmailErrors[0]}
              placeholder="Email"
              name="email"
              id="email"
              type="email"
              disabled={!enabled}
            />
            <StyledButton
              disabled={!enabled}
              $bgColor="button"
              $color="01Primary0"
              $size="md"
              type="submit"
              $heightSize="56px"
              $bold
            >
              {labelButton}
            </StyledButton>
          </ApplyWrapper>
        </>
      ) : (
        <>
          <WrapperEmail disabled={!enabled}>
            <CheckIcon icon="circle-check-filled" />
            <Text $typography="body/medium" $spacing="ml-2 mr-3" $color="01Primary700" $bold>
              {email}
            </Text>
            <PenIcon icon="pen-to-square" onClick={editEmail} />
          </WrapperEmail>
        </>
      )}
    </TopWrapper>
  );
};

const TopWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
  padding-bottom: 32px;
  margin-bottom: 40px;
`;

const StyledBox = styled(Box)`
  justify-content: space-between;
  gap: 79px;
  @media ${breakpoints.tablet} {
    gap: 94px;
  }
  @media ${breakpoints.mobile} {
    gap: 17px;
  }
`;

const ApplyWrapper = styled.form`
  display: flex;
  max-width: 524px;
  width: 100%;
  ${({ disabled }) => disabled && "opacity: 0.25;"}

  & input {
    border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: ${({ theme }) => theme.colors["01Primary0"]};
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &:focus,
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors["01Primary400"]};
    }
  }

  .invalidEmail {
    input {
      border-color: red;
    }
  }
`;

const WrapperEmail = styled.div`
  display: flex;
  align-items: center;
  ${({ disabled }) => disabled && "opacity: 0.25; pointer-events: none;"}
  svg:last-child {
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  ${({ disabled, theme }) =>
    disabled &&
    `
    background: ${theme.colors.button} !important;
    color: ${theme.colors["01Primary0"]} !important;
  `}
  white-space: nowrap;
  border-radius: 0px 8px 8px 0px;
`;
const CheckIcon = styled(IconSvg)`
  path {
    fill: ${({ theme }) => theme.colors["03Primary500"]};
  }
`;

const PenIcon = styled(IconSvg)`
  path {
    fill: ${({ theme }) => theme.colors["01Primary700"]};
  }
`;
export default EmailNotifications;
