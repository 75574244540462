import HeaderElement from "@common/HeaderElement";
import { Box, breakpoints, Loader } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import { SearchEmpty } from "../EmptyContent";
import RowElement from "./RowElement";

const TableContributors = ({
  data,
  onProfileClick,
  _group,
  group,
  loading,
  isSearching,
  rewards_token,
  isCustomToken,
  sortProps,
  user,
  topThreeRanks,
}) => {
  const { isDesktop } = useStoreState((state) => state.media);

  const isCommunity = group === "community";

  return (
    <>
      <Table>
        <thead>
          <tr>
            <HeaderElement
              _group={_group}
              $typography="body/small"
              text="Status"
              text2="Contributors"
              $firstElement
              $padding="0 0 12px 24px!important"
              gapText="44px"
            />
            {!isCommunity && (
              <HeaderElement
                text={isCustomToken ? "End of Season Prize" : "Rewards"}
                name="amount"
                $padding="0 0 12px 0"
                $minWidth={isDesktop && "200px"}
              />
            )}
            <HeaderElement text="Contributions" name="count" $padding="0 0 12px 0" $width="130px" />
          </tr>
        </thead>

        <TBody>
          {!loading && (
            <>
              {data.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <SearchEmpty />
                  </td>
                </tr>
              ) : (
                data.map((item) => {
                  const personal = item.user_id === user.id;
                  const rank = item.contributor_rank;

                  return (
                    <>
                      <RowElement
                        key={item.id}
                        id={rank}
                        {...item}
                        _group={_group}
                        onProfileClick={onProfileClick}
                        index={rank}
                        isSearching={isSearching}
                        rewards_token={rewards_token}
                        isCustomToken={isCustomToken}
                        sortProps={sortProps}
                        personal={personal}
                        isDesktop={isDesktop}
                        isCommunity={isCommunity}
                        topThreeRanks={topThreeRanks}
                      />
                    </>
                  );
                })
              )}
            </>
          )}
        </TBody>
      </Table>
      {loading && (
        <Box $width="100%" $spacing="m-a p-9+6" $flex $alignItems="center" $justifyContent="center">
          <Loader />
        </Box>
      )}
    </>
  );
};

const Table = styled.table`
  width: 100%;
  margin-top: 28px;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
  border-collapse: separate;
  border-spacing: 0;
  @media ${breakpoints.tablet} {
    margin-top: 32px;
  }
  tr {
    th:first-child {
      padding-left: 0;
    }
  }
`;

const TBody = styled.tbody`
  width: 100%;
  border-radius: 8px 8px 0px 0px;
`;

export default TableContributors;
