import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isSeasonCompleted } from "../../common";
import CompletedSeasonState from "./CompletedSeasonState";
import SeasonPrizes from "./SeasonPrizes";
import SeasonStats from "./SeasonStats";
import UncompletedState from "./UncompletedState";

const BottomPart = ({ season, seasonDetails, scrollToBody }) => {
  const navigate = useNavigate();
  const { isMobile, isDesktop, isTablet } = useStoreState((state) => state.media);
  const seasonCompleted = isSeasonCompleted(season.status);

  const onCommunityDetails = () => navigate(`/communities/${season.community_id}`);

  return (
    <Wrapper $bgColor="headerBackground">
      <MainContainer>
        <Container>
          <Box>
            <ContainerInside>
              <LeftPart>
                {!seasonCompleted && (
                  <UncompletedState season={season} seasonDetails={seasonDetails} scrollToBody={scrollToBody} />
                )}
                {seasonCompleted && (
                  <CompletedSeasonState
                    season={season}
                    seasonDetails={seasonDetails}
                    onCommunityDetails={onCommunityDetails}
                  />
                )}
              </LeftPart>
            </ContainerInside>
          </Box>
          <StatsWrapper>
            {<SeasonStats season={season} seasonDetails={seasonDetails} />}
            {isDesktop && !seasonCompleted && <SeasonPrizes season={season} seasonDetails={seasonDetails} />}
          </StatsWrapper>
        </Container>
        {(isTablet || isMobile) && !seasonCompleted && <SeasonPrizes season={season} seasonDetails={seasonDetails} />}
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  margin-top: -1px;
  position: relative;
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  gap: 24px;
  padding-top: 20px;

  @media ${breakpoints.miniTablet} {
    flex-direction: column;
    gap: unset;

    & > div:first-child {
      order: 2;
    }
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: unset;

    & > div:first-child {
      order: 2;
    }
  }
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${breakpoints.mobile} {
    gap: 24px;
    max-width: unset;
  }
`;

const StatsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ContainerInside = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;

  @media ${breakpoints.miniTablet} {
    flex-direction: column;
    align-items: unset;
  }

  @media ${breakpoints.mobile} {
    position: relative;
    flex-direction: column;
    gap: 32px;
    align-items: unset;
  }
`;

export default BottomPart;
