import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { ConnectionButtons, DiscourseButtons } from "../../common/NetworkConnections";

const NetworkConnections = (props) => {
  const { discourseConnections } = props;
  const keys = Object.keys(discourseConnections);
  const connectionCount = keys.filter((key) => discourseConnections[key]).length;

  return (
    <>
      <WrapperButtons>
        <ConnectionButtons {...props} />
      </WrapperButtons>
      <Box>
        <Line $height="1px" $bgColor="01Primary100" />
        <Box $flex $gap="12px" $alignItems="center" $spacing="mb-6+2">
          <DiscourseBox $flex $justifyContent="center" $alignItems="center">
            <IconSvg icon="discourse" $color="white" />
          </DiscourseBox>
          <Text $typography="body/large" $bold $color="01Primary700">
            {connectionCount} of {keys.length} Discourse Connections
          </Text>
        </Box>
        <WrapperButtons>
          <DiscourseButtons {...props} />
        </WrapperButtons>
      </Box>
    </>
  );
};

const WrapperButtons = styled.div`
  display: grid;
  ${({ mt }) => mt && `margin-top: ${mt};`}
  hr {
    width: 100%;
  }
  grid-template-columns: repeat(4, 1fr);
  button {
    line-height: 24px;
    width: 100%;
    padding: 16px;
    svg {
      overflow: overlay;
    }
  }
  gap: 29.24px;
  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${breakpoints.miniTablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`;

NetworkConnections.defaultProps = {
  canDisconnect: true,
  showCount: true,
};

const Line = styled(Box)`
  margin: 40px 0 32px 0;
`;

const DiscourseBox = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors["discourse"]};
`;
export default NetworkConnections;
