import TabWrapper  from "@common/TabWrapper";
import { Box, breakpoints, RaffleCard } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatUsername } from "../../../utils/formatting";
import { DEFAULT_PROFILE_IMAGE } from "../../Constants";

const RafflePrizes = ({ raffle_id, editorRafflePrizes }) => {
  const navigate = useNavigate();
  const paramId = useParams().id;
  const id = raffle_id ? raffle_id : paramId;
  const { user } = useStoreState((state) => state.user);
  const { rafflePrizes } = useStoreState((state) => state.raffle);
  const { getRafflePrizes } = useStoreActions((actions) => actions.raffle);

  const _rafflePrizes = editorRafflePrizes || rafflePrizes;

  const onProfileClick = (user_id) => {
    navigate(`/${user_id}/profile`);
  };

  useEffect(() => {
    if (!editorRafflePrizes) {
      getRafflePrizes({ id });
    }
  }, []);

  return (
    <TabWrapper>
      <Content $spacing="mx-a">
        <PrizesWrapper $gap="26.57px">
          {_rafflePrizes.map((item, index) => (
            <RaffleCard
              key={index}
              congrats={user.user_id === item.winner_id}
              {...item}
              hideUserInfo={!item.winner_username}
              user_name={formatUsername(item.winner_username)}
              user_image={item.winner_image_url ? item.winner_image_url : DEFAULT_PROFILE_IMAGE}
              onProfileClick={onProfileClick}
              user_id={item.winner_id}
            />
          ))}
        </PrizesWrapper>
      </Content>
    </TabWrapper>
  );
};

const Content = styled(Box)`
  @media ${breakpoints.mobile} {
    width: calc(100% - 32px);
  }
`;

const PrizesWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 304.58px);
  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(auto-fit, 292.59px);
    gap: 23.74px;
  }
  @media ${breakpoints.miniTablet} {
    grid-template-columns: auto auto;
    gap: 27.93px;
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(auto-fit, 341.38px);
  }
`;

export default RafflePrizes;
