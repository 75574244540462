import MenuImg from "@assets/menu-bottom-img.svg";
import { CenteredBox } from "@common/MainContainer";
import { Box, breakpoints, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { THRIVE_DISCORD_LINK, THRIVE_TWITTER_LINK } from "../../constants";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { changeTheme } from "../../utils/ThemeContainer";
import { CommunityContext } from "../CommunityContext";
import AuthButtons from "./AuthButtons";
import HeaderProfile from "./HeaderProfile";

const Header = () => {
  const { isCustomDomain, communityBrand } = useContext(CommunityContext);
  const { themeName } = useContext(ThemeContext);
  const { username, confirmed } = useStoreState((state) => state.user.user);
  const { isAnyAdmin } = useStoreState((state) => state.user);
  const { isTablet, isMobile, isMiniTablet } = useStoreState((state) => state.media);
  const navigate = useNavigate();
  const onClick = () => navigate("/");
  const { pathname } = useLocation();
  const [isActive, setActive] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem("theme") || "system");
  const showBrandLogo = isCustomDomain && !!communityBrand?.logo_url;
  const showBrandLogoOnMenu = showBrandLogo && isMobile;
  const isDarkMode = themeName === "dark" || themeName === "customDark";
  const brandLogoUrl = isDarkMode ? communityBrand.logo_dark_url : communityBrand.logo_url;

  const showBackButton =
    (isTablet || isMobile) &&
    (pathname.includes("/activity/") || pathname.includes("/step_completed") || pathname.includes("/challenge/"));

  const toggleClass = () => setActive(!isActive);

  const getTheme = () => {
    setSelectedTheme(localStorage.getItem("theme"));
  };

  const onSwitch = (event) => {
    const { theme } = event.currentTarget.dataset;
    changeTheme(theme);
  };

  useEffect(() => {
    window.addEventListener("theme-change", getTheme);
    return () => {
      window.removeEventListener("theme-change", getTheme);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").removeAttribute("style");
    }
  }, [isActive]);

  return (
    <Wrapper>
      <Navigation className={isActive && "active"}>
        <CenteredBox>
          <Container $justifyContent={isActive && "center"} $direction={isActive && "column"} $isActive={isActive}>
            <LeftPart
              $flex
              $alignItems={isActive ? "center" : "baseline"}
              $direction={isActive && "column"}
              $width={isActive && "100%"}
            >
              {!showBackButton && (
                <>
                  {!isCustomDomain && (
                    <Logo
                      icon={isDarkMode ? "thrive-logo-white" : "thrivecoin-logo"}
                      $spacing={!isMobile && "mr-8"}
                      $width={isMobile ? "135px" : "148px"}
                      $alignSelf={isActive && "baseline"}
                      onClick={onClick}
                    />
                  )}

                  {showBrandLogo && !isActive && <StyledLogo onClick={onClick} src={brandLogoUrl} />}

                  <div className="buttons-nav">
                    {!isCustomDomain ? (
                      <StyledLink to="/" active={+true}>
                        Dashboard
                      </StyledLink>
                    ) : (
                      <StyledExternalLink href="https://app.thrivecoin.com/" target="_blank" active={+true}>
                        Dashboard
                      </StyledExternalLink>
                    )}

                    <Box $flex>
                      {showBrandLogoOnMenu && <StyledLogo className="menu-logo" onClick={onClick} src={brandLogoUrl} />}
                      <StyledExternalLink href="https://www.thrivecoin.com/" target="_blank" active={+true}>
                        About Us
                      </StyledExternalLink>
                    </Box>
                    {isAnyAdmin && <StyledLink to="/manage/communities">Manage</StyledLink>}

                    {(isMobile || isMiniTablet) && (
                      <Box>
                        <Text $typography="body/small" $color="01Primary700" $spacing="mb-5">
                          Theme Selector: {capitalizeFirstLetter(selectedTheme)}
                        </Text>
                        <Box $flex $gap="1rem">
                          <SwitchButton
                            icon="brightness"
                            selected={selectedTheme === "light"}
                            theme="light"
                            onClick={onSwitch}
                          />
                          <SwitchButton
                            icon="moon"
                            selected={selectedTheme === "dark"}
                            theme="dark"
                            onClick={onSwitch}
                          />
                          <SwitchButton
                            icon="gear"
                            selected={selectedTheme === "system"}
                            theme="system"
                            onClick={onSwitch}
                          />
                        </Box>
                      </Box>
                    )}
                  </div>
                </>
              )}

              {showBackButton && (
                <Button onClick={onClick} variant="01Primary0" $color="black" leftIcon="arrow-left" $spacing="p-0">
                  Back to Community
                </Button>
              )}
            </LeftPart>
            {!confirmed ? (
              <div className="auth-btns">
                <AuthButtons isMobileModalOpen={isActive} />
              </div>
            ) : (
              <div className="header-profile">
                <HeaderProfile name={username} />
              </div>
            )}
          </Container>
        </CenteredBox>
        <div className="bottom-part">
          <Box $flex $justifyContent="center" $alignItems="center" $height="40px" $gap="16px">
            <a aria-label="Discord" target="_blank" href={THRIVE_DISCORD_LINK} rel="noreferrer">
              <IconWrapper discord>
                <IconSvg icon="discord" $width="20px" />
              </IconWrapper>
            </a>
            <a aria-label="Twitter" target="_blank" href={THRIVE_TWITTER_LINK} rel="noreferrer">
              <IconWrapper twitter>
                <IconSvg icon="twitter" $width="20px" />
              </IconWrapper>
            </a>
            <a aria-label="Mail" href="mailto:support@thrivecoin.com">
              <IconWrapper email>
                <IconSvg icon="envelope" $width="15px" />
              </IconWrapper>
            </a>
          </Box>
          <ImageStyled src={MenuImg} />
        </div>
      </Navigation>
      <MainMenu className={isActive && "active"} onClick={toggleClass}>
        <span></span>
      </MainMenu>
    </Wrapper>
  );
};

const SwitchButton = ({ icon, selected, onClick, theme }) => {
  return (
    <SwitchContainer $flex selected={selected} $pointer onClick={onClick} data-theme={theme}>
      <IconSvg icon={icon} $width="16px" $color="inherit" />
    </SwitchContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 0;
  background: ${({ theme }) => theme.colors["navigation"]};
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  @media ${breakpoints.tablet} {
    padding-right: 16px;
  }
  @media ${breakpoints.mobile} {
    padding: 0 16px;
    height: 58px;
  }
`;

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  ${({ isActive }) =>
    isActive &&
    `
    @media ${breakpoints.belowDesktop} {
      margin: 0 auto 32px;
    }
  `}
`;

const StyledLink = styled(NavLink)`
  margin-right: 3rem;
  text-decoration: none;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors["01Primary700"]};
  outline: none;
  cursor: pointer;

  @media ${breakpoints.belowDesktop} {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }

  &.active {
    font-weight: 700;
    position: relative;
    padding: 6px 0;
    ::before {
      content: "";
      display: block;
      border-bottom: 2px solid ${({ theme }) => theme.colors["01Primary700"]};
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 2px;
      width: 90%;
      border-radius: 50px;
      transform: translate(-50%, 0);
      @media ${breakpoints.belowDesktop} {
        display: none;
      }
    }
  }
`;

const Logo = styled(IconSvg)`
  cursor: pointer;
  ${({ $alignSelf }) => $alignSelf && `align-self: ${$alignSelf}`};
`;

const StyledExternalLink = styled.a`
  margin-right: 3rem;
  text-decoration: none;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors["01Primary700"]};
  outline: none;
  cursor: pointer;

  @media ${breakpoints.belowDesktop} {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }

  &.active {
    font-weight: 700;
    position: relative;
    ::before {
      content: "";
      display: block;
      border-bottom: 2px solid ${({ theme }) => theme.colors["02Primary500"]};
      position: absolute;
      bottom: 0;
      height: 4px;
      width: 40px;
      transform: translate(50%, 0);
      @media ${breakpoints.belowDesktop} {
        display: none;
      }
    }
    padding: 6px 0;
  }
`;

const LeftPart = styled(Box)`
  @media ${breakpoints.desktop}, @media ${breakpoints.tablet} {
    flex: 1;
  }
`;

const MainMenu = styled.button`
  display: none;
  padding: 0;
  cursor: pointer;
  z-index: 9;
  height: 50px;
  width: 18px;
  background: transparent;
  outline: 0;
  float: right;
  position: relative;
  margin-left: 11px;
  outline: none;
  border: none;
  transform: rotateZ(180deg);
  @media ${breakpoints.belowDesktop} {
    display: block;
  }

  > span {
    transition: all 0.1s ease 0s;
    display: block;
    position: relative;
    width: 18px;
    height: 2px;
    margin: auto;
    background: ${({ theme }) => theme.colors["cta2"]};
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
      transition: all 0.2s ease 0s;
      position: absolute;
      content: "";
      width: 18px;
      height: 2px;
      background: ${({ theme }) => theme.colors["cta2"]};
      left: 0;
    }

    &:after {
      top: -7px;
    }

    &:before {
      bottom: -7px;
    }
  }

  &.active {
    width: 20px;
    height: 30px;
    position: absolute;
    right: 22px;
  }

  &.active > span {
    background: transparent;

    &:after {
      top: 0;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      background: ${({ theme }) => theme.colors["01Primary700"]};
    }

    &:before {
      bottom: 0;
      transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      background: ${({ theme }) => theme.colors["01Primary700"]};
    }
  }
`;

const Navigation = styled.div`
  width: 100%;

  .header-profile {
    @media ${breakpoints.belowDesktop} {
      & > div {
        & > div {
          margin-top: 4px;
        }
        & > div:last-child {
          height: 29px;
          width: 29px;
          margin-top: 0;
        }
      }
    }
  }
  & > div {
    @media ${breakpoints.tablet} {
      width: calc(100% - 30px);
      padding-left: 24px;
      padding-right: 6px;
    }
  }
  & > div {
    @media ${breakpoints.mobile} {
      width: 100%;
    }
  }
  .bottom-part {
    display: none;
  }
  .buttons-nav {
    display: flex;
    align-items: center;
  }
  @media ${breakpoints.belowDesktop} {
    width: 100%;
    transition: 0.3s all ease-in-out;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

    .buttons-nav {
      display: none;
    }

    &.active {
      position: fixed;
      left: 0;
      height: 100%;
      top: 0;
      background: ${({ theme }) => theme.colors.navigationMobile};
      transform: translateX(0);

      .auth-btns {
        button {
          color: ${({ theme }) => theme.colors.success500};
          min-width: 299px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          height: 56px;

          svg {
            color: ${({ theme }) => theme.colors.success500};
          }
        }
        .btn-outline {
          background: transparent;
          color: ${({ theme }) => theme.colors["01Primary0"]};
          border-color: ${({ theme }) => theme.colors["01Primary0"]};
        }
      }
      .buttons-nav {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 48px;
        margin: 114px 0 48px 0;
        a {
          padding: 0;
        }
        .active {
          font-weight: 600;
        }
      }
      .bottom-part {
        margin: auto auto 0;
        display: flex;
        flex-direction: column;
        gap: 44px;
      }
      & > div:first-child > div > div > svg {
        position: absolute;
        top: 14px;
        left: 16px;
        path:first-child,
        path:nth-child(3) {
          fill: ${({ theme }) => theme.colors["02Primary0"]};
        }

        path:nth-child(2),
        path:nth-child(4) {
          fill: ${({ theme }) => theme.colors.navigationMobileLogo};
        }
      }

      .header-profile {
        display: none;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;

const IconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  color: ${({ theme }) => theme.colors["02Primary700"]};
  svg {
    margin: 0 !important;
  }
  ${({ discord, twitter }) => `
      background-color: ${discord ? "#4253E4" : twitter ? "#1DA1F2" : "#E2857E"};
      color: white;
  `}
`;

const SwitchContainer = styled(Box)`
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors["01Primacry700"]};
  border-radius: 50%;
  ${({ theme, selected }) => selected && `background-color: ${theme.colors["01Primary700"]};`}
  color: ${({ theme, selected }) => theme.colors[selected ? "01Primary0" : "01Primary700"]};
`;

const ImageStyled = styled.img`
  width: 290px;
  height: 155px;
  margin: 0 auto;
`;

const StyledLogo = styled.img`
  height: 24px;
  align-self: center;
  cursor: pointer;
  margin-right: 3.75rem;
  @media ${breakpoints.belowDesktop} {
    max-width: 135px;
    margin-right: 0;
  }

  &.menu-logo {
    position: relative;
    right: 1rem;
  }
`;

Header.defaultProps = {
  name: "Jonathan Doe",
};

export default Header;
