import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const TableHeaderElement = ({ text, onSort, name, sort, firstElement }) => {
  return (
    <HeaderItem $width={firstElement && "900px"}>
      <Box data-name={name} onClick={onSort} $flex $gap="8px" $alignItems="center">
        <Text $bold $size="14px" $lineHeight="21px" $color="01Primary700" $textAlign="left">
          {text}
        </Text>
        {!!sort && <IconSvg $width="9px" icon={sort === "desc" ? "down-long" : "up-long"} />}
      </Box>
    </HeaderItem>
  );
};

const HeaderItem = styled.th`
  padding: 17px 16px;
  max-width: ${({ $maxWidth }) => $maxWidth && $maxWidth};
  width: ${({ $width }) => $width && $width};

  div {
    cursor: default;
    max-width: inherit;

    & * {
      pointer-events: none;
    }
  }
`;
export default TableHeaderElement;
