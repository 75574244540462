import TabWrapper from "@common/TabWrapper";
import { ListingFilters } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CommunityContext } from "../../../CommunityContext";
import useSort from "../../../hooks/useSort";
import { CONTRIBUTION_SORT_OPTIONS } from "../../../SortOptions";
import TableContributions from "./TableContributions";
import TableTab from "./TableTab";
import useTagFilters from "./useTagFilters";

const sortOrder = [
  "Newest to oldest",
  "Oldest to newest",
  "Reward (highest to lowest)",
  "Reward (lowest to highest)",
  "Contribution (A-Z)",
  "Contribution (Z-A)",
  "Contributor (A-Z)",
  "Contributor (Z-A)",
];

const sortArray = (array) => array.sort((a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label));

const modalProps = {
  sortOptions: sortArray(CONTRIBUTION_SORT_OPTIONS),
  backLabel: "Back to Community Page",
  filterLabel: "Contributions",
};

const Contributions = () => {
  const navigate = useNavigate();
  const { communityId } = useContext(CommunityContext);
  const { getCommunityRewards } = useStoreActions((actions) => actions.rewards);
  const { communityRewards, communityRewardsPagination } = useStoreState((state) => state.rewards);
  const { getPublicSeasons } = useStoreActions((actions) => actions.community);
  const { publicSeasons } = useStoreState((state) => state.community);

  const { isMobile } = useStoreState((state) => state.media);
  const { community } = useStoreState((state) => state.community);
  const sortProps = useSort(getCommunityRewards, "date", "community_id", communityId);
  const title = communityRewards && `Community contributions to ${community?.name}`;

  const onCommunityClick = (e) => navigate(`/communities/${e.target.dataset.id}`);

  const onProfileClick = (e) => {
    const user_id = e.target.dataset.userid;
    navigate(`/${user_id}/profile`);
  };

  const rowProps = {
    onCommunityClick,
    onProfileClick,
  };

  const justifyTitle = isMobile ? "space-between" : "center";

  const filterFN = (text, selectedSeasons) => {
    const season_ids = selectedSeasons.map((season) => season.value);
    getCommunityRewards({ community_id: communityId, season_ids, text });
  };

  const tagProps = useTagFilters(publicSeasons, filterFN, sortProps, community);

  const selectedItems =
    !isMobile && tagProps.persistentOptions.filter((tag) => tag.selected && tag.id).map((tag) => Number(tag.id));

  const requestFilters = () => {
    return {
      ...{ season_ids: isNaN(selectedItems) ? [] : selectedItems },
    };
  };

  const onLoadMore = (page) => {
    const filters = requestFilters();
    return Promise.resolve(
      sortProps
        .sortMethod({
          community_id: communityId,
          page,
          ...filters,
        })
        .finally(() => sortProps.setLoading(false))
    );
  };

  useEffect(() => {
    const filters = requestFilters();
    getCommunityRewards({ community_id: communityId, ...filters });
  }, [selectedItems[0], tagProps.filterOptions[1]?.selectedOptions.length]);

  useEffect(() => {
    if (communityId) {
      getPublicSeasons({ id: communityId });
    }
  }, []);

  return (
    <TableTab
      title={title}
      titleSearch
      justifyTitle={justifyTitle}
      sortOptions={modalProps.sortOptions}
      TableComponent={TableContributions}
      data={communityRewards}
      pagination={communityRewardsPagination}
      sortProps={{ ...sortProps, onLoadMore }}
      rowProps={rowProps}
      modalProps={modalProps}
      isContributions={true}
      WrapperKlass={TabWrapper}
    >
      {!isMobile && <ListingFilters showFilter={true} {...tagProps} />}
    </TableTab>
  );
};

export default Contributions;
