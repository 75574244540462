import { Box } from "@thrivecoin/ui-components";
import { normalize } from "@utils/socialUtils";
import ConnectionButton from "./SocialButtons/ConnectionButton";

const DEFAULT_CONFIG = { displayName: "MISSING CONFIGURATION" };

const DiscourseButtons = ({ discourseConnections, countByApp }) => {
  const socials = Object.entries(discourseConnections).map(([key, item]) => {
    const config = normalize(item.provider || key) || DEFAULT_CONFIG;
    const { displayName, appName } = config;
    const count = countByApp[appName];
    const connected = !!item.id;

    return <ConnectionButton key={key} displayName={displayName} count={count} connected={connected} {...config} />;
  });

  return (
    <Box $flex $direction="column" $gap="30px">
      {socials}
    </Box>
  );
};

DiscourseButtons.defaultProps = {
  canDisconnect: true,
  showCount: true,
  isOpen: false,
};

export default DiscourseButtons;
