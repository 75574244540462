import CardHand from "@assets/profile/card-hand.svg";
import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { getMetamaskProvider } from "../../utils/web3/connectors/Metamask";
import { doConnect } from "../../utils/web3/useSetUpWeb3Provider";

const defaultConfig = {
  title: "Connect Wallet",
  description:
    "This is a <b>read-only</b> connection to verify your wallet address and let us know where to send any future crypto rewards.",
  metamaskIcon: "metamask2",
  coinBaseIcon: "coinbase",
  walletConnectIcon: "walletconnect",
  type: "solid",
  $bgColor: "button",
  $color: "02Primary0",
  $spacing: "px-7 pt-6 pb-0",
  $smSpacing: "px-7 pb-0",
};

const additionalConfig = {
  title: "Connect Additional Wallet",
  description:
    "This is a <b>read-only</b> connection to verify your ownership of an additional wallet. Verifying an additional wallet allows you to be rewarded for your activity across multiple wallets.",
  metamaskIcon: "metamask2",
  coinBaseIcon: "coinbase",
  walletConnectIcon: "walletconnect",
  type: "solid",
  $bgColor: "button",
  $color: "02Primary0",
  $spacing: "px-7 pt-6 pb-0",
  $smSpacing: "p-0",
};

const WalletConnectorModal = ({
  hideModal,
  afterClose,
  referral_code,
  rememberProvider,
  useAsProvier,
  isAdditional,
}) => {
  const { isMobile } = useStoreState((state) => state.media);
  const [loading, setLoading] = useState(false);
  const showMetamask = getMetamaskProvider()?.isMetaMask;
  const config = isAdditional ? additionalConfig : defaultConfig;
  const {
    title,
    description,
    metamaskIcon,
    coinBaseIcon,
    walletConnectIcon,
    type,
    $bgColor,
    $color,
    $spacing,
    $smSpacing,
  } = config;

  const onClick = async (event) => {
    const { name } = event.target.dataset;
    if (name) {
      setLoading(true);
      doConnect(name.toLowerCase(), rememberProvider, useAsProvier)
        .then((provider) => {
          if (provider) {
            hideModal();
            afterClose && afterClose(referral_code, provider);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      open
      onClose={hideModal}
      $spacing={$spacing}
      $sm-spacing={$smSpacing}
      $md-spacing="px-0 pt-6"
      $maxWidth="684px"
      $borderRadius={isMobile ? "24px" : "12px"}
      $paddingBody={isMobile && "0"}
      $contentHeight={!isMobile && "525px"}
    >
      <Box $flex $direction="column" $color="center" $alignItems="center">
        <Text $typography="heading/large" $spacing="mb-4">
          {title}
        </Text>
        <Text
          $typography="body/medium"
          $maxWidth="520px"
          $spacing="mb-6"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {showMetamask && (
          <Button
            $bold
            leftIcon={metamaskIcon}
            onClick={onClick}
            loading={loading}
            iconWidth="20px"
            data-name="Metamask"
            $spacing="mb-6 p-4"
            $borderRadius="50px"
            $width="100%"
            $maxWidth="524px"
            $borderColor="button"
            $color={$color}
            $bgColor={$bgColor}
            kind={type}
            modal={true}
          >
            Connect Metamask
          </Button>
        )}

        <Button
          iconWidth="20px"
          leftIcon={coinBaseIcon}
          onClick={onClick}
          loading={loading}
          $bold
          data-name="coinbase"
          $spacing="mb-6 p-4"
          $borderRadius="50px"
          $width="100%"
          $maxWidth="524px"
          $color={$color}
          $borderColor="button"
          $bgColor={$bgColor}
          kind={type}
          modal={true}
        >
          Connect Coinbase Wallet
        </Button>

        <Button
          leftIcon={walletConnectIcon}
          $bold
          iconWidth="20px"
          onClick={onClick}
          loading={loading}
          data-name="WalletConnect"
          $spacing="p-4"
          $borderRadius="50px"
          $width="100%"
          $maxWidth="524px"
          $color={$color}
          $borderColor="button"
          $bgColor={$bgColor}
          kind={type}
          modal={true}
        >
          Connect WalletConnect
        </Button>
      </Box>
      <CardHandImg src={CardHand} />
    </Modal>
  );
};

const CardHandImg = styled.img`
  margin: 16.11px auto 0 auto;
  min-height: 197px;
`;

WalletConnectorModal.defaultProps = {
  rememberProvider: true,
  useAsProvier: true,
};

export default WalletConnectorModal;
