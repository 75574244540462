import OG from "@assets/dashboard/og-dark.svg";
import { Box, breakpoints, IconSvg } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const OgBox = () => {
  const navigate = useNavigate();

  const { user } = useStoreState((state) => state.user);

  const OGPhoto = user.image_url || OG;

  const onProfileClick = (e) => navigate(`/${user.user_id}/profile`);

  return (
    <ImgWrapper>
      <OgImg defaultAvatar={!user.image_url}>
        <img src={OGPhoto} />
        <Link onClick={onProfileClick} $flex $alignItems="center" $justifyContent="center">
          <IconSvg icon="pen-outline" $width="12px" $color="headerText" />
        </Link>
      </OgImg>
    </ImgWrapper>
  );
};

const ImgWrapper = styled(Box)`
  position: relative;
`;

const Link = styled(Box)`
  position: relative;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors["02Primary0"]}};
  position: absolute;
  left: 24px;
  bottom: 30px;
  width: 29.22px;
  height: 29.22px;
  cursor: pointer;
`;

const OgImg = styled.div`
  position: absolute;
  bottom: -66px;
  z-index: 1;
  right: 0;
  img {
    width: 170px;
    height: 170px;
    border-radius: 8px;
    border: 12px solid ${({ theme }) => theme.colors["01Primary0"]};
    ${({ defaultAvatar }) => defaultAvatar && "object-fit: none"};
  }

  @media ${breakpoints.tablet} {
    position: relative;
    bottom: unset;
    right: unset;
    margin: auto 0 0 24px;
  }
  @media ${breakpoints.miniTablet} {
    margin: auto 0 0 52px;
  }
`;

export default OgBox;
