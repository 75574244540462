import { STEP_INDEXES } from ".";
import { writeModalConfig } from "../../hooks/useContinueModalFlow";
import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";
import Body from "../ConnectSocialMediasModal/ConnectStep";

const ConnectSocialProfilesStep = () => {
  const props = useSocialProfilesInfo();
  const onClick = () => writeModalConfig({ modalName: "ConnectSocialMediasModal" });

  return (
    <div onClick={onClick}>
      <Body {...props} showCount={false} />
    </div>
  );
};

export const Footer = ({ goToStep }) => {
  const override = () => goToStep(STEP_INDEXES.SearchForRewardStep);

  return <Body.Footer text="Search For Your Rewards" override={override} />;
};

ConnectSocialProfilesStep.$maxWidth = "680px";
ConnectSocialProfilesStep.Footer = Footer;
ConnectSocialProfilesStep.$overflowY = "auto";

export default ConnectSocialProfilesStep;
