import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { numberWithCommas } from "../../../utils/formatting";
import WalletCard from "./WalletCard";

const MainCard = ({ mainWallet }) => {
  return (
    <>
      <WalletCard
        key={mainWallet.id}
        {...mainWallet}
        balance={numberWithCommas(mainWallet.balance, "")}
        title="Login and Rewards Wallet"
      />
      <Box $gap="8px" $alignItems="flex-start" $spacing="mt-4" $flex>
        <IconSvg icon="circle-info-outline" $minWidth="16px" $color="03Primary500" />
        <StyledText $maxWidth="379px" $typography="body/small" $bold>
          Use this address to log in. This cannot be changed. Rewards will be sent to this wallet.
        </StyledText>
      </Box>
    </>
  );
};

const StyledText = styled(Text)`
  margin-top: -2px;
`;

export default MainCard;
