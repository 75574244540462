import rewardStepImage from "@assets/rewardStepImage.svg";
import { Box, breakpoints, IconSvg, ProgressBar, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import { trackEventCategory } from "../../hooks/useTrackEvents";

const DEFAULT_STATE = { message: "This can take up to one minute.", percent: 0 };
const TOTAL_DURATION = (60 * 1000) / 2;

const SearchForRewardsStep = ({ goToStep }) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const { getExternalCommunitiesRequest } = useStoreActions((actions) => actions.community);
  const { getTotalRewards } = useStoreActions((actions) => actions.user);
  const address = useStoreState((state) => state.user.user.username) || "";
  const { message, percent } = state;

  const getUserRewards = () => {
    getTotalRewards().then(({ total_amount }) => {
      const nextStep =
        parseFloat(total_amount) !== 0 ? STEP_INDEXES.RewardsFoundStep : STEP_INDEXES.RewardsNotFoundStep;
      goToStep(nextStep);
    });
  };

  useEffect(() => {
    trackEventCategory("U0_Signed_Up");

    let interval;
    getExternalCommunitiesRequest().then(({ communities }) => {
      const showTime = TOTAL_DURATION / communities.length;
      let index = 0;
      interval = setInterval(() => {
        if (index > communities.length) {
          clearInterval(interval);
          getUserRewards();
        } else {
          const name = communities[index++].name;
          const message = `Searching ${name} for contributions...`;
          const percent = (index / communities.length) * 100;
          setState({ message, percent });
        }
      }, showTime);
    });
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Text $typography="heading/large" $spacing="mb-4" $color="02Primary500">
        Searching for rewards
      </Text>
      <Text $typography="body/medium" $spacing="mb-6" $color="02Primary500">
        We are searching your contribution history to see if you’ve already completed any activities that earn you
        crypto rewards.
      </Text>
      <AddressProfile address={address} />
      <img src={rewardStepImage} />
      <Box $flex $justifyContent="center" $spacing="mt-6+2 mb-6">
        <Bar $height="6" $bgColor="#5FB974" percent={percent} />
      </Box>
      <Text $typography="body/medium" $spacing="mb-6+2" $color="02Primary500">
        {message}
      </Text>
    </Container>
  );
};

const AddressProfile = ({ address }) => {
  const leftAddress = address.substring(0, 5);
  const rightAddress = address.substring(address.length - 4, address.length);
  return (
    <Box $flex $alignItems="center" $gap="12px" $justifyContent="center" $spacing="mb-6">
      <Box
        $width="31px"
        $height="31px"
        $borderRadius="50%"
        $bgColor="hover"
        $flex
        $alignItems="center"
        $justifyContent="center"
      >
        <IconSvg icon="user-2" $width="12px" $color="accent500" />
      </Box>
      <Text $bold $size="15px" $color="02Primary500">
        {leftAddress}...{rightAddress}
      </Text>
    </Box>
  );
};

const Bar = styled(ProgressBar)`
  max-width: 241px;
`;

const Container = styled(Box)`
  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-self: center;
    width: 426px;
    max-width: 426px;
  }

  @media ${breakpoints.mobile} {
    padding: 40px 28px 0;
  }
`;

SearchForRewardsStep.$maxWidth = "684px";

export default SearchForRewardsStep;
