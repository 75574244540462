import Divider from "@common/Divider";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import StepNavigation from "../../common/StepNavigation";
import { CommunityEditorContext } from "../CommunityEditorContext";
import AboutSelectionStep from "./AboutSelectionStep";
import AdminsSelectionStep from "./AdminsSelectionStep/index";
import ButtonList from "./common/ButtonList";
import HeaderButtons from "./common/HeaderButtons";
import NameSelectionStep from "./NameSelectionStep/index";
import Tags from "./Tags";

const STEPS = [NameSelectionStep, AboutSelectionStep, Tags, AdminsSelectionStep];
const NAME_FIELDS = ["name", "slug"];

const STEP_HEADERS = [
  { label: "1. Name", num: 0 },
  { label: "2. About", num: 1 },
  { label: "3. Tags", num: 2 },
  { label: "4. Admins", num: 3 },
];

const Stepper = () => {
  const {
    communityValues,
    setState,
    onClose,
    handleSubmit,
    bannerColor,
    isPersisted,
    isDraft,
    previewMode,
    setPreviewMode,
    errorsValues,
    canSaveAsDraft,
    saveAsDraft,
    onChange,
    onClickPreview,
    ...props
  } = useContext(CommunityEditorContext);
  const [index, setStep] = useState(0);
  const [loading, setLoading] = useState(0);
  const { isOwnerOfCommunity } = useStoreState((state) => state.user);
  const { isDesktop, isMobile } = useStoreState((state) => state.media);
  const StepKlass = STEPS[index];
  const isOwner = isOwnerOfCommunity(communityValues.id);
  const steps = isPersisted && isOwner ? STEP_HEADERS : STEP_HEADERS.slice(0, -1);
  const maxStep = steps.length - 1;
  const title = previewMode ? "Community Page Preview" : isPersisted ? "Edit Community" : "Create Community";
  const textBtn = previewMode ? "Edit Community" : "Preview";
  const previewIcon = previewMode ? "chevron-up" : "chevron-down";
  const titleSpacing = previewMode ? "py-5" : "mb-6+2 pt-6";
  const hidePreview = isMobile || previewMode;
  const _canSaveDraft = canSaveAsDraft && !previewMode && !isMobile;

  const submit = (e) => {
    setPreviewMode(false);
    e.preventDefault();
    setLoading(true);
    handleSubmit(e)
      .catch((e) => setStep(0))
      .finally(() => setLoading(false));
  };

  const buttonsProps = {
    index,
    setStep,
    loading,
    maxStep,
    cancel: onClose,
    submit,
    isPersisted,
    isDraft,
    isDesktop,
  };

  const closeModal = previewMode ? onClickPreview : onClose;

  useEffect(() => {
    if (!!errorsValues && typeof errorsValues === "object") {
      for (const field of NAME_FIELDS) {
        if (Object.prototype.hasOwnProperty.call(errorsValues, field)) {
          setStep(0);
          break;
        }
      }
    }
  }, [errorsValues]);

  return (
    <>
      <Box>
        <StyledText $color="01Primary700" $typography="heading/medium" $spacing={titleSpacing}>
          {title}
        </StyledText>

        <HeaderButtons
          onClickPreview={onClickPreview}
          textBtn={textBtn}
          icon={previewIcon}
          onClose={closeModal}
          saveAsDraft={_canSaveDraft && saveAsDraft}
          hidePreview={hidePreview}
        />
        {!previewMode && <StepNavigation index={index} steps={steps} setStep={setStep} />}
      </Box>
      {!previewMode && (
        <>
          <StyledBox $spacing="mx-a" onChange={onChange}>
            <StepKlass
              communityValues={communityValues}
              setState={setState}
              errorsValues={errorsValues}
              isPersisted={isPersisted}
              bannerColor={bannerColor}
              {...props}
            />
            <Divider $sm-spacing="mx-4 my-5" />
          </StyledBox>
          <ButtonList {...buttonsProps} />
        </>
      )}
    </>
  );
};

const StyledText = styled(Text)`
  text-align: center;

  @media ${breakpoints.miniTablet} {
    max-width: 166px;
    margin: 0 auto;
    padding-top: 70px;
  }

  @media ${breakpoints.mobile} {
    padding-top: 0;
    margin: 30px 16px !important;
    text-align: unset;
  }
`;

const StyledBox = styled(Box)`
  width: 556px;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export default Stepper;
