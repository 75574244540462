import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { Link } from "react-router-dom";

const BreadCrumb = ({ community_id, community_name }) => {
  return (
    <Box $flex $gap="12px">
      <Link to="/manage/communities">
        <Text $bold $color="02Primary500" $pointer>
          Manage
        </Text>
      </Link>
      <IconSvg icon="chevron-right" />
      <Link to={`/manage/communities/${community_id}?tabIndex=1`}>
        <Text $bold $color="02Primary500" $pointer>
          {community_name}
        </Text>
      </Link>
      <IconSvg icon="chevron-right" />
      <Link to={`/manage/communities/${community_id}`}>
        <Text $bold $color="02Primary500" $pointer>
          Manage Seasons
        </Text>
      </Link>
    </Box>
  );
};

export default BreadCrumb;
