import { Box, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const AccordionButton = ({ expanded, toggle }) => (
  <StyledBox
    $pointer
    $flex
    $alignItems="center"
    $justifyContent="center"
    $bgColor="transparent"
    $minWidth="35px"
    $minHeight="35px"
    $borderRadius="50%"
    onClick={toggle}
  >
    <IconSvg icon={expanded ? "chevron-up" : "chevron-down"} $color="03Primary500" $width="20px" />
  </StyledBox>
);

const StyledBox = styled(Box)`
  border 1px solid ${({ theme }) => theme.colors["03Primary500"]};
`;

export default AccordionButton;
