import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { TabWrapper } from "../common";
import EmailNotifications from "./EmailNotifications";
import SocialNotifications from "./SocialNotifications";

const NotificationsSettings = () => {
  const { notificationSettings } = useStoreState((state) => state.user);
  const { updateNotificationSettings, getNotificationSettings } = useStoreActions((actions) => actions.user);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const [configs, setConfigs] = useState(notificationSettings);

  const toggleNotifications = (social) => {
    const newState = { ...configs };
    newState[social] = !newState[social];
    setConfigs(newState);
    updateNotificationSettings(newState)
      .then(() => {
        getNotificationSettings();
      })
      .catch(({ response }) => {
        const content = response.data.message || response.data.data.errors.name[0];
        dangerToast({ content, dismissTime: 5000 });
        setConfigs(notificationSettings);
      });
  };

  return (
    <Wrapper>
      <Box>
        <Title $typography="heading/large">Notifications Settings</Title>
        <Box $height="1px" $bgColor="01Primary100" />
        <Text $typography="heading/medium" $color="01Primary700" $spacing="pt-6+1 pb-5">
          Email
        </Text>
        <EmailNotifications enabled={configs?.email} toggle={() => toggleNotifications("email")} />
      </Box>
      <Box>
        <TopLine />
        <SocialNotifications
          socialName="Discord"
          enabled={configs?.discord}
          toggle={() => toggleNotifications("discord")}
        />

        <SocialNotifications
          socialName="Twitter"
          enabled={configs?.twitter}
          toggle={() => toggleNotifications("twitter")}
        />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(TabWrapper)`
  display: grid;
  grid-template-columns: 539px 539px;
  justify-content: space-between;

  @media ${breakpoints.belowDesktop} {
    grid-template-columns: 1fr;
  }
`;

const Title = styled(Text)`
  padding-bottom: 41px;
`;

const TopLine = styled(Box)`
  margin-bottom: 41px;
  height: 77px;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
  @media ${breakpoints.belowDesktop} {
    display: none;
  }
`;

export default NotificationsSettings;
