import { Box, Button, DropDown, Input, Text, TextArea } from "@thrivecoin/ui-components";
import styled from "styled-components";

const RestrictCommunityStep = ({ communities, community_id }) => {
  return (
    <StyledBox $flex $direction="column">
      <Text $typography="heading/small" $spacing="mb-5 mr-a" $bold>
        For which Community does this restriction apply?
      </Text>

      <Text $typography="body/medium" $spacing="mb-5 mr-a">
        Choose below for which community the user will be restricted, In case the user has shown bad behaviour in
        multiple communities, we suggest to ban the user. Note: Restrictions can be lifted by community admins as well.
      </Text>

      <DropDown
        value={community_id}
        name="community_id"
        label="name"
        valueKey="id"
        $color="02Primary200"
        $bgColor="02PrimaryGrey50"
        $optionsBgColor="02PrimaryGrey50"
        options={communities}
        required
      />
      <Box $spacing="mt-5" />
    </StyledBox>
  );
};

const Footer = ({ goBack }) => {
  return (
    <Box $flex $gap="16px">
      <Button variant="white" kind="outline" $borderRadius="50px" $width="100%" onClick={goBack} modal>
        Back
      </Button>
      <Button $borderRadius="50px" $width="100%" type="submit" modal>
        Next Step
      </Button>
    </Box>
  );
};

const StyledBox = styled(Box)`
  text-align: left;
`;

export const StyledTextArea = styled(TextArea)`
  textarea {
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  }
`;

RestrictCommunityStep.Footer = Footer;

export default RestrictCommunityStep;
