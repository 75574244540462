import InputGroup from "@common/InputGroup";

import { Box, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import FileInput from "../../common/FileInput";
import RichEditor from "../../common/RichEditor";
import { wrappedSelect } from "../../ListingEditor/common";
import { Line } from "./common";

const Body = ({ badgeValues, setBadgeValues, community_id, isPersisted }) => {
  const [selected, setSelected] = useState(badgeValues.community_id);
  const { getActiveCommunities } = useStoreActions((actions) => actions.community);
  const { activeCommunities } = useStoreState((state) => state.community);
  const options = activeCommunities.map(({ id, name }) => ({ value: id, label: name }));

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name) {
      setBadgeValues((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleChange = (name) => (value) => {
    setBadgeValues((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    getActiveCommunities();
  }, []);

  return (
    <Container>
      <form onChange={onChange} className="listing-form">
        <InputGroup field="name" label="Name (Required)" required defaultValue={badgeValues.name} />
        <Line />
        <Box>
          <FileInput
            label="Logo (Required)"
            subLabel="The logo for the badge will be displayed on public profiles and in the admin interface. Our recommended size is 56x56px (Maximum size 100x100px)"
            media={badgeValues}
            setMedia={setBadgeValues}
            name="logo_url"
            includeType={false}
          />
        </Box>
        <Line />

        {!community_id && (
          <>
            <InputGroup
              $boxSpacing="mb-3"
              $labelSpacing="mb-3"
              label="Community (Optional)"
              field="community_id"
              InputClass={wrappedSelect(options)}
              setSelected={setSelected}
              defaultValue={badgeValues.community_id ? badgeValues.community_id : selected}
              disabled={isPersisted}
            />
            <Text $typography="body/medium" $color="01Primary700">
              If no community is selected, a system badge will be generated automatically.
            </Text>
            <Line />
          </>
        )}
        <Text $bold $spacing="mb-4">
          Description (Required)
        </Text>
        <EditorWrapper $height="80px" $flex $direction="column" $spacing="mb-6">
          <RichEditor
            name="description"
            placeholder="Placeholder text for explaining the badge's purpose."
            value={badgeValues.description}
            onChange={handleChange("description")}
          />
        </EditorWrapper>
        <Line />
        <Text $bold $spacing="mb-4">
          Badge Requirements (Required)
        </Text>
        <EditorWrapper $height="128px" $flex $direction="column" $spacing="mb-6">
          <RichEditor
            name="requirements"
            placeholder="Placeholder text for how a user can earn the badge"
            value={badgeValues.requirements}
            onChange={handleChange("requirements")}
          />
        </EditorWrapper>
      </form>
    </Container>
  );
};

const Container = styled(Box)`
  padding: 0 48px;
  margin: auto;
  width: 555px;
  input {
    background-color: ${({ theme }) => theme.colors["01Primary50"]};
  }
`;

const EditorWrapper = styled(Box)`
  & > div {
    height: inherit;
    .quill {
      height: inherit;
    }
  }
`;
export default Body;
