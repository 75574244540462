import { Box, breakpoints, Input, Text } from "@thrivecoin/ui-components";
import getFormData from "@utils/getFormData";
import { useStoreActions } from "easy-peasy";
import { useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import { CornerLink, PaddedButton } from "../common";
import { arePasswordsInvalid } from "./CreateAccountStep";

const ResetPasswordStep = ({ goToStep }) => {
  const { token } = useParams();
  const { resetPasswordRequest } = useStoreActions((actions) => actions.authentication);
  const { showToast } = useStoreActions((actions) => actions.toasts);
  const goToSignIn = () => goToStep(STEP_INDEXES.SignInStep);
  const [validationText, setValidationText] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    const data = getFormData(event.currentTarget);
    const { password, passwordConfirm } = data;
    const passwordError = arePasswordsInvalid(password, passwordConfirm);

    if (passwordError) {
      setValidationText(passwordError);
      return;
    }

    resetPasswordRequest({ password, passwordConfirm, token })
      .then(() => {
        showToast({
          content: "Your password has been sucessfully reset",
          dismissTime: 5000,
        });
        goToStep(STEP_INDEXES.SignInStep);
      })
      .catch(({ response }) => {
        setValidationText(response.data.message);
        return;
      });
  };

  const onChange = () => {
    setValidationText("");
  };
  useVirtualPageView({ pathname: "/reset-password" });

  return <Page onSubmit={onSubmit} onChange={onChange} goToSignIn={goToSignIn} validationText={validationText} />;
};

const Page = ({ onSubmit, goToSignIn, validationText, onChange }) => (
  <>
    <CornerLink text="Sign In" onClick={goToSignIn} />
    <Container>
      <StyledHeader>Enter new password</StyledHeader>

      <form onSubmit={onSubmit} onChange={onChange}>
        <Box $spacing="mb-7">
          <Box $spacing="mb-6">
            <Input type="password" name="password" invalid={validationText} placeholder="New password" required />
          </Box>
          <Input
            type="password"
            name="passwordConfirm"
            invalid={validationText}
            placeholder="Confirm password"
            required
          />
          {validationText && (
            <Text $color="#f16d67" $align="left" $spacing="mt-2">
              {validationText}
            </Text>
          )}
        </Box>
        <PaddedButton $spacing="mb-4 " type="submit" modal={true}>
          SAVE
        </PaddedButton>
      </form>
    </Container>
  </>
);

const Container = styled(Box)`
  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-self: center;
    width: 426px;
    max-width: 426px;
  }
`;

const StyledHeader = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1f2029;
  margin-bottom: 40px;
`;
export default ResetPasswordStep;
