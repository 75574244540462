import { MainContainer } from "@common/MainContainer";
import TabWrapper from "@common/TabWrapper";
import { breakpoints, ListingCompactModular, ListingFilters } from "@thrivecoin/ui-components";
import styled from "styled-components";
import FilterTitle from "../../Season/BodySeason/WaysToContribute/FilterTitle";
import useTagFilters from "../../Season/BodySeason/WaysToContribute/useTagFilters";
import { dummyListing } from "./dummyListings";

const { ContributionTD, RewardTD, ConnectOrDetailsTD } = ListingCompactModular;

const DEFAULT_CELLS = [ContributionTD, RewardTD, ConnectOrDetailsTD];

const WaysToContributePreview = ({ data }) => {
  const filterProps = useTagFilters(null, () => {}, data.community_tags);

  const listings = new Array(3).fill(dummyListing);

  return (
    <TabWrapper>
      <MainContainer>
        <Container>
          <FilterTitle headingText="There are 3 ways to contribute to this community!" />
          <ListingFilters {...filterProps} onFilter={() => {}} />
          <Table>
            <tbody>
              {listings.map((listing, index) => {
                return (
                  <ListingCompactModular
                    $spacing="mb-5"
                    key={index}
                    {...listing}
                    reward={parseFloat(listing.reward_amount)}
                    earned={parseFloat(listing.rewards_earned)}
                    completedCount={listing.contributions_count || 0}
                    canCompleteMultipleTimes={listing.unlimited_contributions_per_user}
                    custom_url={listing.custom_url}
                    type={listing.contribution_app}
                    socialProfileConnected={false}
                    isAuthenticated={false}
                    onSignUp={() => {}}
                    communityName={data.name}
                    fieldClasses={[...DEFAULT_CELLS]}
                  />
                );
              })}
            </tbody>
          </Table>
        </Container>
      </MainContainer>
    </TabWrapper>
  );
};

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.tablet} {
    max-width: calc(100vw - 64px);
    & > div {
      max-width: calc(100vw - 64px);
    }
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 0 24px;
  }
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  z-index: 9;
  tbody:before {
    content: ".";
    display: block;
    line-height: 32px;
    text-indent: -99999px;
  }

  & > tbody > tr > td:last-child {
    width: 100%;
  }
`;

export default WaysToContributePreview;
