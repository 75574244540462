import { Button } from "@thrivecoin/ui-components";

export const CONFIG = {
  twitter: { $color: "02Primary0", rightIcon: "twitter" },
  discord: { rightIcon: "discord", $color: "02Primary0" },
  discourse: { $color: "02Primary0", rightIcon: "discourse" },
  email: { $color: "02Primary0", rightIcon: "mail-filled" },
  github: { $bgColor: "black", $color: "02Primary0", rightIcon: "github" },
  asana: { $color: "02Primary0", rightIcon: "asana" },
  zoom: { $color: "02Primary0", rightIcon: "zoom" },
  linkedin: { $color: "02Primary0", rightIcon: "linkedin" },
  gitcoin: { $color: "02Primary0", rightIcon: "gitcoin" },
  aavegotchi: { $color: "02Primary0", rightIcon: "discourse", $bgColor: "discourse" },
};

const CONNECTED_PROPS = { $color: "hover", leftIcon: "check-circle-filled", $bgColor: "03Primary150" };

const SocialButton = ({ isConnected, network, children, ...props }) => {
  const config = CONFIG[network] || {};
  const connectedProps = isConnected ? CONNECTED_PROPS : {};
  //TODO: seems like the colors are changing again.
  // const $bgColor = isConnected ? "03Primary150" : "02Primary500";
  const $bgColor = isConnected ? "03Primary150" : "#81C394";

  return (
    <Button
      $bold
      $typography="body/medium"
      $borderRadius="40px"
      $color="hover"
      kind="socials"
      $bgColor={$bgColor}
      {...config}
      {...connectedProps}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SocialButton;
