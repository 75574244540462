import { Box, breakpoints, Button, IconSvg, LinkWrapper, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

export const Link = LinkWrapper(RouterLink);

export const Header = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 2.5em;
  text-align: center;
`;

export const PaddedButton = styled(Button)`
  border-radius: 50px;
  padding: 1.25em;
  width: 100%;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

PaddedButton.defaultProps = { variant: "success500" };

export const _GoogleButton = styled(PaddedButton)`
  box-shadow: 0px 2px 6px rgb(0 0 0 / 19%);
  color: #1f2029;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotAMemberFooter = () => (
  <Text $align="center">
    Not a member?
    <Link to=""> Create an account</Link>
  </Text>
);

export const useSkipOnboarding = () => {
  const { setOnboardingStatus } = useStoreActions((actions) => actions.authentication);

  return () => {
    setOnboardingStatus(false);
    localStorage.removeItem("onboarding");
  };
};

const GoBackContainer = styled(Box)`
  position: absolute;
  left: 36px;
  top: 37px;

  @media ${breakpoints.tablet} {
    position: static;
    top: 0;
    left: 0;
    padding: 0 2rem 1.5rem;
    alignitems: center;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  }
  @media ${breakpoints.mobile} {
    position: static;
    top: 0;
    left: 0;
    padding: 0 0 20px 18px;
    alignitems: center;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  }
`;

export const CornerLink = ({ text, onClick }) => {
  const navigate = useNavigate();
  const goBack = () => navigate("/");
  const _onClick = onClick || goBack;
  const { isTablet, isDesktop } = useStoreState((state) => state.media);

  return (
    <GoBackContainer>
      <Box onClick={_onClick} $flex $alignItems="center" $pointer $hoverUnderline>
        <IconSvg icon="arrow-left" $width={!isDesktop ? "18px" : "24px"} />{" "}
        <Text $size={isTablet && "15px"} $semiBold={isTablet} $spacing="ml-2">
          {text}
        </Text>
      </Box>
    </GoBackContainer>
  );
};

CornerLink.defaultProps = {
  text: "Back",
  onClick: () => {},
};

export const MODAL_CONFIG = {
  regular: { $maxWidth: "680px" },
};
