import PlanesSvg from "@assets/paper-planes.svg";
import { Box, breakpoints, Input, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import { CornerLink, PaddedButton } from "../common";

const ERROR_MESSAGE = "We could not find an account matching that email address.";

const ForgotPasswordStep = ({ goToStep }) => {
  const [email, setEmail] = useState();
  const [errorMessage, setError] = useState();
  const [showResend, setShowResend] = useState(true);
  const { passwordRecoveryRequest } = useStoreActions((actions) => actions.authentication);
  const goToSignIn = () => goToStep(STEP_INDEXES.SignInStep);
  useVirtualPageView({ pathname: "/forgot-password" });
  const { isDesktop } = useStoreState((state) => state.media);

  const onSubmit = (event) => {
    event.preventDefault();
    const email = new FormData(event.currentTarget).get("email");
    sendInstructions(email);
  };

  const sendInstructions = (email) => {
    passwordRecoveryRequest(email)
      .then((data) => {
        setEmail(email);
      })
      .catch(() => setError(ERROR_MESSAGE));
  };

  const reSendInstructions = () => {
    setShowResend(false);
    sendInstructions(email);
  };

  const Component = email ? Page2 : Page;

  return (
    <>
      {!isDesktop && <CornerLink onClick={goToSignIn} text="Sign In" />}
      <Component
        email={email}
        onSubmit={onSubmit}
        showResend={showResend}
        onClick={reSendInstructions}
        errorMessage={errorMessage}
      />
      {isDesktop && <CornerLink onClick={goToSignIn} text="Sign In" />}
    </>
  );
};

const Page = ({ email, onSubmit, errorMessage }) => {
  const { isMobile } = useStoreState((state) => state.media);
  return (
    <Container>
      <StyledHeader>Forgot password</StyledHeader>
      <form onSubmit={onSubmit}>
        <Text $spacing={!isMobile ? "mb-6 px-4" : "mb-6"} $lHeight="1.5em">
          Enter the email address you used to join ThriveCoin and we’ll send you an email with instructions to reset
          your password.
        </Text>
        <Input
          name="email"
          type="email"
          placeholder="Email"
          value={email}
          invalid={errorMessage}
          validationText={errorMessage}
          required
        />
        <PaddedButton $spacing={!isMobile ? "my-4" : "my-6"} type="submit" modal={true}>
          Send Instructions
        </PaddedButton>
      </form>
    </Container>
  );
};

const Page2 = ({ email, onClick, showResend }) => {
  useVirtualPageView({ page: "/sign-in/instructions-sent" });

  return (
    <BackgroundBox>
      <StyledImage src={PlanesSvg}></StyledImage>
      <Text $bold $size="28px" $spacing="mb-5" font="Outfit">
        Instructions sent
      </Text>
      <StyledText $spacing="mb-6">
        We sent instructions on how to reset your password to <b>{email}</b>. Didn’t receive an email? Please check your
        spam folder or for any typos.
      </StyledText>

      {showResend && (
        <Text $pointer $color="#5159C9" $spacing="my-4" onClick={onClick} $bold>
          Send instructions again
        </Text>
      )}
    </BackgroundBox>
  );
};

const Container = styled(Box)`
  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 60px;
    max-width: 486px;
  }
  @media ${breakpoints.mobile} {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 50px;
    padding: 16px;
    text-align: left;
  }
`;

const BackgroundBox = styled.div`
  position: relative;
  height: 351px;

  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 60px;
    max-width: 595px;
    height: auto;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  pointer-events: none;
  left: 105px;
  bottom: 20px;

  @media ${breakpoints.tablet} {
    bottom: unset;
  }
`;

const StyledText = styled(Text)`
  width: 554px;
`;
const StyledHeader = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1f2029;
  margin-bottom: 24px;
  @media ${breakpoints.mobile} {
    text-align: left;
  }
`;

export default ForgotPasswordStep;
