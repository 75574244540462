import HeaderElement from "@common/HeaderElement";
import { Button, getTypography, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { HeaderCheckbox } from "../HeaderCheckbox";
import ListingRow from "./ListingRow";

export const ListingsTable = styled(
  ({
    listings,
    showStatus,
    showRewards,
    className,
    listingProps,
    rowClass,
    selectedIDs,
    selectAll,
    children,
    RowClass,
  }) => {
    const rows = listings.map((listing) => (
      <RowClass
        {...listing}
        {...listingProps}
        key={listing.id}
        reward={listing.reward_amount}
        className={rowClass}
        selected={selectedIDs.includes(listing.id)}
      />
    ));

    const onSort = () => {};

    return (
      <table className={className}>
        <thead>
          <tr>
            <th>
              <HeaderCheckbox selectAll={selectAll}>{children}</HeaderCheckbox>
            </th>
            <HeaderElement
              text={
                <>
                  <IconSvg icon="globe-not-filled" $width="16px" $spacing="ml-1 mr-5+2" />
                  Contribution
                </>
              }
              name="contributor"
              onSort={onSort}
            />
            {showRewards && (
              <>
                <th>Reward</th>
                <th>Max Rewards</th>
              </>
            )}
            {showStatus && <th>Status</th>}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
)`
  width: 100%;
  border-spacing: 0;
  text-align: left;
  thead th {
    ${getTypography("body/small")}
  }

  tbody {
    td[data-limit="true"] {
      color: ${({ theme }) => theme.colors["error2"]};
    }

    tr[data-selected="true"] {
      background: ${({ theme }) => theme.colors["manageCard3"]};
    }

    tr {
      background: ${({ theme }) => theme.colors["01Primary0"]};
    }

    tr:first-child {
      td:first-child {
        border-top-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    tr:not(:last-child) td {
      border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
    }
  }

  td,
  th {
    padding: 16px;
    padding-bottom: 14px;
  }

  tr td:first-child, tr th:first-child {
    padding-right: 0px;
  }

  tr td:nth-child(2), tr th:nth-child(2){
    padding-left: 0px;
  }

  th {
    white-space: nowrap;
  }

  th:first-child {
    width: 0%;
  }

  img {
    width: 26px;
    height: 26px;
    vertical-align: top;
  }

  ${Button} {
    white-space: nowrap;
  }
`;

ListingsTable.defaultProps = {
  listings: [],
  selectedIDs: [],
  listingProps: {},
  RowClass: ListingRow,
};

export default ListingsTable;
