import { MarkdownText } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";

const RaffleRules = () => {
  const { raffle } = useStoreState((state) => state.raffle);

  return (
    <>
      <MarkdownText $color="01Primary700">{raffle.rules}</MarkdownText>
    </>
  );
};

export default RaffleRules;
