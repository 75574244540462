import { Box, breakpoints, Button, DropDown, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";

import GOLD_HAND from "@assets/gold_hand.svg";

const DonateModal = ({ hideModal, donated_to, donation_options, earnings_status, rewards_token, seasonId }) => {
  const initSelection = donated_to ? donation_options.find((org) => org.id === donated_to) : donation_options[0];
  const [selectedOrg, setSelectedOrg] = useState(initSelection);
  const [loading, setLoading] = useState(false);
  const { isMobile, isMiniTablet, isDesktop, isTablet } = useStoreState((state) => state.media);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { donateEarnings, getSeasonDetails } = useStoreActions((actions) => actions.season);
  const largeDevice = isDesktop || (isTablet && !isMiniTablet);
  const modalRadius = isMobile ? "12px 12px 0 0 !important" : "12px !important";

  const onOrgChange = (selected) => {
    setSelectedOrg(selected);
  };

  const onCancel = () => {
    showModal({ modalName: "CancelDonationModal", seasonId });
  };

  const donate = () => {
    setLoading(true);
    donateEarnings({ seasonId, to: selectedOrg.id })
      .then(() => {
        getSeasonDetails(seasonId);
        hideModal();
      })
      .catch(({ response }) => {
        setLoading(false);
        const content = response.data.message || response.data.data.errors.name[0];
        dangerToast({ content, dismissTime: 5000 });
      });
  };

  const Footer = () => (
    <ButtonWrapper>
      {earnings_status === "pending_donation" && donated_to === selectedOrg.id ? (
        <Button
          $bold
          $borderRadius="40px"
          $width="100%"
          kind="outline"
          variant="error"
          $color="02Primary500"
          onClick={onCancel}
          modal={true}
        >
          Cancel my Donation
        </Button>
      ) : (
        <Button
          $bold
          loading={loading}
          $borderRadius="40px"
          $width="100%"
          variant="03Primary500"
          $color="navigation"
          onClick={donate}
          modal={true}
        >
          {donated_to ? "Update my selection" : "Donate My Earnings"}
        </Button>
      )}
    </ButtonWrapper>
  );

  return (
    <StyledModal open buttonCircle onClose={hideModal} $borderRadius={modalRadius} $paddingBody={isMobile && "0"}>
      <Content $color="02Primary500">
        <Text $alignSelf="center" $typography="heading/large" $spacing="mb-5">
          Donate your Earnings
        </Text>
        <Text $typography="heading/small" $alignSelf="center" $spacing="mb-5">
          (No Gas Fee!)
        </Text>
        <Text $textAlign="center" $spacing="mb-5" $sm-spacing="mb-4+1">
          Select a charity below and click “Donate Here”. At the end of the season {rewards_token?.toUpperCase()} you
          earned through your contributions will be automatically sent to the selected charity.
        </Text>
        <BottomContent $flex>
          <StyledBox $flex $direction="column">
            <Box $spacing="mb-a">
              <DropDown
                $bgColor="grey150"
                $borderColor="grey150"
                $color="black"
                $optionsBgColor="grey150"
                $hoverColor="grey300"
                autocomplete={false}
                label="name"
                value={selectedOrg.id}
                valueKey="id"
                options={donation_options}
                onChange={onOrgChange}
                $bold
              />
            </Box>
            {largeDevice && <HandImg src={GOLD_HAND} />}
          </StyledBox>
          <StyledBox $flex $direction="column">
            <Logo src={selectedOrg.image_url} />
            <Text $spacing="mb-5" $sm-spacing="mx-4">
              {selectedOrg.description}{" "}
              <StyledAnchor href={selectedOrg.url} target="_blank" rel="noreferrer">
                <StyledSpan $extraBold $color="link">
                  Learn more
                </StyledSpan>
              </StyledAnchor>
            </Text>
            <Footer />
          </StyledBox>
        </BottomContent>
      </Content>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  @media ${breakpoints.tablet} {
    align-items: center;
  }

  & > div {
    width: 684px;

    @media ${breakpoints.miniTablet} {
      width: 569px;
    }

    @media ${breakpoints.mobile} {
      width: 100%;
      max-height: calc(100vh - 100px);

      & > div:first-child {
        flex: 1;
      }
    }
  }
`;

const Content = styled(Box)`
  padding: 64px 36px 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    padding: 0 24px 0;
  }

  @media ${breakpoints.mobile} {
    padding: 64px 1rem 44px;
  }
`;

const ButtonWrapper = styled(Box)`
  padding-bottom: 60px;

  @media ${breakpoints.mobile} {
    padding-bottom: 32px;
    border-bottom: 1px solid #dadada;
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
`;

const StyledSpan = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.link};
`;

const BottomContent = styled(Box)`
  gap: 50px;

  @media ${breakpoints.miniTablet} {
    gap: 25px;
     flex-direction: column;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 25px;
  }
`;

const Logo = styled.img`
  max-height: 60px;
  margin-bottom: 24px;
  object-fit: contain;
  align-self: flex-start;

  @media ${breakpoints.mobile} {
    align-self: center;
  }
`;

const StyledBox = styled(Box)`
  flex: 1;
`;

const HandImg = styled.img`
  width: 200px;
  margin: 20px auto 0;
`;

DonateModal.defaultProps = {
  donation_options: [{}],
};

export default DonateModal;
