import EmptyImg from "@assets/empty-result.svg";
import { Box } from "@thrivecoin/ui-components";
import styled from "styled-components";

const EmptySearch = () => (
  <Container $flex $direction="column">
    <StyledImage src={EmptyImg} />
  </Container>
);

const Container = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary700"]};
`;

const StyledImage = styled.img`
  max-width: 325px;
  margin 40px auto;
`;

export default EmptySearch;
