import { HeaderText, IconText, TableHead, TableHeader } from "@common/TableElements";
import { IconSvg } from "@thrivecoin/ui-components";

const THeader = ({
  onSort,
  isMobile,
  isDesktop,
  sortType,
  sortIcon,
  isSeason,
  isTablet,
  isDashboard,
  isCustomToken,
  isMiniTablet,
}) => {
  return (
    <TableHead onClick={onSort} $display={isMobile && "none"}>
      <tr>
        <TableHeader $width="362px" $textAlign="center">
          <IconText>
            <IconSvg $width="15px" icon="globe-not-filled" $color="01Primary700" $spacing="mx-a" />
            {sortType === "network" && <IconSvg data-name="network" $width="7.5px" icon={sortIcon} />}

            <HeaderText data-name="contribution" $typography="body/small" $color="01Primary700" $isSorted $bold>
              Contribution
              {sortType === "contribution" && <IconSvg $width="7.5px" icon={sortIcon} />}
            </HeaderText>
          </IconText>
        </TableHeader>
        <TableHeader $width="198px">
          <HeaderText data-name="reward" $typography="body/small" $color="01Primary700" $isSorted $bold>
            {isCustomToken && isSeason ? "End of Season Prize" : "Reward"}
            {sortType === "reward" && <IconSvg $width="7.5px" icon={sortIcon} />}
          </HeaderText>
        </TableHeader>
        <TableHeader
          $display={(!isDesktop || !isDashboard) && "none"}
          $minWidth={isTablet ? "180px" : "100px"}
          $width="172px"
        >
          <HeaderText $typography="body/small" $color="01Primary700" $bold>
            Community{" "}
          </HeaderText>
        </TableHeader>
        <TableHeader
          $display={(isMiniTablet || isDashboard) && "none"}
          $minWidth={isTablet ? "180px" : "145px"}
          $width={isDesktop && "172px"}
        >
          <HeaderText $typography="body/small" $color="01Primary700" $bold>
            Completions{" "}
          </HeaderText>
        </TableHeader>
        <TableHeader
          $display={isMobile || (((isTablet && !isDashboard) || isMiniTablet) && "none")}
          $width={isDesktop && "166px"}
        >
          <HeaderText $typography="body/small" $color="01Primary700" $bold>
            Can Be Completed
          </HeaderText>
        </TableHeader>
        <TableHeader $display={isTablet && "none"}>
          <HeaderText data-name="verification_method" $typography="body/small" $color="01Primary700" $isSorted $bold>
            Completion Check {sortType === "verification_method" && <IconSvg $width="7.5px" icon={sortIcon} />}
          </HeaderText>
        </TableHeader>
        <TableHeader></TableHeader>
      </tr>
    </TableHead>
  );
};

export default THeader;
