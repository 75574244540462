import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { VALID_SOCIAL_NETWORKS } from "../../../../constants";
import ConnectMultiple from "./ConnectMultiple";
import FullyConnected from "./FullyConnected";
import ProgressSide from "./ProgressSide";

const MyConnectedNetworks = ({
  connectNetworks,
  connectionsPercent,
  countByApp,
  connectedNetworks,
  connectionsCount,
  isMobile,
}) => {
  const validCount = VALID_SOCIAL_NETWORKS.length;
  let ConnectedNetworks = null;
  if (connectionsPercent == 100) {
    ConnectedNetworks = FullyConnected;
  } else {
    ConnectedNetworks = ConnectMultiple;
  }

  return (
    <Container>
      <Text $typography="body/small" $color="02Primary500" $extraBold>
        NETWORK CONNECTIONS
      </Text>
      <ProgressSide connectionsPercent={connectionsPercent} />
      <ConnectedNetworks
        connectNetworks={connectNetworks}
        countByApp={countByApp}
        connectedNetworks={connectedNetworks}
        connectionsCount={connectionsCount}
        validCount={validCount}
      />
      {isMobile && <Box $height="1px" $bgColor="02Primary0" />}
    </Container>
  );
};
const Container = styled(Box)`
  min-width: 378px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 38px;
  align-items: center;
  @media ${breakpoints.tablet} {
    gap: 16px;
    grid-template-columns: 324px 243px;
  }
  @media ${breakpoints.miniTablet} {
    gap: 16px;
    grid-template-columns: 312px auto;
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    min-width: unset;
    gap: 16px;

    button {
      grid-row-start: 4;
    }
  }
`;

export default MyConnectedNetworks;
