import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";

export const STATUS_FILTERS = [
  { value: "Active", label: "Active", $activeColor: "03Primary500" },
  { value: "Draft", label: "Draft", $activeColor: "01Secondary500" },
  { value: "Archived", label: "Archived", $activeColor: "02Primary200" },
];

const useManagePage = (initialStatus = STATUS_FILTERS[0].value) => {
  const { getManagedCommunities } = useStoreActions((actions) => actions.community);
  const { managedCommunities } = useStoreState((state) => state.community);

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(initialStatus);

  const onStatusChange = ({ value }) => setStatus(value);

  const refresh = () => {
    setLoading(true);
    const formatted = (Array.isArray(status) ? status : [status]).map((x) => x.toLowerCase());
    getManagedCommunities(formatted).finally(() => setLoading(false));
  };

  useEffect(() => {
    refresh();
  }, [status]);

  return { managedCommunities, loading, status, onStatusChange, refresh };
};

export default useManagePage;
