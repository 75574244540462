import { uniqueArray } from "@utils/array";
import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import { nestedByID, normalizeCollectionGen } from "./common";

const requester = new HttpWrapper("badges");

const attributes = {
  user_badges: [],
  userBadgesIDs: {},
  pagination: {},
};

const computed = {
  byBadgeID: nestedByID("userBadge", "userBadgesIDs"),
};

const actions = {
  normalize: normalizeCollectionGen("userBadge"),
  getUsersByBadgeRequest: thunk(
    ({ normalize, setUserBadgesIDs, setPagination }, { id, keyword, page, sort_type, sort_direction }) => {
      const url = `${id}/user_badges`;
      return requester.get(url, { keyword, page, sort_type, sort_direction }).then(({ user_badges, pagination }) => {
        const ids = normalize(user_badges);
        setUserBadgesIDs({ id, ids, override: page == 1 });
        setPagination(pagination);
      });
    }
  ),
  removeUsersRequest: thunk(({ removeUserBadgeIDs }, { id, user_badge_ids }) => {
    const url = `/${id}/user_badges`;

    return requester.delete(url, { user_badge_ids }).then(() => {
      removeUserBadgeIDs({ id, user_badge_ids });
    });
  }),
  bulkAssignRequest: thunk((_, { id, identifiers }) => {
    const url = `${id}/bulk_assign`;

    return requester.post(url, { identifiers });
  }),
  bulkAssignCSVRequest: thunk((_, { id, csv }) => {
    const url = `${id}/bulk_assign_from_csv`;
    const data = new FormData();
    data.append("id", id);
    data.append("csv", csv);

    return requester.post(url, data);
  }),
  setUserBadgesIDs: action((state, { id, ids, override }) => {
    const oldIds = state.userBadgesIDs[id] || [];
    state.userBadgesIDs[id] = override ? ids : uniqueArray([...oldIds, ...ids]);
  }),
  removeUserBadgeIDs: action((state, { id, user_badge_ids }) => {
    const ids = (state.userBadgesIDs[id] || []).filter((id) => !user_badge_ids.includes(id + ""));
    state.userBadgesIDs[id] = ids;
  }),
  setPagination: action((state, pagination) => {
    state.pagination = pagination;
  }),
};

const userBadge = {
  ...attributes,
  ...computed,
  ...actions,
};

export default userBadge;
