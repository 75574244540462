import HeaderElement from "@common/HeaderElement";
import { breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";

const Headers = ({ onSort, sortType, sortDirection }) => {
  return (
    <thead>
      <Row>
        <HeaderElement
          width="40%"
          text="Contribution"
          name="id"
          onSort={onSort}
          sort={sortType === "id" ? sortDirection : null}
        />

        <HeaderElement
          text="Contributor"
          name="contributor"
          onSort={onSort}
          sort={sortType === "contributor" ? sortDirection : null}
        />

        <HeaderElement
          text="Reward"
          name="reward"
          onSort={onSort}
          sort={sortType === "reward" ? sortDirection : null}
        />

        <HeaderElement text="Date" name="date" onSort={onSort} sort={sortType === "date" ? sortDirection : null} />
      </Row>
    </thead>
  );
};

const Row = styled.tr`
  th {
    @media ${breakpoints.belowDesktop} {
      padding-top: 12px;
      padding-bottom: 14.57px;
    }
  }
`;

export default Headers;
