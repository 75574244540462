import HeaderElement from "@common/HeaderElement";
import { HeaderCheckbox } from "./HeaderCheckbox";

const Headers = ({ onSort, sortType, sortDirection, selectAll, allSelected }) => {
  return (
    <thead>
      <tr>
        <th>
          <HeaderCheckbox selectAll={selectAll} allSelected={allSelected} />
        </th>
        <HeaderElement
          text="Contributor"
          name="contributor"
          sort={sortType === "contributor" ? sortDirection : null}
          onSort={onSort}
        />
        <HeaderElement
          text="Added Date"
          name="created_at"
          sort={sortType === "created_at" ? sortDirection : null}
          onSort={onSort}
        />
        <HeaderElement text="State" name="state" sort={sortType === "state" ? sortDirection : null} onSort={onSort} />
        <th></th>
      </tr>
    </thead>
  );
};

export default Headers;
