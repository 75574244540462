import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const FullyConnected = () => {
  return (
    <Wrapper $flex $gap="8px" $alignItems="center">
      <StyledIcon icon="success-check" />
      <Text $color="02Primary500" $typography="body/medium" $bold>
        All networks connected!
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  justify-content: center;
  @media ${breakpoints.belowDesktop} {
    justify-content: flex-start;
  }
`;
const StyledIcon = styled(IconSvg)`
  circle {
    fill: ${({ theme }) => theme.colors["02Primary500"]}};
  }
`;

export default FullyConnected;
