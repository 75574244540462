import HeaderElement from "@common/HeaderElement";
import { breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ContributionsHeaderRow = ({
  onSort,
  sortType,
  sortDirection,
  isCustomToken,
  isDesktop,
  showCommunity,
  isFromProfile,
  fromProfileTablet,
}) => {
  return (
    <thead>
      <Row>
        {!isFromProfile && (
          <HeaderElement
            text="Contributor"
            name="contributor"
            onSort={onSort}
            sort={sortType === "contributor" ? sortDirection : null}
          />
        )}
        <HeaderElement
          text="Contribution"
          $firstElement
          name="title"
          onSort={onSort}
          sort={sortType === "title" ? sortDirection : null}
        />
        {showCommunity && <HeaderElement text="Community" $padding={fromProfileTablet && "0"} />}
        <HeaderElement text="Season" />
        <HeaderElement
          text={isCustomToken ? "End of Season Prize" : "Reward"}
          name="reward"
          onSort={onSort}
          sort={sortType === "reward" ? sortDirection : null}
        />
        <HeaderElement
          text="Date"
          name="date"
          onSort={onSort}
          $minWidth={fromProfileTablet ? "96px" : "112px"}
          $padding={fromProfileTablet && "12px 24px 12px 0"}
          sort={sortType === "date" ? sortDirection : null}
        />
        {isDesktop && <HeaderElement $minWidth="215px" text="" />}
      </Row>
    </thead>
  );
};

const Row = styled.tr`
  th {
    @media ${breakpoints.belowDesktop} {
      padding-top: 12px;
      padding-bottom: 14.57px;
    }
  }
`;

export default ContributionsHeaderRow;
