import GoldMedal from "@assets/raffle/gold-medal.svg";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const EmptyEntries = ({ onKeepContributing, onRaffleDetails }) => (
  <CenteredContainer $flex $direction="column" $spacing="pt-2" $maxWidth="929px" $align="center" $alignItems="center">
    <Text $color="01Primary700" $typography="heading/medium" $spacing="mb-5" $bold>
      No Raffle Entries This Time
    </Text>
    <Text $color="01Primary700" $typography="body/medium" $spacing="mb-5" $bold>
      You didn’t earn any raffle entries this Season. Never fear -- even though this Season is ended, it’s not too late
      to keep contributing!
    </Text>
    <Text $color="01Primary700" $typography="body/medium">
      Every 5 $THRIVE you earn through your contributions during the Season earns you 1 Raffle Entry. During the Raffle,
      one entry will be randomly selected for each prize.
    </Text>

    <GroupButtons $gap="24px" $flex>
      <Button
        $bold
        onClick={onKeepContributing}
        kind="outline"
        $size="lg"
        $color="01Primary700"
        $height="56px"
        $borderRadius="40px"
      >
        Explore New Seasons
      </Button>
      {onRaffleDetails && (
        <Button
          $bold
          onClick={onRaffleDetails}
          $size="lg"
          $color="01Primary0"
          $height="56px"
          $borderRadius="40px"
          $bgColor="hover"
          rightIcon="arrow-up-right"
        >
          See Raffle Details
        </Button>
      )}
    </GroupButtons>
    <StyledImage $width="189px" src={GoldMedal} />
  </CenteredContainer>
);

const CenteredContainer = styled(Box)`
  margin: 0 auto;
`;

const StyledImage = styled.img`
  margin-top: 80px;
`;

const GroupButtons = styled(Box)`
  margin-top: 52px;
`;
export default EmptyEntries;
