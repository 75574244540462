import { Box, Button, Input, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components";
import { CONDITIONS } from "../../../modals/AddListingConditionModal";
import { IntegerInput, Select } from "../../common";

export const VERIFICATION_MAGNITUDE = ["Min(s)", "Hour(s)", "Week(s)", "Day(s)", "Year(s)"];

const optionMapper = (value) => (
  <option key={value} value={value}>
    {value}
  </option>
);

const useConditions = ({ setState, conditionFields }) => {
  const { showModal } = useStoreActions((actions) => actions.modals);

  const removeCondition = (event) => {
    const { value } = event.target.dataset;
    const newConditionFields = conditionFields.filter((key) => key !== value);
    setState((state) => ({ ...state, conditionFields: newConditionFields }));
  };

  const addCondition = () => {
    const addConditions = (conditionFields) => setState((state) => ({ ...state, conditionFields }));
    showModal({ modalName: "AddListingConditionModal", conditionFields, addConditions });
  };

  return {
    addCondition,
    conditionFields,
    removeCondition,
    setState,
  };
};

export const ConditionsForm = ({ verification_value, verification_magnitude, ...props }) => {
  const { addCondition, conditionFields, removeCondition } = useConditions(props);
  const conditionElements = conditionFields.map((key) => (
    <ConditionElement fieldName={key} key={key} remove={removeCondition} value={props.conditions[key]} />
  ));

  return (
    <Box $color="01Primary700">
      {conditionElements}
      <Button
        onClick={addCondition}
        $bold
        $borderRadius="50px"
        $width="100%"
        $spacing="mb-6"
        kind="outline"
        $color="01Primary700"
        variant="03Primary400"
      >
        Add Condition(s){"  +"}
      </Button>
      <Text $bold $spacing="mb-4">
        Verification speed (Required):{" "}
      </Text>
      <Box className="verification" $flex $gap="1rem" $alignItems="center">
        <Text>Within</Text>
        <IntegerInput $grow="18" name="verification_value" max="60" defaultValue={verification_value} />
        <Select
          $grow="1"
          name="verification_magnitude"
          defaultValue={verification_magnitude}
          key={verification_magnitude}
        >
          {VERIFICATION_MAGNITUDE.map(optionMapper)}
        </Select>
      </Box>
    </Box>
  );
};

const trim = (str) => {
  let colonIndex = str.indexOf(":");
  colonIndex = colonIndex > 0 ? colonIndex : str.length;
  return str.substring(0, colonIndex);
};

const TEXT = Object.keys(CONDITIONS).reduce((result, key) => {
  result[key] = trim(CONDITIONS[key].text);
  return result;
}, {});

const ConditionElement = ({ fieldName, remove, value }) => {
  const { user } = useStoreState((state) => state.user);
  const isEmbeddedCode = fieldName === "embedding_personal_code";
  const _value = isEmbeddedCode ? user.verification_code : value;
  return (
    <ConditionBox $spacing="mb-4 p-4">
      <Text>{TEXT[fieldName]} </Text>
      <Text className="close" onClick={remove} data-value={fieldName}>
        X
      </Text>
      <Input
        name={fieldName}
        data-conditionField={true}
        defaultValue={_value}
        $spacing="mt-3"
        disabled={isEmbeddedCode}
      />
    </ConditionBox>
  );
};

const ConditionBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  border-radius: 12px;
  position: relative;

  .close {
    position: absolute;
    top: 8px;
    right: 14px;
  }
`;
