import { Box, ContextMenu, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const SeasonListingOptions = ({ id, seasonName, removeFromSeason }) => {
  return (
    <IconContextMenu $circle={false}>
      <ul>
        <li>Edit</li>
        <li data-id={id} onClick={removeFromSeason}>
          Remove from {seasonName}
        </li>
      </ul>
    </IconContextMenu>
  );
};

export const CommunityListingOptions = ({ id, editListing }) => {
  return (
    <IconContextMenu $circle={false}>
      <ul>
        <li onClick={editListing} data-id={id}>
          Edit
        </li>
      </ul>
    </IconContextMenu>
  );
};

export const IconContextMenu = ({ children, icon, $circle, ...props }) => {
  const ContextButton = $circle ? CircleButton : PlainButton;
  return (
    <ContextMenu icon={icon} ContextButton={ContextButton} {...props}>
      {children}
    </ContextMenu>
  );
};

const CircleButton = ({ onClick, rightIcon, Container = StyledBox }) => {
  return (
    <Container $spacing="p-2" $flex onClick={onClick}>
      <IconSvg icon={rightIcon} $width="15px" />
    </Container>
  );
};

const PlainButton = (props) => <CircleButton {...props} Container={Box} />;

IconContextMenu.defaultProps = {
  icon: "more-vertical",
  $circle: true,
};

const StyledBox = styled(Box)`
  border: 2px solid ${({ theme }) => theme.colors["01Primary700"]};
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
`;
