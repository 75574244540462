import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import UsernameAvatar from "../../../common/UsernameAvatar";

const Contribution = ({
  username,
  image_url,
  onProfileClick,
  index,
  id,
  user_id,
  contributions_count,
  raffle_entries_count,
}) => {
  return (
    <Wrapper
      $flex
      $justifyContent="space-between"
      borderTop={index === 0}
      $spacing="px-4 py-4+1"
      $bgColor="tertiary50"
      $color="01Primary700"
      $gap="11px"
    >
      <LeftPart>
        <UsernameAvatar
          username={username}
          dataID={id}
          onProfileClick={onProfileClick}
          image_url={image_url}
          index={index}
          user_id={user_id}
          imageWidth={"24px"}
          imageHeight={"24.54px"}
          $gap="8px"
        />
        <Box $spacing="my-6" $gap="4px" $flex $alignItems="center">
          <Text $color="01Primary700" $extraBold>
            Contributions: {"  "}
          </Text>
          <Text $color="01Primary700" $extraBold>
            {" "}
            {contributions_count}
          </Text>
        </Box>
        <Box $flex $gap="4px" $alignItems="center">
          <Text $color="01Primary700" $extraBold>
            Raffle Entries:{" "}
          </Text>
          <Text $color="03Secondary700" $extraBold>
            {" "}
            {raffle_entries_count}
          </Text>
        </Box>
      </LeftPart>
    </Wrapper>
  );
};

const MobileQualifedContributors = ({ data, onProfileClick }) => {
  return data.map((item, index) => (
    <Contribution key={item.id} index={index} {...item} onProfileClick={onProfileClick} />
  ));
};

const Wrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  ${({ borderTop, theme }) => borderTop && `border-top: 1px solid ${theme.colors["01Primary200"]};`}
`;

const LeftPart = styled(Box)`
  flex: 1;
`;

const StyledIcon = styled(IconSvg)`
  margin-left: 6px;
  margin-right: 4px;
`;

export default MobileQualifedContributors;
