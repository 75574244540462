import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const HeaderElement = ({
  text,
  onSort,
  name,
  sort,
  sortType,
  $maxWidth,
  $firstElement,
  pointer,
  $minWidth,
  $width,
  padding,
  $typography,
  text2,
  gapText = "8px",
}) => {
  const icon = sort ? (sort === "desc" ? "sort-down" : "sort-up") : "sort";

  return (
    <HeaderItem sort={!!onSort} $width={$width} $maxWidth={$maxWidth} $minWidth={$minWidth} $padding={padding}>
      <StyledBox
        $pointer={pointer}
        data-name={name}
        data-sortType={sortType}
        onClick={onSort}
        $flex
        $gap={gapText}
        $alignItems="center"
        $firstElement={$firstElement}
      >
        {text && (
          <>
            <Text $bold $size="14px" $lineHeight="21px" $textAlign="left" $typography={$typography}>
              {text}
            </Text>
            {onSort && <IconSvg $color="01Primary700" $width="7.5px" icon={icon} />}
          </>
        )}
        {text2 && (
          <Text $bold $size="14px" lineHeight="21px" textAlign="left" $typography={$typography}>
            {text2}
          </Text>
        )}
      </StyledBox>
    </HeaderItem>
  );
};

const HeaderItem = styled.th`
  ${({ $padding }) => $padding && `padding: ${$padding}`};
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}
  ${({ $width }) => $width && `width: ${$width}`};

  div {
    cursor: ${({ sort }) => (sort ? "pointer" : "unset")};
    max-width: inherit;
    white-space: ${({ $firstElement }) => !$firstElement && "pre-line"};

    & * {
      pointer-events: none;
    }
  }
`;

const StyledBox = styled(Box)`
  white-space: ${({ $firstElement }) => !$firstElement && "pre-line"};
`;

export default HeaderElement;
