import { Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CongratsBody from "./CongratsBody";
import ConnectBody from "./ConnectBody";
import { DiscourseProvider } from "./DiscourseContext";

const STEPS = [ConnectBody, CongratsBody];

const mobileConfig = {
  $fullScreen: true,
  hideX: true,
  $overflowY: "auto",
  $borderRadius: "0px !important",
  backText: "Back to community",
  $contentHeight: "100%",
};

const nonMobileConfig = { leaf: true };

const DiscourseConnectModal = (props) => {
  const { closeOthers } = useStoreActions((state) => state.modals);
  const { isMobile } = useStoreState((state) => state.media);
  const [username, setUsername] = useState();
  const [step, setStep] = useState(0);
  const onChange = (event) => setUsername(event.target.value);
  const Body = STEPS[step];
  const config = isMobile ? mobileConfig : nonMobileConfig;
  const footerContent = Body.Footer ? (
    <Body.Footer provider={props.provider} username={username} setStep={setStep} hideModal={props.hideModal}/>
  ) : null;
  useEffect(() => {
    closeOthers("DiscourseConnectModal");
  }, []);

  return (
    <DiscourseProvider>
      <StyledModal
        open
        onClose={props.hideModal}
        leaf={!isMobile}
        $maxWidth="684px"
        $maxHeight="751px"
        footerContent={footerContent}
        {...config}
      >
        <Body {...props} onChange={onChange} username={username} setStep={setStep} />
      </StyledModal>
    </DiscourseProvider>
  );
};

const StyledModal = styled(Modal)`
  & > div {
    @media only screen and (max-height: 990px) {
      transform: unset;
    }
  }
  & > div > div {
    & > div:first-child {
      button {
        right: 16px;
        top: 16px;
      }
    }
  }
`;

export default DiscourseConnectModal;
