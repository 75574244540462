import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";

const UpdateInfo = () => {
  const { isMiniTablet, isMobile } = useStoreState((state) => state.media);
  const [nextUpdate, setNextUpdate] = useState(null);

  useEffect(() => {
    const currentUTC = new Date();
    const nextUpdateUTC = new Date(currentUTC);

    nextUpdateUTC.setUTCHours(nextUpdateUTC.getUTCHours() + 1, 0, 0, 0);
    setNextUpdate(nextUpdateUTC);

    const intervalId = setInterval(() => {
      setNextUpdate(nextUpdateUTC);
    }, 3600000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", timeZone: "UTC" });
  };

  return (
    <StyledBox $flex $alignItems={isMobile || isMiniTablet ? "flex-start" : "center"} $gap="8px">
      <IconSvg icon="clock" $width="16px" $height="21px" $color="03Secondary500" />
      <Text $typography="body/small" $color="01Primary700">
        The Contributors updates on an hourly basis, with the next update scheduled for
        {nextUpdate && ` ${formatTime(nextUpdate)}`} UTC.
      </Text>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  margin-bottom: 24px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  @media ${breakpoints.mobile} {
    margin-top: 24px;
    svg {
      min-width: 16px;
      height: 21px;
    }
  }
`;
export default UpdateInfo;
