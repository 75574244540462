import { Box, Loader } from "@thrivecoin/ui-components";
import styled from "styled-components";
import TableHeaderElement from "./../../../common/TableHeaderElement";
import TableRow from "./TableRow";

const TableQualifedContributors = ({ data, loading, onProfileClick }) => {
  return (
    <>
      <Table>
        <tr>
          <TableHeaderElement text="Season Contributors" firstElement />
          <TableHeaderElement text="Contributions" />
          <TableHeaderElement text="Raffle Entries" />
        </tr>
        {!loading && (
          <TBody>
            {data.map((item) => (
              <TableRow key={item.id} onProfileClick={onProfileClick} {...item} />
            ))}
          </TBody>
        )}
      </Table>
      {loading && (
        <Box $width="100%" $spacing="m-a p-9+6" $flex $alignItems="center" $justifyContent="center">
          <Loader />
        </Box>
      )}
    </>
  );
};

const Table = styled.table`
  width: 100%;
  margin-top: 69px;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
  border-collapse: collapse;
`;

const Line = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
`;

const TBody = styled.tbody`
  width: 100%;
  & > tr {
    &:first-child {
      border-radius: 8px 8px 0px 0px;
      td {
        border-top: 2px solid ${({ theme }) => theme.colors["01Primary300"]};
      }
      td:first-child {
        border-radius: 8px 0 0 0;
      }
      td:last-child {
        border-radius: 0 8px 0 0;
      }
    }
    background: ${({ theme }) => theme.colors["01Primary110"]};
    border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  }
`;

export default TableQualifedContributors;
