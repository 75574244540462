import { Box, Button, Text, TextArea } from "@thrivecoin/ui-components";
import styled from "styled-components";

const RequestSecurityReviewStep = ({ handleMessageChange, type }) => {
  return (
    <StyledBox>
      <Text $color="02Primary500" $spacing="mb-5" $typography="body/medium">
        Please add your details below why you think your {type} is inappropriate. Our admins will review your activities on
        ThriveCoin and re-assess.
      </Text>
      <StyledTextArea
        onChange={handleMessageChange}
        name="message"
        placeholder="Type personal Message here..."
        rows="3"
        modal
      ></StyledTextArea>
    </StyledBox>
  );
};

const Footer = ({ closeModal, handleSubmit, loading }) => {
  return (
    <Box $flex $gap="16px">
      <Button variant="blue" $borderRadius="50px" $width="100%" onClick={closeModal} modal>
        I'll See Myself Out
      </Button>
      <StyledButton
        variant="red"
        $borderRadius="50px"
        kind="outline"
        $width="100%"
        leftIcon="search2"
        onClick={handleSubmit}
        loading={loading}
        modal
      >
        Request Security Review
      </StyledButton>
    </Box>
  );
};

const StyledBox = styled(Box)`
  text-align: left;
`;

const StyledButton = styled(Button)`
  text-transform: capitalize;
  padding: 15px 22px;
`;

const StyledTextArea = styled(TextArea)`
  textarea {
    height: 124px;
  }
`;
RequestSecurityReviewStep.Footer = Footer;

export default RequestSecurityReviewStep;
