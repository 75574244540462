import { Box, TabsContainer } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { recentCommunityIDs } from "../Constants";
import useQuery from "../hooks/useQuery";
import WaysToContribute from "../Season/BodySeason/WaysToContribute/index";
import ContributionOpportunities from "./ContributionOpportunities/ContributionOpportunities";
import DashboardHeader from "./DashboardHeader";
import MyActivity from "./MyActivity";

const selectedTabIndex = (tab, headers) => {
  if (tab === "Contribution Opportunities") {
    if (headers.includes("My Contributions")) {
      return 1;
    }
    return 0;
  }
  return tab;
};

const NON_LOGGED_TABS = [ContributionOpportunities, WaysToContribute];
const LOGGED_TABS = [MyActivity, ...NON_LOGGED_TABS];

const Dashboard = () => {
  const { confirmed } = useStoreState((state) => state.user.user);
  const { getRecentlyExploredCommunity } = useStoreActions((actions) => actions.community);
  const selectedIndex = parseInt(useQuery().get("tabIndex")) || 0;
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedIndex);
  const recentlyExplored = recentCommunityIDs();
  const referral_code = useParams().referral_code;
  const { getAllActiveListingsRequest } = useStoreActions((actions) => actions.listings);
  const disabledIndex = confirmed ? [] : [false, false, true];

  let headers = confirmed
    ? ["My Contributions", "Contribution Opportunities", "Ways To Contribute"]
    : ["Contribution Opportunities", "Ways To Contribute", "My Contributions"];

  const onChangeTab = (index) => setCurrentTabIndex(index);
  const tabIndex = selectedTabIndex(currentTabIndex, headers);
  const tabProps = { setTabIndex: onChangeTab, group: "dashboard", getListings: getAllActiveListingsRequest };

  let tabClasses = confirmed ? LOGGED_TABS : NON_LOGGED_TABS;

  useEffect(() => {
    if (recentlyExplored) {
      getRecentlyExploredCommunity(recentlyExplored);
    }
  }, []);

  useEffect(() => {
    referral_code && sessionStorage.setItem("referral-code", referral_code);
  }, []);

  return (
    <Wrapper $flex $direction="column" className="background-container">
      <DashboardHeader />
      <TabsContainer
        selectedIndex={tabIndex}
        tabClasses={tabClasses}
        headers={headers}
        onChangeIndex={onChangeTab}
        disabledIndex={disabledIndex}
        tabProps={tabProps}
        className="tabsContainer"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  min-height: 100vh;
  position: relative;
`;

export default Dashboard;
