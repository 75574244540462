import { Box, breakpoints, Button, DropDown, IconSvg, Modal, Text, Tooltip } from "@thrivecoin/ui-components";
import truncateText from "@utils/truncateText";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../Constants";

const footerContent = (hideModal) => (
  <FooterButton
    $size="lg"
    $borderRadius="56px"
    $height="56px"
    $bold
    onClick={hideModal}
    kind="outline"
    $color="02Primary500"
    variant="02PrimaryGrey300"
    modal={true}
  >
    Save & Close
  </FooterButton>
);

const _renderPlaceholders = (n, list) => (
  <>
    {[...Array(n)].map((_, index) => (
      <BadgePlaceholder key={index} $list={list} />
    ))}
  </>
);

const ActiveBadges = ({ userBadges, removeBadge }) => (
  <BadgesBox $flex>
    {userBadges.map((item) => (
      <Box key={item.id} $relative>
        <BadgeIcon src={item?.logo_url || item.badge_logo_url} />
        <DeleteBox $pointer data-id={item.id} data-badge_id={item.badge_id} onClick={removeBadge}>
          <IconSvg icon="x" $width="14px" />
        </DeleteBox>
      </Box>
    ))}
    {_renderPlaceholders(4 - userBadges.length)}
  </BadgesBox>
);

const ManageUserBadges = ({ hideModal, communities, getProfile, user_id, isPublicProfile }) => {
  const { publicUser } = useStoreState((state) => state.authentication);
  const { user } = useStoreState((state) => state.user);
  const profileData = isPublicProfile ? publicUser : user;
  const [selectedCommunity, setSelectedCommunity] = useState(-1);
  const { user_badges: userBadges } = profileData;
  const { badges } = useStoreState((state) => state.badges);
  const { getBadges, assignBadgeToUser, removeBadgeFromUsers } = useStoreActions((actions) => actions.badges);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { isDesktop, isTablet, isMobile } = useStoreState((state) => state.media);
  const [message, setMessage] = useState("");

  const { image_url, address, username } = profileData;
  const image = image_url || DEFAULT_PROFILE_IMAGE;
  const unchangedUsername = address === username;
  const options = [{ id: -1, name: "System" }, ...communities];
  const _userBadges = userBadges.slice(0, 4);
  const badgesToShow = isMobile ? 10 : 12;
  const _badges = (badges || []).slice(0, badgesToShow);
  const isBadgeAssigned = (id) => !!userBadges.find((item) => item.badge_id === id);
  const tooltipWidth = (index) => (index === 0 || index === 5 || index === 6 || index === 11 ? "140px" : "230px");
  const paddingBody = isDesktop ? "16px 48px 24px" : isTablet ? "0 24px 24px !important" : "16px !important";

  const onCommunityChange = (val) => {
    if (val) {
      setSelectedCommunity(val.id);
    } else {
      setSelectedCommunity(-1);
    }
  };

  const assignBadge = (badge) => {
    if (!isBadgeAssigned(badge.id)) {
      assignBadgeToUser({ badge_id: badge.id, user_id })
        .then(() => {
          getProfile();
          setMessage("Badge Successfully added.");
        })
        .catch((e) =>
          dangerToast({ content: "Something went wrong while assigning user to the badge!", dismissTime: 5000 })
        );
    }
  };

  const removeBadge = (e) => {
    const id = e.target.dataset.id;
    const badgeId = e.target.dataset.badge_id;
    removeBadgeFromUsers({ badge_id: badgeId, user_badge_ids: [id] })
      .then(() => {
        getProfile();
        setMessage("Badge Successfully removed.");
      })
      .catch(() =>
        dangerToast({ content: "Something went wrong while removing user from the badge!", dismissTime: 5000 })
      );
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, [3000]);
    }
  }, [message]);

  useEffect(() => {
    if (selectedCommunity === -1) {
      getBadges({ system: true });
    } else {
      getBadges({ community_ids: [selectedCommunity] });
    }
  }, [selectedCommunity]);

  return (
    <Modal
      open
      onClose={hideModal}
      $maxWidth="620px"
      footerContent={footerContent(hideModal)}
      $paddingBody={paddingBody}
      $footerPadding="24px 0 48px"
    >
      <Box $relative>
        <Text $typography="heading/medium" $textAlign="center">
          Add/Remove User Badge
        </Text>
        <ActiveBadgesWrapper>
          <Text $typography="heading/small" $spacing="mb-5" $sm-spacing="mb-4">
            Active User Badges
          </Text>
          <ActiveBadgesBox $flex $gap="24px">
            <UserAvatar src={image} />
            <Box $flex $direction="column" $justifyContent="space-between">
              <Text $typography="heading/large">{unchangedUsername ? truncateText(username, 6) : username}</Text>
              {!isMobile && <ActiveBadges userBadges={_userBadges} removeBadge={removeBadge} />}
            </Box>
          </ActiveBadgesBox>
        </ActiveBadgesWrapper>
        {isMobile && <ActiveBadges userBadges={_userBadges} removeBadge={removeBadge} />}
        <Text $typography="heading/small">Add a Badge</Text>
        <Text $spacing="my-5" $sm-spacing="my-4">
          You can assign a badge to a user by selecting the community and choose the badge you want to add to this user.
          If you want to add badges to multiple users at once, please use the{" "}
          <SemiBold>Communities Admin Panel</SemiBold>
        </Text>
        <DropDown
          value={selectedCommunity}
          valueKey="id"
          options={options}
          label="name"
          required
          onChange={onCommunityChange}
        />
        <BadgesList $spacing="mt-5" $sm-spacing="mt-4">
          {_badges.map((item, index) => (
            <Box $spacing="mx-a" key={item.id} $pointer={!isBadgeAssigned(item.id)} onClick={() => assignBadge(item)}>
              <Tooltip
                $direction="top"
                $minWidth={tooltipWidth(index)}
                $bgColor="#fff"
                message={
                  <Text>
                    The <b>&apos;{item.name}&apos;</b> Badge for validation contributions.
                  </Text>
                }
              >
                <BadgeIcon src={item.logo_url} $list $selected={isBadgeAssigned(item.id)} />
              </Tooltip>
            </Box>
          ))}
          {_renderPlaceholders(badgesToShow - _badges.length, true)}
        </BadgesList>
        {message && (
          <Message $spacing="mt-5" $sm-spacing="mt-4" $color="03Primary500">
            <IconSvg icon="check" $width="22px" />
            <Text>{message}</Text>
          </Message>
        )}
      </Box>
    </Modal>
  );
};

const ActiveBadgesWrapper = styled(Box)`
  margin: 24px 0;
  padding: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey150"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey150"]};

  @media ${breakpoints.mobile} {
    margin: 16px -16px 0;
    padding: 24px 16px;
    border-bottom: 0;
  }
`;

const ActiveBadgesBox = styled(Box)``;

const BadgesBox = styled(Box)`
  gap: 16px;

  @media ${breakpoints.mobile} {
    gap: unset;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey150"]};
  }
`;

const UserAvatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 8px;

  @media ${breakpoints.mobile} {
    width: 90px;
    height: 90px;
  }
`;

const SemiBold = styled.span`
  font-weight: 600;
`;

const BadgePlaceholder = styled(Box)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors["01Primary110"]};
  ${({ $list }) => $list && "margin: 0 auto;"}
`;

const BadgesList = styled(Box)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 60px 60px;
  gap: 24px 0;

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const BadgeIcon = styled.img`
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease-in-out;

  ${({ $selected }) => $selected && "opacity: 0.3;"}

  &:hover {
    ${({ $list, $selected }) => $list && !$selected && "transform: scale(1.2);"}
  }
`;

const DeleteBox = styled(Box)`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey300"]};

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -8px;
  left: 34px;

  & > * {
    pointer-events: none;
  }
`;

const FooterButton = styled(Button)`
  margin: 0 -16px;
  width: calc(100% + 32px);

  @media ${breakpoints.belowDesktop} {
    margin: 0;
    width: 100%;
  }
`;

const Message = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;

  display: flex;
  align-items: center;
  min-height: 56px;
  gap: 12px;
  border-radius: 12px;
  padding-left: 20px;
  border: 1px solid ${({ theme }) => theme.colors["03Primary500"]};
`;

export default ManageUserBadges;
