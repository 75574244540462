import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const TableDataElement = ({ text, dataId, onClick, textIconGroup, icon, ...props }) => {
  return (
    <DataElement>
      {textIconGroup ? (
        <Box $flex $alignItems="center" $justifyContent="start" $gap="6px">
          <IconSvg icon={icon} $width="17px" />
          <Text {...props} onClick={onClick} data-id={dataId}>
            {text}
          </Text>
        </Box>
      ) : (
        <Text {...props} onClick={onClick} data-id={dataId}>
          {text}
        </Text>
      )}
    </DataElement>
  );
};

const DataElement = styled.td`
  padding: 26px 16px;
  ${({ $pointer }) => $pointer && `cursor: pointer;`}
`;

export default TableDataElement;
