import { Button, getTypography } from "@thrivecoin/ui-components";
import styled from "styled-components";
import Headers from "./Headers";
import RowElement from "./RowElement";

export const Table = styled(
  ({
    items,
    showStatus,
    showRewards,
    className,
    rowPRops,
    rowClass,
    selectedIDs,
    selectAll,
    sortProps,
    allSelected,
  }) => {
    const rows = items.map((item) => (
      <RowElement
        {...item}
        {...rowPRops}
        key={item.id}
        showStatus={showStatus}
        showRewards={showRewards}
        reward={item.reward_amount}
        className={rowClass}
        selected={selectedIDs.includes(item.id)}
      />
    ));

    return (
      <table className={className}>
        <Headers {...sortProps} selectAll={selectAll} allSelected={allSelected} />
        <tbody>{rows}</tbody>
      </table>
    );
  }
)`
  width: 100%;
  border-spacing: 0;
  text-align: left;

  thead th {
    ${getTypography("body/small")}
  }

  thead th:first-child {
    width: 1%;
  }

  tbody {
    td[data-limit="true"] {
      color: ${({ theme }) => theme.colors["error2"]};
    }

    tr[data-selected="true"] {
      background: ${({ theme }) => theme.colors["manageCard3"]};
    }

    tr {
      background: ${({ theme }) => theme.colors["01Primary0"]};
    }

    tr:first-child {
      td:first-child {
        border-top-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    tr:not(:last-child) td {
      border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
    }
  }

  td,
  th {
    padding: 16px;
  }

  th {
    white-space: nowrap;
  }

  img {
    width: 26px;
    height: 26px;
    vertical-align: top;
  }

  ${Button} {
    white-space: nowrap;
  }
`;

Table.defaultProps = {
  listings: [],
  selectedIDs: [],
  listingProps: {},
};

export default Table;
