import CoinsImg from "@assets/dashboard/coins-img.svg";
import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { numberWithCommas } from "../../../utils/formatting";
import { MY_CONTRIBUTIONS_INDEX } from "../../Profile";
import OgBox from "./OgBox";
const myContributionsPath = `/current_user/profile?tabIndex=${MY_CONTRIBUTIONS_INDEX}`;

const MyContributions = ({ stats, isMiniTablet, isMobile }) => {
  const navigate = useNavigate();

  const seeAllContributions = () => navigate(myContributionsPath);

  return (
    <BoxWrapper>
      <Wrapper $flex $gap="40px">
        <StyledBox $gap="20px" $flex $direction="column" $justifyContent="space-between" $height="100%">
          <Box $pointer $flex $alignItems="center" $gap="12px" onClick={seeAllContributions}>
            <Text $typography="body/small" $color="02Primary500" $extraBold>
              CONTRIBUTIONS
            </Text>
            <Box $gap="4px" $alignItems="center" $flex>
              <Text $typography="body/small" $color="02Primary500" $extraBold $underline>
                View All
              </Text>
              <IconSvg icon="arrow-up-right" $width="10px" $height="21px" $color="02Primary500" />
            </Box>
          </Box>
          <Box $flex $gap="12px">
            <IconSvg $width="40px" icon="puzzle" $color="hover" />
            <Num $color="02Primary500"> {stats?.contributions_made}</Num>
          </Box>
        </StyledBox>
        {(isMiniTablet || isMobile) && <Line />}
        <RewardBox $gap="20px" $flex $direction="column" $justifyContent="space-between" $height="100%">
          <Text $typography="body/small" $extraBold>
            REWARDS EARNED
          </Text>
          <Box $flex $gap="12px" $mdWidth="175px">
            <IconSvg $width="40px" icon="logo-gold-circle" fill="#CEA73" />
            <Box $flex $gap="10px" $alignItems="baseline">
              <Num $color="02Primary500">{numberWithCommas(+stats?.rewards_earned)}</Num>
            </Box>
          </Box>
          <Coins src={CoinsImg} />
        </RewardBox>
      </Wrapper>
      {isMiniTablet && <OgBox />}
    </BoxWrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  max-width: 528px;

  @media ${breakpoints.tablet} {
    flex-direction: row-reverse;
    max-width: unset;
    margin-right: auto;
    width: unset;
    gap: 99px;
    justify-content: flex-end;
  }
  @media ${breakpoints.miniTablet} {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }
`;

const BoxWrapper = styled(Box)`
  @media ${breakpoints.tablet} {
    margin-right: auto;
    border-top: 1px solid ${({ theme }) => theme.colors["02Primary0"]};
    width: 100%;
    padding-top: 24px;
    margin-top: 24px;
  }
  @media ${breakpoints.miniTablet} {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const Num = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
`;

const StyledBox = styled(Box)`
  padding-left: 40px;
  border-left: 1px solid ${({ theme }) => theme.colors["02Primary0"]};
  position: relative;
  @media ${breakpoints.tablet} {
    padding-left: 64px;
    gap: 16px;
  }
  @media ${breakpoints.miniTablet} {
    border-left: none;
    padding: 0;
  }
  @media ${breakpoints.mobile} {
    border-left: none;
    padding: 0;
  }
`;

const RewardBox = styled(Box)`
  padding-left: 40px;
  border-left: 1px solid ${({ theme }) => theme.colors["02Primary0"]};
  position: relative;
  @media ${breakpoints.belowDesktop} {
    border-left: none;
    padding-left: 0;
    gap: 16px;
  }
`;

const Coins = styled.img`
  position: absolute;
  bottom: -74px;
  right: -153px;
  @media ${breakpoints.belowDesktop} {
    display: none;
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors["02Primary0"]};
  margin: 24px 0;
  width: 100%;
`;

export default MyContributions;
