import HeaderElement from "@common/HeaderElement";

const HeaderRow = ({ onSort, sortType, sortDirection, isDesktop, isMiniTablet }) => {
  const desktopPadding = isDesktop && "12px 24px 0";
  const miniTabletPadding = isMiniTablet && "12px 19px 12px 0";
  const padding = isDesktop ? desktopPadding : miniTabletPadding;

  return (
    <tr>
      <HeaderElement
        text="Raffle Entries"
        name="serial_number"
        onSort={onSort}
        sort={sortType === "serial_number" ? sortDirection : null}
        $padding={!isDesktop && "0 24px 0"}
      />

      <HeaderElement
        text="Community"
        name="community"
        onSort={onSort}
        sort={sortType === "community" ? sortDirection : null}
        $padding={desktopPadding}
      />

      <HeaderElement
        text="Season"
        name="season"
        onSort={onSort}
        sort={sortType === "season" ? sortDirection : null}
        $padding={padding}
      />

      <HeaderElement text="Status" name="status" $padding={padding} />

      {isDesktop && (
        <>
          <HeaderElement
            text="Date"
            name="date"
            onSort={onSort}
            sort={sortType === "date" ? sortDirection : null}
            $padding={desktopPadding}
          />
          <HeaderElement $width="192px" text="" $padding="12px 30px 12px 28px" />
        </>
      )}
    </tr>
  );
};

export default HeaderRow;
