import { Box, Text, TokenReward } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import truncateText from "@utils/truncateText";
import { CircleImage, IconContextMenu } from "../common";

const Row = ({ id, contribution, image_url, username, amount, date, deleteClick }) => {
  return (
    <tr>
      <td>{contribution}</td>
      <td>
        <Box $flex $gap="12px" $alignItems="center">
          <CircleImage $size="44px" src={image_url} />
          <Text $typography="body/medium" $bold>
            {truncateText(username)}
          </Text>
        </Box>
      </td>
      <td>
        <TokenReward amount={amount} $color="01Primary700" hideText />
      </td>
      <td>
        <Box $flex $justifyContent="space-between" $alignItems="center">
          <Text>{formatDate(date)}</Text>
          <IconContextMenu>
            <ul>
              <li onClick={deleteClick} data-id={id}>
                <Text color="error">Delete Contribution</Text>
              </li>
            </ul>
          </IconContextMenu>
        </Box>
      </td>
    </tr>
  );
};

export default Row;
