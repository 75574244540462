import sparkleImg from "@assets/profile/sparkle.svg";
import { Box, breakpoints, Button, Text } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import styled from "styled-components";
import CircleSeparator from "./CircleSeparator";
import Status, { STATUSES } from "./Status";

const truncateText = (str) => (str?.length > 15 ? str.substr(0, 15) + "..." : str);

const BodyRow = ({
  serial_number,
  community_name,
  community_slug,
  onCommunityClick,
  season_order_number,
  onSeasonClick,
  status,
  isDesktop,
  season_slug,
  community_logo_url,
  raffle_draw_date,
  isMiniTablet,
}) => {
  const currentStatus = STATUSES[status];
  const colorWinner = status === "not_selected" ? "link" : "#4F99B9";
  const selected = status === "selected";

  return (
    <>
      <Row $bgColor={currentStatus.$bgColorRow}>
        <DataElement>
          <Text $color={currentStatus.textRowColor} $typography="body/x-large" $textAlign="center" $bold>
            {serial_number}
          </Text>
          <CircleSeparator />
        </DataElement>

        <DataElement>
          <Box $flex $alignItems="center" $gap="12px">
            <Logo src={community_logo_url} />
            <Text
              $pointer
              $color={currentStatus.textRowColor}
              $typography="body/medium"
              $bold
              data-id={community_slug}
              onClick={onCommunityClick}
            >
              {isMiniTablet ? truncateText(community_name) : community_name}
            </Text>
          </Box>
        </DataElement>
        <DataElement $padding="0 10px 0 24px">
          <SeasonText
            data-id={season_slug}
            $typography="body/medium"
            $pointer
            $underline
            $color={colorWinner}
            $bold
            onClick={onSeasonClick}
          >
            {isDesktop ? "Season " : "S"}#{season_order_number}
          </SeasonText>
          {isDesktop && <CircleSeparator />}
        </DataElement>

        <DataElement $padding={`0 ${selected ? 0 : "22.65px"} 0 30px`}>
          <Box $alignItems="center" $flex>
            <Status status={status} />
            {isDesktop && <SparkleBox selected={selected} />}
          </Box>
        </DataElement>
        {isDesktop && (
          <>
            <DataElement>
              <Text $color={currentStatus.textRowColor} $textAlign="left">
                {formatDate(raffle_draw_date)}
              </Text>
              <CircleSeparator />
            </DataElement>

            <DataElement $padding="0 30px 0 20px">
              <StyledButton
                data-id={season_slug}
                onClick={onSeasonClick}
                variant="blue"
                leftIcon="eye"
                $bold
                iconWidth="180px"
                $color={colorWinner}
                kind="outline"
                $borderRadius="40px"
                $width="182px"
                $size="sm"
                $heightSize="44px"
              >
                See Raffle Details
              </StyledButton>
            </DataElement>
          </>
        )}
      </Row>
      <SpacingRow />
    </>
  );
};

const DataElement = styled.td`
  ${({ $pointer }) => $pointer && `cursor: pointer;`}
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}`};
  padding: ${({ $padding = "0 24px" }) => $padding};
  height: 108px;
  position: relative;
`;

const Row = styled.tr`
  background: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  border-radius: 8px;
  @media ${breakpoints.miniTablet} {
    td:last-child {
      padding-left: 0;
      padding-right: 24px;
    }
    td:nth-child(2) {
      padding-right: 8px;
      padding-left: 23px;
      & > div {
        max-width: 90px;
        gap: 0;
        & > img {
          margin-right: 8px;
        }
      }
    }
    td:nth-child(3) {
      padding: 0;
      & > div {
        text-align: center;
      }
    }
  }

  @media ${breakpoints.mobile} {
    display: inline-flex;
    position: relative;
    height: 140px;
    width: 100%;
    td {
      display: inline-block;
      padding: 32px 24px 24px 8px;
      height: unset;
    }
    td:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 117px;
      padding: 0;
      & > div {
        display: flex;
      }
    }

    td:nth-child(2) {
      padding: 24px 0 24px 29px;
    }
    td:nth-child(3) {
      margin-left: auto;
    }
    td:last-child {
      position: absolute;
      bottom: 0;
      left: 146px;
      padding-left: 0;
    }
  }
`;

const SpacingRow = styled.tr`
  margin-bottom: 24px;
  display: block;
`;

const StyledButton = styled(Button)`
  text-wrap: nowrap;
  padding: 11px 24.5px 11px 22.5px;
  margin-left: auto;
  svg {
    margin-right: 6px !important;
    transform: scale(2);
  }
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const SeasonText = styled(Text)`
  white-space: nowrap;
`;

const SparkleBox = styled.div`
  width: 90px;
  height: 79px;
  ${({ selected }) => selected && `background-image: url(${sparkleImg}); margin-left: 22.65px`}
`;

export default BodyRow;
