import { Box, Button, Grid, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { CircleBox, LoadingSection, RectangleBox, SectionBox } from "./common";

const BadgesSection = ({ communityId }) => {
  const { getCommunityBadges } = useStoreActions((actions) => actions.badges);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { communityBadges } = useStoreState((state) => state.badges);
  const [loading, setloading] = useState(true);

  const getBadges = () => getCommunityBadges({ community_id: communityId });

  const onCreate = () => {
    showModal({ modalName: "AddBadgeModal", community_id: communityId, callback: getBadges });
  };

  const onEdit = (event) => {
    const { id } = event.currentTarget.dataset;
    showModal({ modalName: "AddBadgeModal", community_id: communityId, callback: getBadges, id });
  };

  useEffect(() => {
    if (communityId) {
      getBadges().finally(() => {
        setloading(false);
      });
    }
  }, communityId);

  if (loading) {
    return <LoadingSection title="Badges" />;
  }

  const Component = communityBadges.length > 0 ? SomeBadgesBox : NoBadgesBox;
  return <Component badges={communityBadges} onCreate={onCreate} onEdit={onEdit} communityId={communityId} />;
};

const NoBadgesBox = ({ onCreate }) => {
  return (
    <SectionBox title="Badges" status="blue">
      <Box $flex $gap="16px">
        <CircleBox $bgColor="01Secondary500" $pointer onClick={onCreate}>
          <IconSvg icon="plus" $color="#fff" />
        </CircleBox>
        <CircleBox $bgColor="#FFFFFF33" />
        <CircleBox $bgColor="#FFFFFF33" />
        <CircleBox $bgColor="#FFFFFF33" />
      </Box>
      <RectangleBox
        status="blue"
        $relative
        $lgSize
        $spacing="px-8 py-6"
        $flex
        $alignItems="center"
        $justifyContent="center"
      >
        <Text $typography="body/small" $color="02Primary500" $center>
          No Badges created yet.
        </Text>
      </RectangleBox>
      <Button $borderRadius="40px" $rightIcon="plus" $bgColor="01Secondary500" onClick={onCreate}>
        Create
      </Button>
    </SectionBox>
  );
};

const MAX_CIRCLES = 12;

const SomeBadgesBox = ({ badges, onEdit, onCreate, communityId }) => {
  const navigate = useNavigate();
  const hasAddCircle = badges.length < MAX_CIRCLES;
  const totalCircles = hasAddCircle ? badges.length + 1 : badges.length;
  const fillercount = MAX_CIRCLES - totalCircles;
  const fillers = new Array(fillercount).fill(0);
  const managePath = () => {
    const url = `/manage/communities/${communityId}`;

    navigate(url);
  };

  return (
    <SectionBox title="Badges" status="published">
      <Grid $columns="repeat(4, 1fr)" $gap="16px" $spacing="mb-4">
        {badges.map(({ logo_url, id }) => (
          <CircleBox $bgColor="transparent" key={id} $pointer data-id={id} onClick={onEdit}>
            <Badge src={logo_url} />
          </CircleBox>
        ))}
        <CircleBox $bgColor="03Primary500" onClick={onCreate} $pointer>
          <IconSvg icon="plus" $color="#FFFFFF" />
        </CircleBox>
        {fillers.map((_, index) => (
          <CircleBox key={index} status="published" />
        ))}
      </Grid>
      <Button
        onClick={managePath}
        rightIcon="pen-2"
        kind="solid"
        variant="green"
        $borderRadius="40px"
        $color="02Primary0"
      >
        Edit Badges
      </Button>
    </SectionBox>
  );
};

const Badge = styled.img`
  height: inherit;
  object-fit: cover;
  width: inherit;
`;

export default BadgesSection;
