import TabWrapper from "@common/TabWrapper";

import { Button, ListingFilters } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import TableTab from "../../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "../../hooks/useSort";
import TableBadges from "./TableBadges";
import useTagFilters from "./useTagFilters";

const BadgesTab = () => {
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { isMobile } = useStoreState((state) => state.media);
  const { getBadges } = useStoreActions((actions) => actions.badges);
  const { badges } = useStoreState((state) => state.badges);
  const { community, activeCommunities } = useStoreState((state) => state.community);
  const { getActiveCommunities } = useStoreActions((actions) => actions.community);

  const [badgesNum, setBadgesNum] = useState(badges?.length);

  const sortProps = useSort(getBadges, "name", "community", "contributors");

  const title = badges && `There are a total of  ${badges?.length} badges`;

  const justifyTitle = isMobile ? "space-between" : "center";
  const _getBadges = () => getBadges({});
  const createBadge = () => showModal({ modalName: "AddBadgeModal", callback: _getBadges });

  const filterFN = (text, selectedCommunities) => {
    const community_ids = selectedCommunities.map((community) => community.value);

    getBadges({ community_ids, text });
  };
  const tagProps = useTagFilters(activeCommunities, filterFN, sortProps, badges, badgesNum);
  const rowProps = {
    _getBadges,
  };
  const selectedItems =
    !isMobile && tagProps.persistentOptions.filter((tag) => tag.selected && tag.id).map((tag) => tag.id);

  const requestFilters = () => {
    return {
      ...(selectedItems[0].includes("system") && { system: true }),
    };
  };
  const onLoadMore = (page) => {
    const filters = requestFilters();
    return Promise.resolve(
      sortProps
        .sortMethod({
          page,
          ...filters,
        })
        .finally(() => sortProps.setLoading(false))
    );
  };

  useEffect(() => {
    const filters = requestFilters();
    getBadges({ ...filters });
  }, [selectedItems[0], tagProps.filterOptions[0]?.selectedOptions.length]);

  useEffect(() => {
    if (selectedItems[0] !== "system") {
      setBadgesNum(badges?.length);
    }
  }, [badges]);

  useEffect(() => {
    getActiveCommunities();
  }, []);

  const extraComponent = (
    <Button
      kind="solid"
      variant="green"
      $typography="body/medium"
      rightIcon="plus"
      $borderRadius="40px"
      $heightSize="44px"
      $width="210px"
      onClick={createBadge}
    >
      Add New Badge
    </Button>
  );

  return (
    <TableTab
      title={title}
      titleSearch
      justifyTitle={justifyTitle}
      TableComponent={TableBadges}
      data={badges}
      sortProps={{ ...sortProps, onLoadMore }}
      _showForDesktop={true}
      WrapperKlass={TabWrapper}
      hideTitleBox={true}
      rowProps={rowProps}
    >
      <ListingFilters showFilter={true} {...tagProps} extraComponent={extraComponent} />
    </TableTab>
  );
};

export default BadgesTab;
