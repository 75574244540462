import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { Divider } from "../../ListingEditor/common";

const StepNavigationSmall = ({ className, index: _index, steps, setStep }) => {
  const index = parseInt(_index);
  const lastStep = index === steps.length - 1;
  const name = steps[index].label.split(". ")[1];
  const paddingRight = lastStep ? "mx-a pr-5" : "mx-a";
  const paddingLeft = index === 0 ? "mx-a pl-5" : "mx-a";
  const spacingClass = lastStep ? paddingRight : paddingLeft;
  const goNext = () => setStep(index + 1);
  const goBack = () => setStep(index - 1);
  const counterText = `Step ${index + 1}/${steps.length}`;

  return (
    <>
      <Box $relative className={className} $spacing="mb-4">
        <Divider $spacing="" />
        <StepSmall $bgColor="01Secondary300">
          <Text $typography="body/medium" $bold $color="02Primary700" $bgColor="01Secondary300">
            {counterText}
          </Text>
        </StepSmall>
      </Box>
      <StepName>
        {index > 0 && <IconSvg icon="chevron-left" $color="03Secondary700" onClick={goBack} />}
        <Text $bold $spacing={spacingClass} $textAlign="center">
          {name}
        </Text>
        {index + 1 !== steps.length && <IconSvg icon="chevron-right" $color="03Secondary700" onClick={goNext} />}
      </StepName>
    </>
  );
};

const StepSmall = styled(Box)`
  top: 50%;
  left: 50%;
  padding: 0 2px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  padding: 0 10px;
`;

const StepName = styled(Box)`
  display: flex;
  margin-bottom: 24px;
  padding: 0 26.5px 24px 26.5px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary300"]};

  @media ${breakpoints.mobile} {
    padding: 5px 16px 21px;
    margin-bottom: 20px;
  }
`;

export default StepNavigationSmall;
