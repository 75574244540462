import { ConnectionButtons, DiscourseButtons } from "@common/NetworkConnections";
import { Box, breakpoints, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import useSocialProfilesInfo from "../hooks/useSocialProfilesInfo";

const ConnectMultiNetworks = ({ hideModal, networks }) => {
  const { user } = useStoreState((state) => state.user);
  const { socialProfiles } = user;
  const { discourseConnections, ...props } = useSocialProfilesInfo();
  const connectedCount = networks.filter((type) => socialProfiles.some((obj) => obj.provider === type)).length;
  const absentDiscourseNetworks = networks.filter((type) => !type.includes("discourse"));
  const _discourseConnections = networks.filter((type) => type.includes("discourse"));
  const discourseConfigs = Object.keys(discourseConnections)
    .filter((key) => _discourseConnections.includes(key))
    .reduce((obj, key) => {
      obj[key] = discourseConnections[key];
      return obj;
    }, {});

  return (
    <Modal open onClose={hideModal} $maxWidth="764px" $paddingBody="32px 90px 64px">
      <Text $typography="heading/large" $spacing="mb-4 mx-a">
        {connectedCount} of {networks.length} Networks Connected To Start!
      </Text>
      <Text $textAlign="center" $spacing="mb-6 mx-4">
        Connect to verify your identity on other networks you use to contribute. Verified identities are never exposed.
      </Text>
      <ButtonsWrapper>
        <ConnectionButtons $isModal {...props} networks={absentDiscourseNetworks} />
        <DiscourseButtons $isModal {...props} discourseConnections={discourseConfigs} />
      </ButtonsWrapper>
    </Modal>
  );
};

const ButtonsWrapper = styled(Box)`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media ${breakpoints.miniTablet} {
    padding: 0 32px;
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

export default ConnectMultiNetworks;
