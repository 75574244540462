import { Box, IconSvg, Text } from "@thrivecoin/ui-components";

const StatusBox = ({ status }) => (status == "active" ? <ActiveStatus /> : <PendingStatus />);

const _StatusBox = ({ $color, icon, text }) => (
  <Box $flex $gap="6px" $alignItems="center" $color={$color}>
    <IconSvg icon={icon} />
    <Text $typography="Body/Medium" $bold>
      {text}
    </Text>
  </Box>
);

const ActiveStatus = () => <_StatusBox icon="check-circle-filled" text="Active" $color="03Primary500" />;
const PendingStatus = () => <_StatusBox icon="clock-filled" text="Pending" $color="01Secondary500" />;

export default StatusBox;
