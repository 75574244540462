import { uniqueByID } from "@utils/array";
import toFormData from "@utils/toFormData";
import { action, thunk } from "easy-peasy";
import { DEFAULT_PAGINATION } from "../../components/ChallengeApp/Constants";
import HttpWrapper from "../httpRequester";
import { normalizeCollection2 } from "../normalizerSchema";
import { byID, showComputed } from "./common";

const CONTRIBUTIONS_PAGINATION = {
  page: 1,
  total: 1,
  per_page: 20,
};

const requester = new HttpWrapper("seasons");
const usersRequester = new HttpWrapper("users");
const communitiesRequester = new HttpWrapper("communities");

const attributes = () => ({
  showID: undefined,
  seasonStats: {},
  seasonDetails: {},
  seasonContributorStats: {},
  seasonListings: [],
  activeSeasons: [],
  seasonsContributedTo: [],
  contributionsSeason: [],
  seasonPrizes: [],
  filteredSeasons: [],
  //TODO earnings by communityID, needs schema
  earnings: [],
  //TODO pagination by communityID
  pagination: {
    earnings: DEFAULT_PAGINATION,
    contributions: DEFAULT_PAGINATION,
  },
  eligibleForContributing: null,
  seasonListingsPagination: DEFAULT_PAGINATION,
  contributionsSeasonPagination: CONTRIBUTIONS_PAGINATION,
});

const computed = {
  season: showComputed("season"),
  byID: byID("season"),
};

const actions = {
  claimRewardRequest: thunk((_, { id, tx_id }) => {
    return requester.post(`${id}/claim`, { tx_id });
  }),
  createSeason: thunk((_, data) => {
    const season = toFormData("season", data);
    return communitiesRequester.post(`${data.community_id}/create_season`, season);
  }),
  getSeasonToEdit: thunk(({ setShowSeason }, id) => {
    return requester.get(`${id}/edit`).then(setShowSeason);
  }),
  editSeason: thunk((_, data) => {
    const season = toFormData("season", data);
    return requester.patch(`${data.id}`, season);
  }),
  getSeasonsContributedTo: thunk(({ setSeasonsContributedTo }) => {
    return usersRequester
      .get("current/contributed_seasons?serializer=card")
      .then(({ seasons }) => setSeasonsContributedTo(seasons));
  }),
  getActiveSeasons: thunk(({ setActiveSeasons }) => {
    return requester.get("/active").then(({ seasons }) => setActiveSeasons(seasons));
  }),
  getActiveSeasonRequest: thunk(({ setShowSeason }, id) => {
    return requester.get(`${id}`).then(setShowSeason);
  }),
  getEarningsRequest: thunk(({ setEarnings }, payload) => {
    const { id, ...sortParams } = payload;
    return requester.get(`${id}/season_earnings`, sortParams).then(setEarnings);
  }),
  setShowSeason: thunk(({ normalize, setShowID }, { season }) => {
    normalize({ data: [season], entityName: "season", id: season.id });
    setShowID(season.id);

    return season;
  }),
  getSeasonDetails: thunk(({ setSeasonDetails }, id) => {
    return usersRequester.get(`current/seasons/${id}/details`).then(({ details }) => setSeasonDetails(details));
  }),
  eligibleForContributingRequest: thunk(({ setEligibleForContribution }, id) => {
    return requester.get(`${id}/eligible_for_contributing`).then(({ result }) => setEligibleForContribution(result));
  }),
  forceComplete: thunk((_, id) => {
    return requester.post(`${id}/force_complete`);
  }),
  setShowID: action((state, id) => {
    state.showID = id;
  }),
  reset: action((state) => {
    //TOOD: only change the showID if we link everything using normalzr
    Object.assign(state, attributes());
  }),
  normalize: thunk((actions, { data, entityName }, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeCollection2(data, entityName);
    setEntities(result);
    return result.result;
  }),
  updateSeason: thunk(({ normalize }, season) => {
    normalize({ data: [season], entityName: "season", id: season.id });
  }),

  getActiveSeasonListingsRequest: thunk(
    (
      { setSeasonListings, loadMoreSesasonListings, setListingsPagination },
      { id, keyword, sort_type, sort_direction, community_tag_ids, page = 1, ...args }
    ) => {
      return requester
        .get(`${id}/active_listings`, { keyword, sort_type, sort_direction, page, community_tag_ids, ...args })
        .then(({ listings, pagination }) => {
          if (page > 1) {
            loadMoreSesasonListings(listings);
          } else {
            setSeasonListings(listings);
          }
          //TODO: find a way for MSW to write custom heders.
          pagination && setListingsPagination(pagination);
        });
    }
  ),
  getFilteredSeasons: thunk(({ setFilteredSeasons }, { id, status }) => {
    return communitiesRequester.get(`${id}/seasons`, { status }).then(({ seasons }) => {
      setFilteredSeasons(seasons);
    });
  }),
  getContributionsSeason: thunk(
    (
      { setContributionsSeason, loadMoreContributionsSeason, setContributionsSeasonsPagination },
      { id, sort_type = "amount", sort_direction = "desc", keyword = "", page = 1 }
    ) => {
      const params = { sort_type, sort_direction, page };
      if (keyword) {
        params.keyword = keyword;
      }
      return requester.get(`${id}/contributions`, params).then(({ contributions, pagination }) => {
        if (page > 1) {
          loadMoreContributionsSeason(contributions);
        } else {
          setContributionsSeason(contributions);
        }
        setContributionsSeasonsPagination(pagination);
      });
    }
  ),
  publishSeasonRequest: thunk((actions, seasonData) => {
    const data = Number.isInteger(parseInt(seasonData)) ? { id: seasonData } : seasonData;
    const season = toFormData("season", data);

    return requester.patch(`/${data.id}/publish`, season);
  }),

  createDraftSeason: thunk((actions, data) => {
    const season = toFormData("season", data);

    return communitiesRequester.post(`${data.community_id}/create_draft_season`, season);
  }),
  setSmartContract: thunk((_, { data, seasonId }) => {
    const url = `/${seasonId}/store_reward_claiming_config`;
    return requester.patch(url, data);
  }),
  getSeasonStats: thunk(({ setSeasonStats }, id) => {
    return requester.get(`${id}?serializer=stats`).then(({ season }) => setSeasonStats(season));
  }),
  getSeasonContributorStats: thunk(({ setSeasonContributorStats }, id) => {
    return requester.get(`${id}?serializer=contributor_stats`).then(({ season }) => setSeasonContributorStats(season));
  }),
  getSeasonPrizes: thunk(({ setSeasonPrizes }, id) => {
    return requester.get(`${id}/raffle_prizes`).then(({ raffle_prizes }) => setSeasonPrizes(raffle_prizes));
  }),
  donateEarnings: thunk((_, { seasonId, to }) => {
    return requester.post(`${seasonId}/donate`, { to });
  }),
  cancelDonation: thunk((_, seasonId) => {
    return requester.delete(`${seasonId}/cancel_donation`);
  }),
  removeContributionRequest: thunk((_, { id, contribution_id }) => {
    return requester.delete(`${id}/remove_contribution`, { contribution_id });
  }),
  createContributionRequest: thunk((_, { id, listing_id, reward_amount, user_id }) => {
    return requester.post(`${id}/create_contribution`, { listing_id, reward_amount, user_id });
  }),

  setSeasonsContributedTo: action((state, seasons) => {
    state.seasonsContributedTo = seasons;
  }),
  setActiveSeasons: action((state, seasons) => {
    state.activeSeasons = seasons;
  }),
  setSeasonListings: action((state, seasonListings) => {
    state.seasonListings = seasonListings;
  }),
  setFilteredSeasons: action((state, filteredSeasons) => {
    state.filteredSeasons = filteredSeasons;
  }),
  loadMoreSesasonListings: action((state, seasonListings) => {
    state.seasonListings = uniqueByID([...state.seasonListings, ...seasonListings]);
  }),
  setListingsPagination: action((state, pagination) => {
    state.seasonListingsPagination = pagination;
  }),
  setContributionsSeason: action((state, contributionsSeason) => {
    state.contributionsSeason = contributionsSeason;
  }),
  loadMoreContributionsSeason: action((state, contributionsSeason) => {
    state.contributionsSeason = uniqueByID([...state.contributionsSeason, ...contributionsSeason]);
  }),
  setContributionsSeasonsPagination: action((state, pagination) => {
    state.contributionsSeasonPagination = pagination;
    state.pagination.contributions = pagination;
  }),
  setSeasonStats: action((state, seasonStats) => {
    state.seasonStats = seasonStats;
  }),
  setSeasonContributorStats: action((state, seasonContributorStats) => {
    state.seasonContributorStats = seasonContributorStats;
  }),
  setSeasonPrizes: action((state, seasonPrizes) => {
    state.seasonPrizes = seasonPrizes;
  }),
  setEligibleForContribution: action((state, eligible) => {
    state.eligibleForContributing = eligible;
  }),
  setSeasonDetails: action((state, details) => {
    state.seasonDetails = details;
  }),
  setEarnings: action((state, { season_earnings, pagination = {} }) => {
    state.pagination.earnings = pagination;
    state.earnings = uniqueByID([...state.earnings, ...season_earnings]);
  }),
};

const season = {
  ...attributes(),
  ...computed,
  ...actions,
};

export default season;
