import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const modules = {
  toolbar: [["image", "link"], [{ header: [1, 2, 3, false] }], ["bold", "italic", "strike"]],
};

const formats = ["image", "link", "header", "bold", "italic", "strike"];

const RichEditor = (props) => {
  const [showToolbar, setShowToolbar] = useState(false);

  const handleEditorFocus = () => {
    setShowToolbar(true);
  };

  const handleEditorBlur = () => {
    setShowToolbar(false);
  };

  return (
    <div>
      <StyledEditor
        showToolbar={showToolbar}
        modules={modules}
        formats={formats}
        onFocus={handleEditorFocus}
        onBlur={handleEditorBlur}
        {...props}
      />
    </div>
  );
};

const StyledEditor = styled(ReactQuill)`
  flex: 1;
  margin-bottom: 44px;
   height ${({ showToolbar }) => (showToolbar ? "130px" : "172px")};
  .ql-toolbar {
    display: ${({ showToolbar }) => (showToolbar ? "flex" : "none")};
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    ${({ showToolbar }) => !showToolbar && "border-top: 1px solid #ccc;"}
  }

  .ql-container > .ql-editor.ql-blank::before {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors["01Primary400"]};
      border-top-width: 1px !important;
    }
  & > div {
    &:first-child {
      border-color: ${({ theme }) => theme.colors["01Primary300"]};
      border-radius:  ${({ showToolbar }) => (showToolbar ? "12px 12px 0 0" : "12px")};
      background: ${({ theme }) => theme.colors["02Primary0"]};
    }

    &:last-child {
      border-color: ${({ theme }) => theme.colors["01Primary300"]};
      background: ${({ theme }) => theme.colors["01Primary50"]};
      border-radius: ${({ showToolbar }) => (showToolbar ? "0 0 12px 12px;" : "12px")};
      font-family: "Proxima Nova";

      & p {
        font-size: 16px;
        line-height: 24px;
      }

      & h1 {
        font-size: 28px;
        line-height: 36px;
      }

      & h2 {
        font-size: 24px;
        line-height: 30px;
      }

      & h3 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export default RichEditor;
