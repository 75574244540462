import { computed, thunk } from "easy-peasy";
import { deNormalizeCollection, denormalizeEntity, normalizeCollection2 } from "../normalizerSchema";

export const showResolver = (state, storeState) => ({
  id: state.showID,
  entities: storeState.entities,
});

export const showComputed = (schemaName, initialValue = {}) =>
  computed([showResolver], ({ id, entities }) => {
    return denormalizeEntity(id, entities, schemaName) || initialValue;
  });

const entitiesResolver = (state, storeState) => ({
  entities: storeState.entities,
});

const makeResolver = (idsKey) => (state, storeState) => ({
  [idsKey]: state[idsKey],
  entities: storeState.entities,
});

export const byID = (schemaName) =>
  computed([entitiesResolver], ({ entities }) => {
    return (id) => {
      const data = denormalizeEntity(id, entities, schemaName) || {};
      return data;
    };
  });

export const nestedByID = (schemaName, idsKey) => {
  const dynamicResolver = makeResolver(idsKey);
  return computed([dynamicResolver], ({ entities, ...state }) => {
    return (id) => {
      const ids = state[idsKey][id] || [];
      const data = deNormalizeCollection(ids, entities, schemaName);
      return data;
    };
  });
};

export const filteredByField = (fieldName, entityKey, schemaName) =>
  computed([entitiesResolver], ({ entities }) => {
    return (value) => {
      const ids = Object.keys(entities[entityKey]);
      const data = deNormalizeCollection(ids, entities, schemaName);
      return data.filter((entry) => entry[fieldName] == value);
    };
  });

export const normalizeCollectionGen = (schemaName) =>
  thunk((_, _data, { getStoreActions }) => {
    const data = Array.isArray(_data) ? _data : [_data];
    const { setEntities } = getStoreActions().entities;
    const { result, entities } = normalizeCollection2(data, schemaName);
    setEntities({ entities });

    return result;
  });
