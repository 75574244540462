import Divider from "@common/Divider";
import InputGroup from "@common/InputGroup";
import { Box, breakpoints, Text, TextArea } from "@thrivecoin/ui-components";
import styled from "styled-components";
import FileInput from "../../../common/FileInput";
import { FloatInput } from "../../../ListingEditor/common";
import { bannerColors, brandColors } from "./Colors";
import ColorsPalette from "./ColorsPalette";

import CUSTOM_DARK from "@assets/custom-dark.svg";
import CUSTOM_LIGHT from "@assets/custom-light.svg";
import THRIVE_DARK from "@assets/thrive-dark.svg";
import THRIVE_LIGHT from "@assets/thrive-light.svg";

const ThemeImages = ({ lightImg, darkImg, lightAlt, darkAlt }) => (
  <ThemeBox $flex>
    <Box>
      <Text $extraBold $typography="body/small" $spacing="mb-2">
        Light
      </Text>
      <ThemeImg src={lightImg} alt={lightAlt} />
    </Box>
    <Box>
      <Text $extraBold $typography="body/small" $spacing="mb-2">
        Dark
      </Text>
      <ThemeImg src={darkImg} alt={darkAlt} />
    </Box>
  </ThemeBox>
);

const NameSelectionStep = ({ communityValues, setState, errorsValues }) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name) {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onThemeChange = (e) => {
    setState((prev) => ({ ...prev, theme: e.target.dataset.id }));
  };

  return (
    <form onChange={onChange} className="listing-form">
      <InputGroup
        field="name"
        label="Community name (Required)"
        placeholder="E.g. Web3 for Good"
        required
        invalid={!!errorsValues?.name}
        defaultValue={communityValues.name}
        alwaysShowValidation={!!errorsValues?.name?.[0]}
        validationText={errorsValues.name?.[0]}
      />
      <InputGroup
        field="subheadline"
        label="Community Subheadline (Optional)"
        placeholder="E.g. We are a community that wants to help create positive impact with Web3. Some of us are Web3 natives and others are just getting started. Join us!"
        InputClass={TextArea}
        defaultValue={communityValues.subheadline}
      />
      <Divider $sm-spacing="mx-4 my-5" />
      <InputGroup
        field="slug"
        label="Custom Slug (Required)"
        placeholder="apecoin"
        required
        invalid={!!errorsValues?.slug}
        defaultValue={communityValues.slug}
        alwaysShowValidation={!!errorsValues?.slug?.[0]}
        validationText={errorsValues.slug?.[0]}
      />
      <Divider $sm-spacing="mx-4 my-5" />
      <InputGroup
        field="required_gitcoin_score"
        label="Minimun gitcoin score (optional)"
        InputClass={FloatInput}
        defaultValue={communityValues.required_gitcoin_score}
      />
      <Divider $sm-spacing="mx-4 my-5" />
      <Box $sm-spacing="mx-4">
        <FileInput
          label="Community Logo (Optional)"
          subLabel="This logo will show on the primary navigation when hosting your community page on a custom domain."
          media={communityValues}
          setMedia={setState}
          name="logo_url"
        />
      </Box>
      <Divider $sm-spacing="mx-4 my-5" />
      <Box $sm-spacing="mx-4">
        <FileInput
          label="Community Logo Dark(Optional)"
          subLabel="Dark mode version of the community logo."
          media={communityValues}
          setMedia={setState}
          name="logo_dark_url"
        />
      </Box>
      <Divider $sm-spacing="mx-4 my-5" />
      <Box $sm-spacing="mx-4">
        <FileInput
          label="Header Image (Optional)"
          subLabel="Our recommended size is 596x346px"
          media={communityValues}
          setMedia={setState}
          name="cover_media_url"
        />
      </Box>
      <Divider $sm-spacing="mx-4 my-5" />
      <Box $sm-spacing="mx-4">
        <Text $bold $spacing="mb-3">
          Select Theme (Required)
        </Text>
        <Text $spacing="mb-6+1">
          Choose the “ThriveCoin Theme” to get up and running right away. Select “Custom Theme” for additional advanced
          customization options.
        </Text>
        <ThemeWrapper data-id="thrive" onClick={onThemeChange}>
          <RadioBox>
            <CustomRadioBtn>{communityValues.theme === "thrive" && <InnerCircle />}</CustomRadioBtn>
            <Text $bold>ThriveCoin Theme</Text>
          </RadioBox>
          <ThemeImages
            lightImg={THRIVE_LIGHT}
            darkImg={THRIVE_DARK}
            lightAlt="ThriveCoin Theme Light"
            darkAlt="ThriveCoin Theme Dark"
          />
        </ThemeWrapper>
        <ThemeWrapper data-id="custom" onClick={onThemeChange}>
          <RadioBox>
            <CustomRadioBtn>{communityValues.theme === "custom" && <InnerCircle />}</CustomRadioBtn>
            <Text $bold>Custom Theme</Text>
          </RadioBox>
          <ThemeImages
            lightImg={CUSTOM_LIGHT}
            darkImg={CUSTOM_DARK}
            lightAlt="Custom Theme Light"
            darkAlt="Custom Theme Dark"
          />
        </ThemeWrapper>
      </Box>
      {communityValues.theme === "custom" && (
        <>
          <Box $sm-spacing="mx-4">
            <ColorsPalette
              name="accent_color"
              bannerColor={communityValues.accent_color}
              Colors={brandColors}
              title="Accent color (Required)"
              subtitle="The selected color should represent your brand's identity."
            />
          </Box>
          <Divider $sm-spacing="mx-4 my-5" />
          <StyledBox disabled={!!communityValues.header_background} $spacing="mb-6" $sm-spacing="mx-4">
            <ColorsPalette
              name="header_background_color"
              bannerColor={communityValues.header_background_color}
              Colors={bannerColors}
              title="Header Background color (Optional)"
              subtitle="The selected color should represent your brand's identity. You can only choose between Header Background color or Header Background Image."
            />
          </StyledBox>
          <Box $sm-spacing="mx-4">
            <FileInput
              label="Header Background Image (Optional)"
              subLabel="Our recommended size is 1920x252px"
              media={communityValues}
              setMedia={setState}
              name="header_background_url"
            />
          </Box>
        </>
      )}
    </form>
  );
};

const StyledBox = styled(Box)`
  ${({ disabled }) =>
    disabled &&
    `
    & > div:last-child {
      opacity: 0.25;
      pointer-events: none;
    }
  `}
`;

const ThemeImg = styled.img`
  height: 180px;
  border-radius: 2px;
`;

const ThemeWrapper = styled(Box)`
  cursor: pointer;
  margin-bottom: 2rem;

  & > * {
    pointer-events: none;
  }
`;

const RadioBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 1rem;
`;

const CustomRadioBtn = styled(Box)`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors["01Primary400"]};
`;

const InnerCircle = styled(Box)`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.button};
`;

const ThemeBox = styled(Box)`
  gap: 2rem;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

export default NameSelectionStep;
