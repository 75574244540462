import { Box, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { discourseStats } from "./ConnectStep";
import DiscourseButtons from "./DiscourseButtons";

const DiscourseBody = (props) => {
  const { pendingWaysCount } = discourseStats(props);
  return (
    <Box $flex $direction="column" $alignItems="center">
      <Text $typography="heading/medium" $extraBold $spacing="mb-4">
        Connect other Discourse Forums
      </Text>
      {pendingWaysCount > 0 && (
        <Text typographt="body/medium" $bold $spacing="mb-5">
          opens {pendingWaysCount} more ways to contribute
        </Text>
      )}
      <Text $spacing="mb-6" $align="center">
        We’ll verify your identity on each forum you connect and automatically reward your contributions on that forum.
      </Text>
      <Box $flex $direction="column" $gap="16px">
        <DiscourseButtons helperTextColor="02Primary500" {...props} isOpen />
      </Box>
    </Box>
  );
};

export const Footer = ({ closeModal }) => {
  return (
    <FooterBody $flex $justifyContent="center" $alignItems="center" $spacing="p-7 pt-6+2">
      <Button
        onClick={closeModal}
        $typography="body/medium"
        $color="02Primary700"
        kind="outline"
        $sm-spacing="mx-5+1"
        $borderColor="#CFCFCF"
        $borderRadius="50px"
        $width="100%"
        $size="lg"
        $bold
      >
        Keep Contributing
      </Button>
    </FooterBody>
  );
};

const FooterBody = styled(Box)`
  border-top: 1px solid #d9d9d9;
`;

DiscourseBody.Footer = Footer;

export default DiscourseBody;
