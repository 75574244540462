import debounce from "@utils/debounce";
import { useEffect, useState } from "react";

const PERSISTENT_OPTIONS_INITIAL = [
  { id: "all", value: "all", label: "All", selected: true },
  { id: "system", value: "system", label: "System" },
];

const formatTagOptions = (options, onClick) => {
  return options.map((option) => ({
    id: option.id,
    value: option.id,
    label: option.name,
    selected: false,
    onClick,
  }));
};

const updateLabelWithCount = (options, value, count) => {
  const option = options.find((opt) => opt.value === value);
  if (option) {
    option.label = `${option.label.replace(/\(\d+\)/, "")} (${count})`;
  }
};

const useTagFilters = (activeCommunities, filterFN, sortProps, badges, badgesNum) => {
  const [persistentOptions, setPersistentOptions] = useState([...PERSISTENT_OPTIONS_INITIAL]);
  const [text, setText] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  const [_communities, setCommunitties] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const systemBadges = badges.filter((badge) => badge.community_id === null);

  const onTagClick = (event) => {
    const value = event.value || event.target.dataset.value;
    setPersistentOptions((options) =>
      options.map((tag) => ({
        ...tag,
        selected: tag.value == value,
      }))
    );
  };

  const _onTextChange = (text) => {
    sortProps.onChange(text);
    const updatedTags = persistentOptions.map((tag) => ({
      ...tag,
      selected: tag.value === persistentOptions[0].value,
    }));

    setPersistentOptions(updatedTags);
  };

  const onTextChange = debounce(_onTextChange, 3000);

  const onItemClick = (community) => {
    setSelectedCommunities((prevCommunity) => {
      const isItemInCommunity = prevCommunity.some((item) => item.id === community.id);
      if (isItemInCommunity) {
        return prevCommunity.filter((item) => item.id !== community.id);
      } else {
        return [...prevCommunity, { ...community }];
      }
    });
  };
  useEffect(() => {
    updateLabelWithCount(persistentOptions, "all", badgesNum);
  }, [badgesNum]);

  useEffect(() => {
    updateLabelWithCount(persistentOptions, "system", systemBadges.length);
  }, [systemBadges]);

  useEffect(() => {
    const formattedFiltersCommunities = {
      title: "Communities",
      selectedOptions: selectedCommunities,
      options: formatTagOptions(activeCommunities, onItemClick),
    };

    setCommunitties(formattedFiltersCommunities);

    const filterOptions = [_communities].map((collection) => {
      collection.onItemClick = onItemClick;
      return collection;
    });

    setFilterOptions(filterOptions);
  }, [_communities.length]);

  const doSearch = () => {
    filterFN(text, selectedCommunities);
  };

  useEffect(() => {
    setFilterOptions((prevFilterOptions) => {
      return prevFilterOptions.map((collection) => ({
        ...collection,
        selectedOptions: selectedCommunities,
      }));
    });
  }, [selectedCommunities]);

  return {
    text,
    persistentOptions,
    onTextChange,
    setText,
    onTagClick,
    onItemClick,
    filterOptions: filterOptions,
    selectedCommunities,
    doSearch,
    disabledFilterBtn: selectedCommunities.length === 0,
    gridTemplateColumns: "506px 1fr",
  };
};

export default useTagFilters;
