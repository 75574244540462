import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";

const DeleteBadgeModal = ({ hideModal, id, callback }) => {
  const { showToast } = useStoreActions((actions) => actions.toasts);
  const { deleteBadge } = useStoreActions((actions) => actions.badges);
  const onClick = () =>
    deleteBadge(id).then(({ message }) => {
      showToast({
        content: message,
        type: "success",
        dismissTime: 5000,
      });
      hideModal();
      callback && callback();
    });

  return (
    <Modal $maxWidth="621px" $paddingBody="12px 48px 48px 48px!important" open onClose={hideModal}>
      <Text $typography="heading/medium" $textAlign="center" $color="02Primary500" $spacing="mb-3" $bold>
        Delete Badge?
      </Text>
      <Text $textAlign="center" $typography="body/medium" $color="02Primary500" $spacing="mb-5">
        Are you absolutely certain that you want to proceed with the deletion of this badge?
      </Text>
      <Box $flex $gap="16px" $alignItems="center">
        <Button onClick={hideModal} $width="100%" $bold $size="md" $borderRadius="40px" kind="outline" variant="white">
          Cancel
        </Button>
        <Button onClick={onClick} $width="100%" $bold $size="md" $borderRadius="40px" kind="solid" variant="red">
          Delete Badge
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteBadgeModal;
