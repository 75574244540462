import { Box, breakpoints, Button, Loader, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext } from "react";
import styled from "styled-components";
import StepNavigation from "../common/StepNavigation";
import HeaderButtons from "../CommunityEditor/Steps/common/HeaderButtons";
import { StepsHeaders } from "./constants";
import PreviewSeason from "./PreviewSeason";
import { SeasonEditorContext } from "./SeasonEditorContext";

const BottomButton = (props) => (
  <Button $bold $height="56px" $borderRadius="40px" $grow="1" {...props}>
    {props.children}
  </Button>
);

const SeasonEditorLayout = ({ children }) => {
  const {
    currentStep,
    isEditing,
    raffleEnabled,
    previewMode,
    submitLoading,
    loadingData,
    submitSeason,
    setCurrentStep,
    setPreviewMode,
    saveAsDraft,
    canSaveAsDraft,
    onClose,
  } = useContext(SeasonEditorContext);
  const steps = raffleEnabled ? StepsHeaders : StepsHeaders.slice(0, -1);
  const { isMobile } = useStoreState((state) => state.media);

  const bottomButtonText = steps.length === currentStep + 1 ? (isEditing ? "Update Season" : "Create Season") : "Next";

  const headerText = previewMode ? "Season Page Preview" : isEditing ? "Edit Season" : "Add New Season";
  const buttonText = previewMode ? "Edit" : "Preview";
  const prevText = currentStep === 0 ? "Cancel" : "Back";
  const _canSaveDraft = canSaveAsDraft && !previewMode;

  const togglePreview = () => {
    setPreviewMode(!previewMode);
  };

  const onNextStep = () => {
    if (currentStep === steps.length - 1 || previewMode) {
      submitSeason();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onPrevious = () => {
    if (currentStep === 0) {
      onClose();
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const closeModal = previewMode ? togglePreview : onClose;

  return (
    <Wrapper previewMode={previewMode} $color="01Primary700">
      <Layout $relative previewMode={previewMode}>
        {!isMobile && (
          <HeaderButtons
            onClickPreview={togglePreview}
            textBtn={buttonText}
            icon={previewMode ? "chevron-up" : "chevron-down"}
            onClose={closeModal}
            saveAsDraft={_canSaveDraft && saveAsDraft}
            hidePreview={previewMode}
          />
        )}
        <Header $relative previewMode={previewMode}>
          {!loadingData && <HeaderText $typography="heading/medium">{headerText}</HeaderText>}
        </Header>
        {!previewMode && !loadingData && (
          <>
            <StepNavigation index={currentStep} steps={steps} setStep={setCurrentStep} />
            {children}
            <BottomButtons>
              <BottomButton onClick={onPrevious} kind="outline" variant="01Primary300" $color="01Primary700">
                {prevText}
              </BottomButton>
              {isEditing && (
                <BottomButton onClick={submitSeason} loading={submitLoading}>
                  Save & Close
                </BottomButton>
              )}
              <BottomButton
                kind={isEditing && "outline"}
                $color={isEditing ? "01Primary700" : "01Primary0"}
                variant={isEditing ? "01Primary300" : undefined}
                onClick={onNextStep}
                loading={!isEditing && submitLoading}
              >
                {bottomButtonText}
              </BottomButton>
            </BottomButtons>
          </>
        )}
        {loadingData && (
          <Box $height="100%" $flex $alignItems="center">
            <Loader $center={true} $size="30px" />
          </Box>
        )}
      </Layout>
      {previewMode && <PreviewSeason />}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Layout = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 12px 12px 0 0;
  overflow-y: auto;
  width: calc(100vw - 64px);
  height: ${({ previewMode }) => (previewMode ? "auto" : "calc(100vh - 64px)")};
  background: ${({ theme }) => theme.colors["01Surface"]};
  ${({ previewMode }) =>
    !previewMode &&
    `
    box-sizing: border-box;
    padding-bottom: 87px;
  `}

  @media ${breakpoints.tablet} {
    width: ${({ previewMode }) => (previewMode ? "100vw" : "calc(100vw - 48px)")};
    height: ${({ previewMode }) => (previewMode ? "182px" : "calc(100vh - 100px)")};
  }

  @media ${breakpoints.mobile} {
    position: relative;
    width: 100%;
    border-radius: 0;
    background: ${({ theme }) => theme.colors.background};
    height: calc(100vh - 58px);
  }
`;

const Header = styled(Box)`
  display: flex;
  justify-content: center;
  padding: ${({ previewMode }) => (previewMode ? "2rem 0" : "40px 0")};

  @media ${breakpoints.tablet} {
    padding: ${({ previewMode }) => (previewMode ? "2rem 0" : "39px 0")};
  }

  @media ${breakpoints.mobile} {
    padding: 21px 1rem 19px;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeaderText = styled(Text)`
  @media ${breakpoints.mobile} {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
  }
`;
const BottomButtons = styled(Box)`
  margin: 0 auto;
  margin-top: 32px;
  padding-top: 32px;
  display: flex;
  gap: 1rem;
  width: 555px;
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};

  @media ${breakpoints.mobile} {
    flex-direction: column;
    width: calc(100vw - 32px);
    max-width: 100%;
  }
`;

export default SeasonEditorLayout;
