import { Box, breakpoints, Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CONFIG } from "./constants";
import Header from "./Header";
import NotifyStep from "./NotifyStep";
import ReasonStep from "./ReasonStep";
import RestrictCommunityStep from "./RestrictCommunityStep";

export const STEP_INDEXES = {
  ReasonStep: 0,
  NotifyStep: 1,
};

const INITIAL_STATE = {
  user_id: null,
  reason: "",
  message: "",
  additional_notes: "",
};

const STEP_KLASSES = [ReasonStep, NotifyStep];
const RESTRICT_STEP_CLASSES = [ReasonStep, RestrictCommunityStep, NotifyStep];

const DoActionsModal = ({ hideModal, initialStep, afterClose, type, image, username, id, ...props }) => {
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { getManagedCommunities } = useStoreActions((actions) => actions.community);
  const { managedCommunities } = useStoreState((state) => state.community);
  const [step, goToStep] = useState(initialStep);
  const stepClasses = type == "restrict" ? RESTRICT_STEP_CLASSES : STEP_KLASSES;
  const StepKlass = stepClasses[step];
  const { ban, flag, restrict } = useStoreActions((actions) => actions.userAudits);
  const config = CONFIG[type];
  const [state, setState] = useState({ ...INITIAL_STATE, user_id: id });
  const [loading, setLoading] = useState(false);
  const isLastStep = step == stepClasses.length - 1;
  const fetchManagedCommunities = type == "restrict" && managedCommunities.length == 0;

  const goBack = () => goToStep((step) => step - 1);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  const functionMap = {
    ban,
    flag,
    restrict,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isLastStep) {
      return goToStep((step) => step + 1);
    }

    setLoading(true);
    return functionMap[type](state)
      .then(({ message }) => {
        showToast({
          content: message,
          type: "success",
          dismissTime: 5000,
        });
        afterClose && afterClose();
        hideModal();
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const onError = ({ response }) => {
    const content = response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  const footerContent = (
    <StepKlass.Footer loading={loading} closeModal={hideModal} goBack={goBack} type={type} {...config} />
  );

  useEffect(() => {
    if (fetchManagedCommunities) {
      getManagedCommunities(["active"]);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <StyledModal
        open
        onClose={hideModal}
        footerContent={footerContent}
        $maxWidth="620px"
        $paddingBody="16px 48px 48px 48px"
      >
        <StyledBox $direction="column" $textAlign="center" onChange={onChange}>
          <Header type={type} image={image} username={username} />
          <StepKlass
            goToStep={goToStep}
            closeModal={hideModal}
            type={type}
            {...config}
            {...props}
            {...state}
            communities={managedCommunities}
          />
        </StyledBox>
      </StyledModal>
    </form>
  );
};

export const StyledModal = styled(Modal)`
  margin-top: 0;

  ${Modal.Body} {
    padding-bottom: 0;
    overflow: initial;
  }

  ${Modal.Footer} {
    padding-top: 24px;
    padding-bottom: 48px;

    & > div {
      padding: 0 48px;
    }
  }
`;

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 100%;
  }

  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

DoActionsModal.defaultProps = {
  initialStep: 0,
};

export default DoActionsModal;
