import { Box, Loader, SearchInput, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useMemo, useState } from "react";
import LoadMore from "../../common/LoadMore";
import { StatusFilter } from "../../ManageCommunities/ManageCommunityCard/common";
import { TabWrapper } from "../common";
import useSort from "../useSort";
import ContributorsTable from "./Table";

const FILTERS = [
  { value: "", label: "All", selected: true },
  { value: "claimed", label: "Claimed" },
  { value: "pending", label: "Pending" },
];

const EarningsTab = ({ id, title }) => {
  const { earnings } = useStoreState((state) => state.season);
  const { getEarningsRequest } = useStoreActions((actions) => actions.season);
  const pagination = useStoreState((state) => state.season.pagination.earnings);
  const { total } = pagination;
  const [status, setStatus] = useState("");
  let content = "";
  const makeRequest = useMemo(() => {
    return (props) => getEarningsRequest({ ...props, status });
  }, [status]);

  const { onChange, loading, onLoadMore, ...sortProps } = useSort(makeRequest, "status", "id", id);

  const onTagClick = (event) => setStatus(event.currentTarget.dataset.value);

  if (loading && earnings.length == 0) {
    content = (
      <Box $flex $justifyContent="center" $spacing="my-a">
        <Loader $size="64px" />
      </Box>
    );
  } else {
    content = <ContributorsTable items={earnings} sortProps={sortProps} />;
  }

  return (
    <TabWrapper>
      <Text $typography="heading/small" bold>
        There are {total} Contributors in {title}
      </Text>
      <StatusFilter filters={FILTERS} onStatusClick={onTagClick}>
        <SearchInput className="search-input" onChange={onChange} />
      </StatusFilter>
      {content}
      <LoadMore pagination={pagination} loadMore={onLoadMore} />
    </TabWrapper>
  );
};

export default EarningsTab;
